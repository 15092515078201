import React, { Component } from "react";
import { Collapse } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";

import { StyleContainer } from "./style";
const { Panel } = Collapse;
class CollapseData extends Component {
  constructor(props) {
    super(props);
    this.state = { visible: false, type: "list", collapse: false };
  }
  changeIcon = () =>
    !this.props.disable && this.setState({ collapse: !this.state.collapse });
  render() {
    // const { collapse } = this.state;
    const { data, disable, className } = this.props;
    return (
      <StyleContainer>
        <Collapse
          ghost
          accordion
          bordered={false}
          expandIconPosition="end"
          onChange={this.changeIcon}
          className={className ? className : ""}
          collapsible={disable ? "disabled" : "header"}
          defaultActiveKey={data.length-1}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
        >
          {data.map((a, i) => (
            <Panel
              key={i}
              className="display"
              header={<div className="title">{a.header}</div>}
              extra={a.icon}
            >
              {a.pData}
            </Panel>
          ))}
        </Collapse>
      </StyleContainer>
    );
  }
}
export default CollapseData;
