import { message } from "antd";
import { axiosAuthGet, axiosAuthPost } from "modules/Axios";
import { masterConst, AuditConst } from "modules/config";
import * as actions from "./constant";

export const getMasterList = () => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_MASTER_LIST_INITIATED });
    let response = await axiosAuthGet(masterConst.GET_MASTER_LIST);
    if (response?.success) await dispatch({ type: actions.GET_MASTER_LIST_SUCCESS, payload: response });
    else dispatch({ type: actions.GET_MASTER_LIST_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.GET_MASTER_LIST_ERROR, error: "Network Error" });
  }
};
export const getMasterById = (id) => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_MASTER_BY_ID_INITIATED });
    let response = await axiosAuthGet(masterConst.GET_MASTER_BY_ID + id);
    if (response?.success) await dispatch({ type: actions.GET_MASTER_BY_ID_SUCCESS, payload: response });
    else dispatch({ type: actions.GET_MASTER_BY_ID_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.GET_MASTER_BY_ID_ERROR, error: "Network Error" });
  }
};

export const addMaster = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.ADD_MASTER_INITIATED });
    let response = await axiosAuthPost(masterConst.ADD_MASTER, payload);
    if (response?.success) {
      await axiosAuthPost(AuditConst.ADD_AUDIT, { uid: payload.uid, text: payload.typeName + " record added" });
      await dispatch({ type: actions.ADD_MASTER_SUCCESS, payload: response });
    }
    else dispatch({ type: actions.ADD_MASTER_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.ADD_MASTER_ERROR, error: "Network Error" });
  }
};

export const updateMaster = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.UPDATE_MASTER_INITIATED });
    let response = await axiosAuthPost(masterConst.UPDATE_MASTER, payload);
    if (response?.success) {
      await axiosAuthPost(AuditConst.ADD_AUDIT, { uid: payload.uid, toid: payload.id, text: payload.typeName + " record update" });
      await dispatch({ type: actions.UPDATE_MASTER_SUCCESS, payload: response });
    }
    else dispatch({ type: actions.UPDATE_MASTER_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.UPDATE_MASTER_ERROR, error: "Network Error" });
  }
};

export const deleteMaster = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.DELETE_MASTER_INITIATED });
    let response = await axiosAuthGet(masterConst.DELETE_MASTER + payload.id);
    if (response?.success) {
      await axiosAuthPost(AuditConst.ADD_AUDIT, { uid: payload.uid, toid: payload.id, text: payload.typeName + " record deleted" });
      await dispatch({ type: actions.DELETE_MASTER_SUCCESS, payload: response });
      message.success(response?.message)
    }
    else dispatch({ type: actions.DELETE_MASTER_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.DELETE_MASTER_ERROR, error: "Network Error" });
  }
};

export const getMasterType = () => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_MASTER_TYPE_INITIATED });
    let response = await axiosAuthGet(masterConst.GET_MASTER_TYPE);
    if (response?.success) await dispatch({ type: actions.GET_MASTER_TYPE_SUCCESS, payload: response });
    else dispatch({ type: actions.GET_MASTER_TYPE_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.GET_MASTER_TYPE_ERROR, error: "Network Error" });
  }
};