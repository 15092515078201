import * as actions from "./constant";
const initialState = {
  error: false,
  message: false,
  loading: false,
  isSaved: false,
  isDeleted: false,
  isEdited: false,
  isSend: false,
  smptData: {},
  smptList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_SMTP_LIST_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
      };
    case actions.GET_SMTP_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        smptList: action.payload.data,
      };
    case actions.GET_SMTP_LIST_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
      };
    case actions.GET_SMTP_BY_ID_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
      };
    case actions.GET_SMTP_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        smptData: action.payload.data,
      };
    case actions.GET_SMTP_BY_ID_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
      };

    case actions.ADD_SMTP_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
        isSaved: false,
      };
    case actions.ADD_SMTP_SUCCESS:
      return {
        ...state,
        loading: false,
        isSaved: true,
      };
    case actions.ADD_SMTP_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
        isSaved: false,
      };
    case actions.UPDATE_SMTP_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
        isSaved: false,
      };
    case actions.UPDATE_SMTP_SUCCESS:
      return {
        ...state,
        loading: false,
        isSaved: true,
      };
    case actions.UPDATE_SMTP_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
        isSaved: false,
      };
    case actions.UPDATE_ACTIVE_SMTP_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
        isEdited: false,
      };
    case actions.UPDATE_ACTIVE_SMTP_SUCCESS:
      return {
        ...state,
        loading: false,
        isEdited: true,
      };
    case actions.UPDATE_ACTIVE_SMTP_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
        isEdited: false,
      };
    case actions.DELETE_SMTP_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
        isDeleted: false,
      };
    case actions.DELETE_SMTP_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: true,
      };
    case actions.DELETE_SMTP_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
        isDeleted: false,
      };
      case actions.SEND_MAIL_INITIATED:
        return {
          ...state,
          message: false,
          error: false,
          loading: true,
          isSend: false,
        };
      case actions.SEND_MAIL_SUCCESS:
        return {
          ...state,
          loading: false,
          isSend: true,
        };
      case actions.SEND_MAIL_ERROR:
        return {
          ...state,
          error: true,
          loading: false,
          message: false,
          isSend: false,
        };
    default:
      return state;
  }
};
