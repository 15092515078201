export const GET_DECLARATION_LIST_INITIATED = "GET_DECLARATION_LIST_INITIATED";
export const GET_DECLARATION_LIST_SUCCESS = "GET_DECLARATION_LIST_SUCCESS";
export const GET_DECLARATION_LIST_ERROR = "GET_DECLARATION_LIST_ERROR";

export const GET_DECLARATION_BY_ID_INITIATED = "GET_DECLARATION_BY_ID_INITIATED";
export const GET_DECLARATION_BY_ID_SUCCESS = "GET_DECLARATION_BY_ID_SUCCESS";
export const GET_DECLARATION_BY_ID_ERROR = "GET_DECLARATION_BY_ID_ERROR";

export const GET_DECLARATION_BY_UID_INITIATED = "GET_DECLARATION_BY_UID_INITIATED";
export const GET_DECLARATION_BY_UID_SUCCESS = "GET_DECLARATION_BY_UID_SUCCESS";
export const GET_DECLARATION_BY_UID_ERROR = "GET_DECLARATION_BY_UID_ERROR";

export const ADD_DECLARATION_INITIATED = "ADD_DECLARATION_INITIATED";
export const ADD_DECLARATION_SUCCESS = "ADD_DECLARATION_SUCCESS";
export const ADD_DECLARATION_ERROR = "ADD_DECLARATION_ERROR";

export const UPDATE_DECLARATION_INITIATED = "UPDATE_DECLARATION_INITIATED";
export const UPDATE_DECLARATION_SUCCESS = "UPDATE_DECLARATION_SUCCESS";
export const UPDATE_DECLARATION_ERROR = "UPDATE_DECLARATION_ERROR";

export const DELETE_DECLARATION_INITIATED = "DELETE_DECLARATION_INITIATED";
export const DELETE_DECLARATION_SUCCESS = "DELETE_DECLARATION_SUCCESS";
export const DELETE_DECLARATION_ERROR = "DELETE_DECLARATION_ERROR";