import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { getAuthRole } from "modules/helper";
import DPDashboard from "./DPDashboard";
import CPDashboard from "./CPDashboard";
var userRole = getAuthRole();

class Home extends Component {
  async componentDidMount() {
    userRole = userRole ? userRole : getAuthRole();
  }
  render() {
    return (
      <>
        {userRole === 67 ? (
          <DPDashboard />
        ) : userRole === 66 || userRole === 68 ? (
          <CPDashboard />
        ) : (
          <></>
        )}
      </>
    );
  }
}
export default withRouter(Home);
