export const GET_SMTP_LIST_INITIATED = "GET_SMTP_LIST_INITIATED";
export const GET_SMTP_LIST_SUCCESS = "GET_SMTP_LIST_SUCCESS";
export const GET_SMTP_LIST_ERROR = "GET_SMTP_LIST_ERROR";

export const GET_SMTP_BY_ID_INITIATED = "GET_SMTP_BY_ID_INITIATED";
export const GET_SMTP_BY_ID_SUCCESS = "GET_SMTP_BY_ID_SUCCESS";
export const GET_SMTP_BY_ID_ERROR = "GET_SMTP_BY_ID_ERROR";

export const ADD_SMTP_INITIATED = "ADD_SMTP_INITIATED";
export const ADD_SMTP_SUCCESS = "ADD_SMTP_SUCCESS";
export const ADD_SMTP_ERROR = "ADD_SMTP_ERROR";

export const UPDATE_SMTP_INITIATED = "UPDATE_SMTP_INITIATED";
export const UPDATE_SMTP_SUCCESS = "UPDATE_SMTP_SUCCESS";
export const UPDATE_SMTP_ERROR = "UPDATE_SMTP_ERROR";

export const UPDATE_ACTIVE_SMTP_INITIATED = "UPDATE_ACTIVE_SMTP_INITIATED";
export const UPDATE_ACTIVE_SMTP_SUCCESS = "UPDATE_ACTIVE_SMTP_SUCCESS";
export const UPDATE_ACTIVE_SMTP_ERROR = "UPDATE_ACTIVE_SMTP_ERROR";

export const DELETE_SMTP_INITIATED = "DELETE_SMTP_INITIATED";
export const DELETE_SMTP_SUCCESS = "DELETE_SMTP_SUCCESS";
export const DELETE_SMTP_ERROR = "DELETE_SMTP_ERROR";

export const SEND_MAIL_INITIATED = "SEND_MAIL_INITIATED";
export const SEND_MAIL_SUCCESS = "SEND_MAIL_SUCCESS";
export const SEND_MAIL_ERROR = "SEND_MAIL_ERROR";