import * as actions from "./constant";
const initialState = {
  error: false,
  message: false,
  loading: false,
  isSaved: false,
  isUpdated: false,
  isDeleted: false,
  holdingData: {},
  holdingList: [],
  holdinguData: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_HOLDING_LIST_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
      };
    case actions.GET_HOLDING_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        holdingList: action.payload.data,
      };
    case actions.GET_HOLDING_LIST_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
      };
    case actions.GET_HOLDING_BY_ID_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
      };
    case actions.GET_HOLDING_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        holdingData: action.payload.data,
      };
    case actions.GET_HOLDING_BY_ID_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
      };
    case actions.GET_HOLDING_BY_UID_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
      };
    case actions.GET_HOLDING_BY_UID_SUCCESS:
      return {
        ...state,
        loading: false,
        holdinguData: action.payload.data,
      };
    case actions.GET_HOLDING_BY_UID_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
      };
    case actions.ADD_HOLDING_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
        isSaved: false,
      };
    case actions.ADD_HOLDING_SUCCESS:
      return {
        ...state,
        loading: false,
        isSaved: true,
      };
    case actions.ADD_HOLDING_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
        isSaved: false,
      };
    case actions.UPDATE_HOLDING_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
        isUpdated: false,
      };
    case actions.UPDATE_HOLDING_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: true,
      };
    case actions.UPDATE_HOLDING_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
        isUpdated: false,
      };
    case actions.DELETE_HOLDING_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
        isDeleted: false,
      };
    case actions.DELETE_HOLDING_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: true,
      };
    case actions.DELETE_HOLDING_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
        isDeleted: false,
      };
    case actions.DELETEBYDATE_HOLDING_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
        isDeleted: false,
      };
    case actions.DELETEBYDATE_HOLDING_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: true,
      };
    case actions.DELETEBYDATE_HOLDING_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
        isDeleted: false,
      };
    default:
      return state;
  }
};
