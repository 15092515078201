import React, { Component } from "react";
import { Spin } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { getEmploymentById, deleteEmployment } from "redux/employment/actions";
import { appConst, ButtonConst } from "App/AppConstant";
import { Button, Table, LayOut, Input } from "components/Form";
import { getAuthRole } from "modules/helper";

var userRole = getAuthRole();
class Employment extends Component {
  constructor(props) {
    super(props);
    this.state = { search: "", ulist: [], id: "" };
  }
  async componentDidMount() {
    try {
      const { match } = this.props;
      userRole = userRole ? userRole : getAuthRole(); // userId = userId ? userId : getAuthUserID();
      await this.props.getEmploymentById(window.atob(match?.params?.id));
      this.setState({ id: match?.params?.id });
    } catch (error) {
      console.log(error);
    }
  }
  componentDidUpdate(prevProps) {
    try {
      const { employmentData } = this.props;
      if (employmentData !== prevProps.employmentData) {
        let ulist = employmentData?.filter((a) => a.is_deleted === 0);
        this.setState({ ulist });
      }
    } catch (error) {
      console.log(error);
    }
  }
  removeData = async (i) => {
    try {
      const { id } = this.state;
      await this.props.deleteEmployment(i);
      await this.props.getEmploymentById(window.atob(id));
    } catch (error) {
      console.log(error);
    }
  };
  redirect = (link) => this.props.history.push(link);
  changetxt = (e) => this.setState({ search: e.target.value });
  goBack = () => {
    if (localStorage.url) this.props.history.push(localStorage.url);
    else this.props.history.goBack();
  };
  render() {
    const { ulist, id, search } = this.state;
    return (
      <Spin size="large" spinning={false}>
        <LayOut>
          <div className="main anime">
            <div className="head">
              <h4 className="title">{appConst.eml}</h4>
              <div className="btnDiv">
                <Input
                  placeholder={appConst.src}
                  handleChange={this.changetxt}
                />
                {(userRole !== 68 || localStorage.url === "/profile") && (
                  <Button
                    onClick={() => this.redirect("/add-employment/" + id)}
                  >
                    {ulist?.length > 0 ? ButtonConst.change : ButtonConst.add}
                  </Button>
                )}
                <Button onClick={() => this.goBack()}>
                  {ButtonConst.back}
                </Button>
              </div>
            </div>
            <div className="display">
              <Table
                data={ulist}
                type="employee"
                tab="Employment"
                deleteCol={this.removeData}
                search={search}
              />
            </div>
          </div>
        </LayOut>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.employment.loading,
  employmentData: state.employment.employmentData,
});
const mapDispatchToProps = (dispatch) => ({
  getEmploymentById: (payload) => dispatch(getEmploymentById(payload)),
  deleteEmployment: (payload) => dispatch(deleteEmployment(payload)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Employment)
);
