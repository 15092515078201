import { axiosAuthGet, axiosAuthPost } from "modules/Axios";
import { directorConst, AuditConst } from "modules/config";
import * as actions from "./constant";
import { getAuthUserID } from "modules/helper";
var userId = getAuthUserID();
userId = userId ? userId : getAuthUserID()
export const getDirectorList = () => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_DIRECTOR_LIST_INITIATED });
    let response = await axiosAuthGet(directorConst.GET_DIRECTOR_LIST);
    if (response?.success)
      await dispatch({ type: actions.GET_DIRECTOR_LIST_SUCCESS, payload: response });
    else dispatch({ type: actions.GET_DIRECTOR_LIST_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.GET_DIRECTOR_LIST_ERROR, error: "Network Error" });
  }
};

export const getDirectorById = (id) => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_DIRECTOR_BY_ID_INITIATED });
    let response = await axiosAuthGet(directorConst.GET_DIRECTOR_BY_ID + id);
    if (response?.success)
      await dispatch({ type: actions.GET_DIRECTOR_BY_ID_SUCCESS, payload: response });
    else dispatch({ type: actions.GET_DIRECTOR_BY_ID_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.GET_DIRECTOR_BY_ID_ERROR, error: "Network Error" });
  }
};

export const addDirector = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.ADD_DIRECTOR_INITIATED });
    let response = await axiosAuthPost(directorConst.ADD_DIRECTOR, payload);
    if (response?.success) {
      userId = userId ? userId : getAuthUserID();
      await axiosAuthPost(AuditConst.ADD_AUDIT, { uid: userId, text: "Director added" });
      await dispatch({ type: actions.ADD_DIRECTOR_SUCCESS, payload: response });
    } else dispatch({ type: actions.ADD_DIRECTOR_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.ADD_DIRECTOR_ERROR, error: "Network Error" });
  }
};

export const updateDirector = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.UPDATE_DIRECTOR_INITIATED });
    let response = await axiosAuthPost(directorConst.UPDATE_DIRECTOR + payload.id, payload);
    if (response?.success) {
      userId = userId ? userId : getAuthUserID();
      await axiosAuthPost(AuditConst.ADD_AUDIT, { uid: userId, toid: payload.id, text: "Director update" });
      await dispatch({ type: actions.UPDATE_DIRECTOR_SUCCESS, payload: response });
    } else dispatch({ type: actions.UPDATE_DIRECTOR_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.UPDATE_DIRECTOR_ERROR, error: "Network Error" });
  }
};

export const deleteDirector = (id) => async (dispatch) => {
  try {
    dispatch({ type: actions.DELETE_DIRECTOR_INITIATED });
    let response = await axiosAuthGet(directorConst.DELETE_DIRECTOR + id);
    if (response?.success) {
      userId = userId ? userId : getAuthUserID();
      await axiosAuthPost(AuditConst.ADD_AUDIT, { uid: userId, toid: id, text: "Director deleted" });
      await dispatch({ type: actions.DELETE_DIRECTOR_SUCCESS, payload: response });
    } else dispatch({ type: actions.DELETE_DIRECTOR_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.DELETE_DIRECTOR_ERROR, error: "Network Error" });
  }
};
