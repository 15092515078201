import React, { Component } from "react";
import * as Yup from "yup";
import { connect } from "react-redux";
import { Spin, Modal } from "antd";
import { Formik, Form } from "formik";
import { withRouter } from "react-router-dom";
import { QuestionCircleOutlined } from "@ant-design/icons";

import { PageConst } from "./constant";
import { ButtonConst, RemoveConst, FormValid } from "App/AppConstant";
import {
  Label,
  Input,
  Button,
  Row,
  Col,
  LayOut,
  Select,
} from "components/Form";
import {
  getMasterType,
  addMaster,
  getMasterById,
  updateMaster,
  getMasterList,
} from "redux/master/actions";
import { getAuthUserID } from "modules/helper";
var userId = getAuthUserID();

const ValidationSchema = Yup.object().shape({
  mname: Yup.string()
    .trim()
    .nullable()
    .required(" ")
    .matches(/^[aA-zZ\s]+$/, FormValid.alphaValid),
  typeName: Yup.string().trim().nullable().required(" "),
});
const { confirm } = Modal;

class AddMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disable: false,
      typeList: [],
      url: "",
      init: { mtype: 0, typeName: "", mname: "" },
    };
  }
  async componentDidMount() {
    try {
      const { match } = this.props;
      userId = userId ? userId : getAuthUserID();
      let link = match.path.split("/")[1];
      this.setState({ url: link.replace(/add-/g, "").replace(/edit-/g, "").replace(/-/g, " ") });
      await this.props.getMasterType();
      if (match.params.id)
        await this.props.getMasterById(window.atob(match.params.id));
    } catch (error) {
      console.log(error);
    }
  }
  async componentDidUpdate(prevProps) {
    try {
      const { masterType, isSaved, masterData, isEdited } = this.props;
      if (prevProps.isSaved !== isSaved || prevProps.isEdited !== isEdited) {
        if (isSaved || isEdited)
          this.props.history.push("/" + this.state.url.replace(/ /g, "-"));
      }
      if (prevProps.masterData !== masterData) {
        let obj = {
          id: masterData.id,
          mname: masterData.mname,
          mtype: masterData.mtype,
          typeName: masterData.typeName,
        };
        this.setState({ init: obj });
      }
      if (prevProps.masterType !== masterType) {
        let ml = [];
        const { init, url } = this.state;
        masterType?.forEach((a, i) => {
          ml.push({ value: a.mname, id: a.mtypeid });
          if (
            a.mname.toLowerCase().includes(url.toLowerCase()) ||
            url.toLowerCase().includes(a.mname.toLowerCase())
          ) {
            init.typeName = a.mname;
            init.mtype = i + 1;
            this.setState({ init });
          }
        });
        this.setState({ typeList: ml });
      }
    } catch (error) {
      console.log(error);
    }
  }
  selectUI = (val, setFieldValue, error) => {
    try {
      const { typeList } = this.state;
      return (
        <Select
          withID
          name={"typeName"}
          data={typeList}
          value={val}
          defaultValue={val}
          className={error && val === "" ? "empty" : ""}
          onChange={async (value, data) => {
            setFieldValue("typeName", value);
            setFieldValue("mtype", data.id);
          }}
        />
      );
    } catch (error) {
      console.log(error);
    }
  };
  handleSubmit = async (values, { setSubmitting }) => {
    try {
      this.setState({ disable: true });
      setTimeout(() => this.setState({ disable: false }), 5000);
      const { masterData } = this.props;
      values.mname = values.mname.trim();
      values.uid = userId;
      if (values.id) {
        if (masterData.mtype !== values.mtype) this.addCol(values);
        else await this.props.updateMaster(values);
      } else await this.props.addMaster(values);
      setSubmitting(false);
    } catch (error) {
      console.log(error, "handle error");
    }
  };
  addCol = async (values) => {
    try {
      confirm({
        title: PageConst.add + values.typeName,
        icon: <QuestionCircleOutlined />,
        content: PageConst.addMessage + values.typeName + RemoveConst.que,
        okText: RemoveConst.yes,
        okType: "default",
        cancelText: RemoveConst.no,
        getContainer: () => document.getElementById("App"),
        onOk: () => {
          this.props.addMaster({
            mname: values.mname,
            mtype: values.mtype,
            typeName: values.typeName,
          });
        },
      });
    } catch (error) {
      console.log(error);
    }
  };
  render() {
    const { init, url, disable } = this.state;
    const { loading } = this.props;
    let title = url?.replace(/(^\w|\s\w)(\S*)/g, (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase());
    title = title.replace("Upsi", "UPSI");
    return (
      <Spin size="large" spinning={loading}>
        <LayOut>
          <div className="main anime">
            <div className="head">
              <h4 className="title">
                {init.id ? PageConst.edit : PageConst.add}
                {title}
              </h4>
              <Button onClick={() => this.props.history.push("/" + url.replace(/ /g, "-"))}>
                {ButtonConst.back}
              </Button>
            </div>
            <div className="display">
              <Formik
                initialValues={init}
                validationSchema={ValidationSchema}
                onSubmit={this.handleSubmit}
                enableReinitialize
              >
                {({
                  values,
                  errors,
                  touched,
                  setFieldValue,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <Form onSubmit={handleSubmit} noValidate>
                    <Row gutter={40}>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.type}
                          className={
                            errors.typeName && touched.typeName ? "empty" : ""
                          }
                        />
                        {values.typeName === "" &&
                          this.selectUI(
                            values.typeName,
                            setFieldValue,
                            errors.typeName && touched.typeName
                          )}
                        {values.typeName !== "" &&
                          this.selectUI(values.typeName, setFieldValue)}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.val}
                          className={
                            errors.mname && touched.mname ? "empty" : ""
                          }
                        />
                        <Input
                          maxLength={25}
                          onBlur={handleBlur}
                          name="mname"
                          value={values.mname}
                          handleChange={handleChange}
                          tabIndex="2"
                          className={
                            errors.mname && touched.mname ? "empty" : ""
                          }
                        />
                        {errors.mname && touched.mname ? (
                          <div className="form-error">{errors.mname}</div>
                        ) : null}
                      </Col>
                    </Row>
                    <div className="btnDiv anime">
                      <Button disabled={disable} htmlType={"submit"}>
                        {ButtonConst.save}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </LayOut>
      </Spin>
    );
  }
}
const mapStateToProps = (state) => ({
  loading: state.master.loading,
  isSaved: state.master.isSaved,
  isEdited: state.master.isEdited,
  masterType: state.master.masterType,
  masterData: state.master.masterData,
});
const mapDispatchToProps = (dispatch) => ({
  addMaster: (payload) => dispatch(addMaster(payload)),
  updateMaster: (payload) => dispatch(updateMaster(payload)),
  getMasterList: (payload) => dispatch(getMasterList(payload)),
  getMasterType: (payload) => dispatch(getMasterType(payload)),
  getMasterById: (payload) => dispatch(getMasterById(payload)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddMaster)
);
