import axios from "axios";
 
export const getAuthToken = () => {
  if (localStorage.auth) {
    let auth = JSON.parse(localStorage.auth);
    if (auth && auth.prj === "upsi" && auth.token) return auth.token;
    return false;
  }
  return false;
};

export const getAuthUserName = () => {
  if (localStorage.auth) {
    let auth = JSON.parse(localStorage.auth);
    if (auth && auth.prj === "upsi" && auth.userName) return auth.userName;
    return false;
  }
  return false;
};
export const getAuthUserID = () => {
  if (localStorage.auth) {
    let auth = JSON.parse(localStorage.auth);
    if (auth && auth.prj === "upsi" && auth.userid) return auth.userid;
    return false;
  }
  return false;
};
export const getAuthRole = () => {
  if (localStorage.auth) {
    let auth = JSON.parse(localStorage.auth);
    if (auth && auth.prj === "upsi" && auth.uid) return auth.uid;
    return false;
  }
  return false;
};
export const getAuthName = () => {
  if (localStorage.auth) {
    let auth = JSON.parse(localStorage.auth);
    if (auth && auth.prj === "upsi" && auth.name) return auth.name;
    return false;
  }
  return false;
};

export const axiosSetApi = async() => {
  try {
    let { data: response } = await axios.get(
      "http://" + window.location.host + "/manifest.json"
      );
    let send = {
      baseUrl: "http://" + response.apiUrl + "/api/",
      mainUrl: "http://" + response.apiUrl + "/",
    };
    return send;
  } catch (error) {
    console.log(error);
  }
};
