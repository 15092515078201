import React, { Component } from "react";
import { Spin } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { getUserList } from "redux/user/actions";
import { appConst, ButtonConst } from "App/AppConstant";
import { getAuthRole, getAuthUserID } from "modules/helper";
import { Button, Table, LayOut, Row, Col } from "components/Form";
import { deleteConRef, getConRefByUId } from "redux/connRef/actions"; //getConRefList,
var role = getAuthRole();
var userId = getAuthUserID();
class ConReference extends Component {
  constructor(props) {
    super(props);
    this.state = { ulist: [], rlist: [], usrlist: [], uid: 0 };
  }
  async componentDidMount() {
    try {
      const { match } = this.props;
      userId = userId ? userId : getAuthUserID();
      role = role ? role : getAuthRole(); // await this.props.getConRefList();
      await this.props.getUserList();
      if (match?.params?.id) {
        this.setState({ uid: match?.params?.id });
        await this.props.getConRefByUId(window.atob(match?.params?.id));
      }
    } catch (error) {
      console.log(error);
    }
  }
  componentDidUpdate(prevProps) {
    try {
      const { connRefuData, userList } = this.props;
      if (userList !== prevProps.userList) {
        let data = [];
        let urlist = userList.filter((a) => a.is_deleted === 0);
        urlist?.forEach((a) => {
          if (role === 67 && a.id !== userId) {
          } else {
            a.value = a.surname ? a.name + " " + a.surname : a.name;
            let pos =
              a.uid === 66
                ? " (CO"
                : a.uid === 67
                ? " (DP"
                : a.uid === 68
                ? " (PCS"
                : " (CP";
            if (data.find((b) => b.value === a.value + pos + ")")) {
              if (data.find((b) => b.value === a.value + pos + ") 2")) {
                if (data.find((b) => b.value === a.value + pos + ") 3"))
                  data.push({ id: a.id, value: a.value + pos + ") 4" });
                else data.push({ id: a.id, value: a.value + pos + ") 3" });
              } else data.push({ id: a.id, value: a.value + pos + ") 2" });
            } else data.push({ id: a.id, value: a.value + pos + ")" });
          }
        });
        this.setState({ usrlist: data });
      }
      if (connRefuData !== prevProps.connRefuData) {
        const { usrlist } = this.state;
        let ulist = [],
          rlist = [];
        let list = connRefuData?.filter((a) => a.is_deleted === 0);
        if (role === 67)
          list = list?.filter((a) => !a.dpId || a.dpId === userId);
        list.forEach((a) => {
          if (a.dpRelationship) ulist.push(a);
          else rlist.push(a);
        });
        list.forEach((a) => {
          if (!a.dpName || a.dpName === "")
            a.dpName = usrlist.find((s) => s.id === a.dpId)?.value;
        });
        this.setState({ ulist, rlist });
      }
    } catch (error) {
      console.log(error);
    }
  }
  removeData = async (id) => {
    try {
      const { uid } = this.state;
      await this.props.deleteConRef({ id: id, uid: userId });
      await this.props.getConRefByUId(window.atob(uid));
    } catch (error) {
      console.log(error);
    }
  };
  changetxt = (e) => this.setState({ search: e.target.value });
  goBack = () => {
    if (localStorage.url) this.props.history.push(localStorage.url);
    else this.props.history.goBack();
  };
  render() {
    const { ulist, rlist, uid } = this.state;
    const { loading } = this.props;
    return (
      <Spin size="large" spinning={loading}>
        <LayOut>
          <div className="main anime">
            <div className="head">
              <h4 className="title">{appConst.cr}</h4>
              <div className="btnDiv">
                {/* <Input placeholder={appConst.src}handleChange={this.changetxt}/> */}
                {role !== 68 && (
                  <Button
                    onClick={() =>
                      this.props.history.push(
                        "/add-connected-person-relation/" + uid
                      )
                    }
                  >
                    {ButtonConst.add}
                  </Button>
                )}
                <Button onClick={() => this.goBack()}>
                  {ButtonConst.back}
                </Button>
              </div>
            </div>
            <Row gutter={40}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className="display">
                  <Table
                    data={ulist} // search={search}
                    type="connRef"
                    tab="connection reference"
                    deleteCol={this.removeData}
                  />
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className="display">
                  <Table
                    data={rlist} // search={search}
                    type="connRef2"
                    tab="connection reference"
                    deleteCol={this.removeData}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </LayOut>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.connref.loading,
  userList: state.user.userList,
  connRefuData: state.connref.connRefuData,// connRefList: state.connref.connRefList,
});
const mapDispatchToProps = (dispatch) => ({
  // getConRefList: (payload) => dispatch(getConRefList(payload)),
  deleteConRef: (payload) => dispatch(deleteConRef(payload)),
  getConRefByUId: (payload) => dispatch(getConRefByUId(payload)),
  getUserList: (payload) => dispatch(getUserList(payload)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ConReference)
);
