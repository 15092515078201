import React, { Component } from "react";
import * as Yup from "yup";
import { message, Spin } from "antd";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { PageConst } from "container/AddUser/constant";
import { ButtonConst, FormValid } from "App/AppConstant";
import {
  addEducation,
  updateEducation,
  getEducationList,
  getEducationById,
  deleteEducation,
} from "redux/education/actions";
import {
  Label,
  Input,
  Button,
  Row,
  Col,
  Collapse,
  LayOut,
  DatePicker,
} from "components/Form";
import { getAuthUserID } from "modules/helper";
var userId = getAuthUserID();
const ValidationSchema = Yup.object().shape({
  edu: Yup.string()
    .trim()
    .nullable()
    .required(" ")
    .min(2)
    .max(55)
    .matches(/^[aA-zZ\s]+$/, FormValid.alphaValid),
  year: Yup.string()
    .trim()
    .nullable()
    .required(" ")
    .min(4)
    .max(4)
    .matches(/^[aA-zZ0-9\s]+$/, FormValid.digitsValid),
  uni: Yup.string()
    .trim()
    .nullable()
    .min(2)
    .max(55)
    .matches(/^[aA-zZ\s.,-]+$/, FormValid.charValid),
  date: Yup.string().trim().nullable().min(2).max(55), //.matches(/^[aA-zZ0-9\s]+$/, FormValid.charValid),
  grade: Yup.string()
    .trim()
    .nullable()
    .max(55)
    .matches(/^[aA-zZ0-9%/\s]+$/, FormValid.charValid),
});
class EducationDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 1,
      id: "",
      disable: false,
      show: true,
      deleteEdu: [],
      init: { id: 0, edu: "", uni: "", date: "", year: "", grade: "" },
      education: [{ id: 0, edu: "", uni: "", date: "", year: "", grade: "" }],
      counter: 0,
    };
  }
  async componentDidMount() {
    try {
      const { match } = this.props;
      userId = userId ? userId : getAuthUserID();
      await this.props.getEducationById(window.atob(match?.params?.id));
      this.setState({ id: match?.params?.id });
    } catch (error) {
      console.log(error);
    }
  }
  componentDidUpdate(prevProps) {
    try {
      const { educationData } = this.props;
      if (educationData !== prevProps.educationData) {
        let arr = [];
        educationData?.forEach((a) => {
          let data = {
            id: a.id,
            uId: a.uId,
            uni: a.university,
            edu: a.qualification,
            date: a.enrollmentDate,
            year: a.passingYear,
            grade: a.grade,
          };
          if (a.is_deleted === 0) arr.push(data);
        });
        if (arr.length > 0)
          this.setState({ education: arr, counter: arr.length });
      }
    } catch (error) {
      console.log(error);
    }
  }
  addEdu = (key, i, val) => {
    try {
      const { education, init } = this.state;
      if (key === i + 1) education[key - 1] = val;
      else education[i] = val;
      education[key] = init;
      this.setState({ education, counter: key });
    } catch (error) {
      console.log(error);
    }
  };
  save = (key, val) => {
    try {
      const { education } = this.state;
      education[key - 1] = val;
      this.setState({ education });
    } catch (error) {
      console.log(error);
    }
  };
  remove = (key, handleReset) => {
    try {
      const { deleteEdu } = this.state;
      const { education, counter } = this.state;
      education[key].id !== 0 && deleteEdu.push(education[key].id);
      education.splice(key, 1);
      this.setState({ education, counter: counter - 1, deleteEdu });
      handleReset();
    } catch (error) {
      console.log(error);
    }
  };
  btnUI = (i, values, handleReset) => {
    try {
      const { education, counter, disable } = this.state;
      let length = education.length;
      if (!education[length - 1]) length = length - 1;
      return (
        <div className="btnDiv">
          {length - 1 === i ? (
            <>
              {values.edu !== "" && values.year !== "" && (
                <Button
                  onClick={() => {
                    this.addEdu(counter + 1, i, values);
                  }}
                >
                  {ButtonConst.an}
                </Button>
              )}
              <Button
                htmlType="submit"
                disabled={disable}
                onClick={() => {
                  this.save(i + 1, values);
                }}
              >
                {ButtonConst.save}
              </Button>
            </>
          ) : (
            <Button
              htmlType="submit"
              onClick={() => {
                this.save(i + 1, values);
              }}
            >
              {ButtonConst.save}
            </Button>
          )}
          {length > 1 && (
            <Button
              onClick={() => {
                this.remove(i, handleReset);
              }}
            >
              {ButtonConst.remove}
            </Button>
          )}
        </div>
      );
    } catch (error) {
      console.log(error);
    }
  };
  handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { education, id, deleteEdu } = this.state;
      this.setState({ disable: true });
      setTimeout(() => this.setState({ disable: false }), 5000);
      education.forEach(async (a) => {
        if (a.edu && a.year) {
          let data = {
            uId: window.atob(id),
            university: a.uni,
            qualification: a.edu,
            enrollmentDate: a.date,
            passingYear: a.year,
            grade: a.grade?.toUpperCase(),
          };
          if (a.id > 0) {
            data.id = a.id;
            await this.props.updateEducation(data);
          } else await this.props.addEducation(data);
        }
      });
      deleteEdu?.forEach(async (c) => await this.props.deleteEducation(c));
      message.success("Data saved");
      this.back(id);
      setSubmitting(false);
    } catch (error) {
      console.log(error);
    }
  };
  back = (id) => this.props.history.push("/education/" + id);
  render() {
    const { education, id } = this.state;
    const { loading } = this.props;
    return (
      <Spin size="large" spinning={loading}>
        <LayOut link={"add-user"}>
          <div className="main anime">
            <div className="head">
              <h4 className="title">{PageConst.ed}</h4>
              <div className="bottomDiv">
                <div className="btn">
                  <Button onClick={() => this.back(id)}>
                    {ButtonConst.back}
                  </Button>
                </div>
              </div>
            </div>
            {education?.map((data, i) => (
              <Collapse
                key={i}
                data={[
                  {
                    header: PageConst.edu,
                    pData: (
                      <Formik
                        initialValues={data}
                        validationSchema={ValidationSchema}
                        onSubmit={this.handleSubmit}
                        enableReinitialize
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          handleReset,
                        }) => (
                          <Form
                            onSubmit={handleSubmit}
                            noValidate
                            key={"edu" + i}
                          >
                            <Row gutter={24}>
                              <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                                <Label
                                  title={PageConst.eduQ}
                                  className={
                                    errors.edu && touched.edu ? "empty" : ""
                                  }
                                />
                                <Input
                                  onBlur={handleBlur}
                                  name="edu"
                                  value={values.edu}
                                  handleChange={handleChange}
                                  tabIndex="1"
                                  className={
                                    errors.edu && touched.edu ? "empty" : ""
                                  }
                                />
                                {errors.edu && touched.edu ? (
                                  <div className="form-error">{errors.edu}</div>
                                ) : null}
                              </Col>
                              <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                                <Label title={PageConst.uniName} />
                                <Input
                                  onBlur={handleBlur}
                                  name="uni"
                                  value={values.uni}
                                  handleChange={handleChange}
                                  tabIndex="2"
                                  className={
                                    errors.uni && touched.uni ? "empty" : ""
                                  }
                                />
                                {errors.uni && touched.uni ? (
                                  <div className="form-error">{errors.uni}</div>
                                ) : null}
                              </Col>
                              <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                                <Label title={PageConst.date} />
                                <DatePicker
                                  value={values.date}
                                  handleChange={handleChange("date")}
                                  className={
                                    errors.date && touched.date ? "empty" : ""
                                  }
                                  tabIndex="3"
                                />
                                {/* <Input onBlur={handleBlur}name="date"value={values.date}handleChange={handleChange}tabIndex="3"/> */}
                                {errors.date && touched.date ? (
                                  <div className="form-error">
                                    {errors.date}
                                  </div>
                                ) : null}
                              </Col>
                              <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                                <Label
                                  title={PageConst.year}
                                  className={
                                    errors.year && touched.year ? "empty" : ""
                                  }
                                />
                                <Input
                                  type="number"
                                  max={new Date().getFullYear()}
                                  min="1910"
                                  onBlur={handleBlur}
                                  name="year"
                                  value={values.year}
                                  handleChange={handleChange}
                                  tabIndex="4"
                                  className={
                                    errors.year && touched.year ? "empty" : ""
                                  }
                                />
                                {errors.year && touched.date ? (
                                  <div className="form-error">
                                    {errors.year}
                                  </div>
                                ) : null}
                              </Col>
                              <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                                <Label
                                  title={PageConst.grade}
                                  className={
                                    errors.grade && touched.grade ? "empty" : ""
                                  }
                                />
                                <Input
                                  className={
                                    errors.grade && touched.grade ? "empty" : ""
                                  }
                                  maxLength={25}
                                  onBlur={handleBlur}
                                  name="grade"
                                  value={values.grade?.toUpperCase()}
                                  handleChange={handleChange}
                                  tabIndex="5"
                                />
                                {errors.grade && touched.grade ? (
                                  <div className="form-error">
                                    {errors.grade}
                                  </div>
                                ) : null}
                              </Col>
                            </Row>
                            {this.btnUI(i, values, handleReset)}
                          </Form>
                        )}
                      </Formik>
                    ),
                  },
                ]}
              />
            ))}
          </div>
        </LayOut>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.education.loading,
  educationData: state.education.educationData,
});
const mapDispatchToProps = (dispatch) => ({
  addEducation: (payload) => dispatch(addEducation(payload)),
  getEducationList: (payload) => dispatch(getEducationList(payload)),
  updateEducation: (payload) => dispatch(updateEducation(payload)),
  getEducationById: (payload) => dispatch(getEducationById(payload)),
  deleteEducation: (payload) => dispatch(deleteEducation(payload)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EducationDetail)
);
