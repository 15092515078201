export const GET_TRADING_LIST_INITIATED = "GET_TRADING_LIST_INITIATED";
export const GET_TRADING_LIST_SUCCESS = "GET_TRADING_LIST_SUCCESS";
export const GET_TRADING_LIST_ERROR = "GET_TRADING_LIST_ERROR";

export const GET_TRADING_BY_ID_INITIATED = "GET_TRADING_BY_ID_INITIATED";
export const GET_TRADING_BY_ID_SUCCESS = "GET_TRADING_BY_ID_SUCCESS";
export const GET_TRADING_BY_ID_ERROR = "GET_TRADING_BY_ID_ERROR";

export const ADD_TRADING_INITIATED = "ADD_TRADING_INITIATED";
export const ADD_TRADING_SUCCESS = "ADD_TRADING_SUCCESS";
export const ADD_TRADING_ERROR = "ADD_TRADING_ERROR";

export const UPDATE_TRADING_INITIATED = "UPDATE_TRADING_INITIATED";
export const UPDATE_TRADING_SUCCESS = "UPDATE_TRADING_SUCCESS";
export const UPDATE_TRADING_ERROR = "UPDATE_TRADING_ERROR";

export const DELETE_TRADING_INITIATED = "DELETE_TRADING_INITIATED";
export const DELETE_TRADING_SUCCESS = "DELETE_TRADING_SUCCESS";
export const DELETE_TRADING_ERROR = "DELETE_TRADING_ERROR";