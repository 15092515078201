export const GET_CONNREFERENCE_LIST_INITIATED = "GET_CONNREFERENCE_LIST_INITIATED";
export const GET_CONNREFERENCE_LIST_SUCCESS = "GET_CONNREFERENCE_LIST_SUCCESS";
export const GET_CONNREFERENCE_LIST_ERROR = "GET_CONNREFERENCE_LIST_ERROR";

export const GET_CONNREFERENCE_BY_ID_INITIATED = "GET_CONNREFERENCE_BY_ID_INITIATED";
export const GET_CONNREFERENCE_BY_ID_SUCCESS = "GET_CONNREFERENCE_BY_ID_SUCCESS";
export const GET_CONNREFERENCE_BY_ID_ERROR = "GET_CONNREFERENCE_BY_ID_ERROR";

export const GET_CONNREFERENCE_BY_UID_INITIATED = "GET_CONNREFERENCE_BY_UID_INITIATED";
export const GET_CONNREFERENCE_BY_UID_SUCCESS = "GET_CONNREFERENCE_BY_UID_SUCCESS";
export const GET_CONNREFERENCE_BY_UID_ERROR = "GET_CONNREFERENCE_BY_UID_ERROR";

export const GET_CONNREFERENCE_BY_DPID_INITIATED = "GET_CONNREFERENCE_BY_DPID_INITIATED";
export const GET_CONNREFERENCE_BY_DPID_SUCCESS = "GET_CONNREFERENCE_BY_DPID_SUCCESS";
export const GET_CONNREFERENCE_BY_DPID_ERROR = "GET_CONNREFERENCE_BY_DPID_ERROR";

export const ADD_CONNREFERENCE_INITIATED = "ADD_CONNREFERENCE_INITIATED";
export const ADD_CONNREFERENCE_SUCCESS = "ADD_CONNREFERENCE_SUCCESS";
export const ADD_CONNREFERENCE_ERROR = "ADD_CONNREFERENCE_ERROR";

export const UPDATE_CONNREFERENCE_INITIATED = "UPDATE_CONNREFERENCE_INITIATED";
export const UPDATE_CONNREFERENCE_SUCCESS = "UPDATE_CONNREFERENCE_SUCCESS";
export const UPDATE_CONNREFERENCE_ERROR = "UPDATE_CONNREFERENCE_ERROR";

export const DELETE_CONNREFERENCE_INITIATED = "DELETE_CONNREFERENCE_INITIATED";
export const DELETE_CONNREFERENCE_SUCCESS = "DELETE_CONNREFERENCE_SUCCESS";
export const DELETE_CONNREFERENCE_ERROR = "DELETE_CONNREFERENCE_ERROR";