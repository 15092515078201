import React, { Component } from "react";
import * as Yup from "yup";
import { message } from "antd";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import { withRouter } from "react-router-dom";

import { PageConst } from "./constant";
import { ButtonConst } from "App/AppConstant";
import { getAuthRole, getAuthUserID } from "modules/helper";
import { Label, Input, Button, FileUpload } from "components/Form";
import { addDeclaration } from "redux/declaration/actions";
var role = getAuthRole();
var userId = getAuthUserID();
const ValidationSchema = Yup.object().shape({
  name: Yup.string().trim().nullable().required(" "),
});

class AddDeclaration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disable: false,
      form: false,
      docByte: "",
      docnm: "",
      init: { name: "" },
    };
  }
  async componentDidMount() {
    try {
      const { match } = this.props;
      role = role ? role : getAuthRole();
      userId = userId ? userId : getAuthUserID();
      let path = match.path.replace(/\//g, "");
      if (path === "add-draft-forms") this.setState({ form: true });
      // if (match?.path?.toLowerCase().includes("/edit-declaration/"))
      //   match.params.id && (await this.props.getDeclarationById(window.atob(match.params.id)));
    } catch (error) {
      console.log(error);
    }
  }
  componentDidUpdate(prevProps) {
    try {
      const { docData, isSaved } = this.props;
      if (prevProps.isSaved !== isSaved)
        this.setState({ init: { name: "" }, docByte: "", docnm: "" });
      if (prevProps.docData !== docData)
        this.setState({ init: { name: docData?.name, docnm: docData?.dname } }); //docByte: docData?.dmurl,
    } catch (error) {
      console.log(error);
    }
  }
  handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      this.setState({ disable: true });
      setTimeout(() => this.setState({ disable: false }), 5000);
      const { docByte, docnm, form } = this.state;
      if (docnm !== "") {
        values.form = form ? "form" : "";
        values.dname = docnm; // values.dmurl = docByte;
        values.uid = userId;
        values.type = role === 66 ? "Approved" : "Pending";
        let postData = { val: values };
        postData.data = docByte;
        await this.props.addDeclaration(postData);
        form && this.props.history.goBack();
      } else message.warning("Add Document");
      setSubmitting(false);
      resetForm();
    } catch (error) {
      console.log(error, "handle error");
    }
  };

  seByte = (byteCode, name) =>
    this.setState({ docByte: byteCode, docnm: name });
  render() {
    const { init, disable, docByte, docnm, form } = this.state;
    return (
      <div className="anime">
        {/* <div className="head"><h4 className="title">{PageConst.ad}</h4><Button onClick={() => this.props.history.push("/declarations")}>{ButtonConst.back}</Button></div> */}
        <div className="display">
          <Formik
            initialValues={init}
            validationSchema={ValidationSchema}
            onSubmit={this.handleSubmit}
            enableReinitialize
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form onSubmit={handleSubmit} noValidate>
                <div>
                  <Label
                    title={PageConst.nd}
                    className={errors.name && touched.name ? "empty" : ""}
                  />
                  <Input
                    name="name"
                    value={values.name}
                    onBlur={handleBlur}
                    handleChange={handleChange}
                    tabIndex="1"
                    className={errors.name && touched.name ? "empty" : ""}
                  />
                </div>
                <div>
                  <Label title={PageConst.dl} />
                  <FileUpload
                    image
                    nm={PageConst.up}
                    accept={form ? ".docx" : ""}
                    file={docByte}
                    name={docnm}
                    sendByte={this.seByte}
                    tabIndex="2"
                  />
                </div>
                <div className="btnDiv anime">
                  <Button disabled={disable} htmlType={"submit"}>
                    {ButtonConst.save}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.declaration.loading,
  docData: state.declaration.docData,
  isSaved: state.declaration.isSaved,
});
const mapDispatchToProps = (dispatch) => ({
  addDeclaration: (payload) => dispatch(addDeclaration(payload)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddDeclaration)
);
