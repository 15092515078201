import * as actions from "./constant";
const initialState = {
  error: false,
  message: false,
  loading: false,
  isSaved: false,
  isDeleted: false,
  isEdited: false,
  closureData: {},
  closureList: [],
  closureMeny: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_CLOSURE_LIST_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
      };
    case actions.GET_CLOSURE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        closureList: action.payload.data,
      };
    case actions.GET_CLOSURE_LIST_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
      };
    case actions.GET_CLOSURE_BY_ID_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
      };
    case actions.GET_CLOSURE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        closureData: action.payload.data,
      };
    case actions.GET_CLOSURE_BY_ID_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
      };
    case actions.GET_MENYCLOSURE_BY_ID_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
      };
    case actions.GET_MENYCLOSURE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        closureMeny: action.payload.data,
      };
    case actions.GET_MENYCLOSURE_BY_ID_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
      };
    case actions.ADD_CLOSURE_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
        isSaved: false,
      };
    case actions.ADD_CLOSURE_SUCCESS:
      return {
        ...state,
        loading: false,
        isSaved: true,
      };
    case actions.ADD_CLOSURE_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
        isSaved: false,
      };
    case actions.UPDATE_CLOSURE_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
        isEdited: false,
      };
    case actions.UPDATE_CLOSURE_SUCCESS:
      return {
        ...state,
        loading: false,
        isEdited: true,
      };
    case actions.UPDATE_CLOSURE_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
        isEdited: false,
      };
    case actions.DELETE_CLOSURE_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
        isDeleted: false,
      };
    case actions.DELETE_CLOSURE_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: true,
      };
    case actions.DELETE_CLOSURE_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
        isDeleted: false,
      };
    default:
      return state;
  }
};
