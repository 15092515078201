import React, { Component } from "react";
import { Tooltip } from "antd";
import axios from "axios";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { DownloadOutlined, DeleteOutlined } from "@ant-design/icons";

// import { configVar } from "modules/config";
import { ButtonConst } from "App/AppConstant";
import { DecConst, downName } from "./constant";
import { LayOut, Table, Col, Button } from "components/Form";
import { getAuthRole, getAuthUserID } from "modules/helper";
import {
  getDeclarationList,
  deleteDeclaration,
} from "redux/declaration/actions";
var role = getAuthRole();
var userId = getAuthUserID();
class FroDown extends Component {
  constructor(props) {
    super(props);
    this.state = { search: "", dList: [],configVar:{} };
  }
  async componentDidMount() {
    try {
      this.setconfig();
      role = role ? role : getAuthRole();
      userId = userId ? userId : getAuthUserID();
      if (localStorage.url === "/profile") localStorage.url = "/";
      await this.props.getDeclarationList();
    } catch (error) {
      console.log(error);
    }
  }
  componentDidUpdate(prevProps) {
    try {
      const { docList } = this.props;
      if (prevProps.docList !== docList) {
        let dList = docList?.filter(
          (a) => a.is_deleted === 0 && a.form === "form"
        );
        dList?.forEach((a) => {
          a.id = a.dmid;
          a.url = "files/" + a.dname;
        }); // a.name = a.name;//dname?.split(".")[0];
        let data = downName.concat(dList);
        this.setState({ dList: data });
      }
    } catch (error) {
      console.log(error);
    }
  }
  setconfig=async()=>{
    try {
      let { data: response } = await axios.get(
        "http://" + window.location.host + "/manifest.json"
        );
        let send = {
          BASE_URL: "http://" + response.apiUrl + "/api/",
          MAIN_URI: "http://" + response.apiUrl + "/",
        };
        this.setState({configVar:send});
    } catch (error) {
      console.log(error);
    }
  }
  removeData = async (id) => {
    try {
      await this.props.deleteDeclaration({ id: id, uid: userId });
      await this.props.getDeclarationList();
    } catch (error) {
      console.log(error);
    }
  };
  downLink = () => {
    try {
      const { dList,configVar } = this.state;
      let data = downName.concat(dList);
      return data?.map((a) => (
        <Col xs={24} sm={24} md={24} lg={24} xl={12} className="formsDownDIV">
          <a className="downloadTag" href={configVar.BASE_URL + a.url}>
            <DownloadOutlined />
            {a.name}
          </a>
          {role === 66 && a.dmid && (
            <Tooltip title={ButtonConst.delete} className="pointer">
              <DeleteOutlined onClick={() => this.removeData(a.dmid)} />
            </Tooltip>
          )}
        </Col>
      ));
    } catch (error) {
      console.log(error);
    }
  };
  changetxt = (e) => this.setState({ search: e.target.value });
  render() {
    const { dList } = this.state;
    return (
      <LayOut>
        <div className="main anime">
          <div className="head">
            <h4 className="title">{DecConst.dec}</h4>
            <div className="btnDiv">
              {role === 66 && (
                <Button
                  onClick={() => this.props.history.push("/add-draft-forms")}
                >
                  {ButtonConst.upload}
                </Button>
              )}
            </div>
          </div>
          {/* <div className="display"><Row gutter={10}>{this.downLink()}</Row></div> */}
          <div className="display">
            <Table
              data={dList}
              type="forms"
              tab="forms"
              deleteCol={this.removeData}
            />
          </div>
        </div>
      </LayOut>
    );
  }
}
const mapStateToProps = (state) => ({
  docList: state.declaration.docList,
});
const mapDispatchToProps = (dispatch) => ({
  deleteDeclaration: (payload) => dispatch(deleteDeclaration(payload)),
  getDeclarationList: (payload) => dispatch(getDeclarationList(payload)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FroDown)
);
