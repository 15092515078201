const PageConst = {
  ui: "UPSI Information",
  ud: "UPSI DateTime",
  cp: "Connected Person",
  person: "User*",
  client: "Client",
  title: "Pre Clearance-Request form",
  purpose: "Purpose*",
  oldApp: "Old Application No",
  rPurpose: "Reapplication Purpose",
  startDate: "Proposed date Starting*",
  endDate: "Proposed date Ending*",
  securityNo: "Proposed Quantity*", // pdm:"Proposed date Max*",
  ptp: "Proposed Transaction Price*",
  cmp: "Current Market Price",
  tv: "Transaction Via",
  sm: "Open Market",
  of: "Off Market",
  mDate: "Proposed ending date is greater than starting date",
  smDate: "Proposed date starting shold be not more than 7 days from today",
  mdDate: "Proposed date ending shold be not more than 7 business days from start day",
  total: "Total Security Held",
  msg: "User can not trade from ",
  to: " to ",
  pfu: "Please fill Form F and upload it",
  up: "Upload Form",
  dwn: " Download Form",
  holdmsg: "User can not sell because user's Total Security Held is 0.",
  holdmsg2: "User can not sell because user don't have enough quantity to sell.",
  frm: "FORM F Undertaking*",
};
const PurConst = [
  { id: 1, value: "Buy" },
  { id: 2, value: "Sale" },
];
const Holidays = [
  "Jan 26, 2021",
  "Mar 11, 2021",
  "Mar 29, 2021",
  "Apr 02, 2021",
  "Apr 14, 2021",
  "Apr 21, 2021",
  "Apr 25, 2021",
  "May 01, 2021",
  "May 13, 2021",
  "Jul 20, 2021",
  "Sep 10, 2021",
  "Oct 02, 2021",
  "Oct 15, 2021",
  "Oct 20, 2021",
  "Nov 04, 2021",
  "Nov 19, 2021",
  "Dec 25, 2021",
  "Jan 26, 2022",
  "Mar 01, 2022",
  "Mar 19, 2022",
  "Apr 10, 2022",
  "Apr 14, 2022",
  "Apr 14, 2022",
  "Apr 15, 2022",
  "May 01, 2022",
  "May 03, 2022",
  "Jul 10, 2022",
  "Aug 31, 2022",
  "Oct 02, 2022",
  "Oct 05, 2022",
  "Oct 24, 2022",
  "Oct 24, 2022",
  "Nov 08, 2022",
  "Dec 25, 2022",
  "Jan 26, 2023",
  "Mar 08, 2023",
  "Mar 18, 2023",
  "Mar 30, 2023",
  "Apr 04, 2023",
  "Apr 07, 2023",
  "Apr 14, 2023",
  "Apr 22, 2023",
  "May 01, 2023",
  "Jun 28, 2023",
  "Aug 15, 2023",
  "Aug 29, 2023",
  "Sep 19, 2023",
  "Oct 02, 2023",
  "Oct 24, 2023",
  "Nov 12, 2023",
  "Nov 14, 2023",
  "Nov 27, 2023",
  "Dec 25, 2023",
  "Jan 26, 2024",
  "Mar 08, 2024",
  "Mar 25, 2024",
  "Mar 29, 2024",
  "Apr 10, 2024",
  "Apr 14, 2024",
  "Apr 17, 2024",
  "Apr 21, 2024",
  "May 01, 2024",
  "Jun 17, 2024",
  "Jul 17, 2024",
  "Aug 15, 2024",
  "Sep 07, 2024",
  "Oct 02, 2024",
  "Oct 13, 2024",
  "Nov 01, 2024",
  "Nov 02, 2024",
  "Nov 15, 2024",
  "Dec 25, 2024",
];

export { PageConst, PurConst, Holidays };
