import React, { Component } from "react";
import { Row } from "antd";

export default class RowUI extends Component {
  render() {
    const { children, gutter, ...props } = this.props;
    return (
      <Row gutter={gutter ? gutter : 0} {...props}>
        {children}
      </Row>
    );
  }
}
