const PageConst = {
  dl:"Upload",
  ad:"Add BenPOS",
  date:"Date*",
  ed:"Edit BenPOS",
  isin:"ISIN",
  up:"Upload Document",
  ucd:"Upload correct Document",
  dcd:"Please Select Date Which Greater Than Last Added Date"
};
export { PageConst };
