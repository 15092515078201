import * as actions from "./constant";
const initialState = {
  error: false,
  message: false,
  loading: false,
  isSaved: false,
  isDeleted: false,
  isEdited: false,
  dashData: [],
  graphData: [],
  preData: [],
  uiData: [],
  calData: [],
  muList: [],
  yuList: [],
  mpList: [],
  ypList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_DASHBOARD_LIST_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
      };
    case actions.GET_DASHBOARD_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        dashData: action.payload.data,
      };
    case actions.GET_DASHBOARD_LIST_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
      };
    case actions.GET_DASHBOARD_GRAPH_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
      };
    case actions.GET_DASHBOARD_GRAPH_SUCCESS:
      return {
        ...state,
        loading: false,
        graphData: action.payload.data,
      };
    case actions.GET_DASHBOARD_GRAPH_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
      };
    case actions.GET_DASHPGRAPH_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
      };
    case actions.GET_DASHPGRAPH_SUCCESS:
      return {
        ...state,
        loading: false,
        preData: action.payload.data,
      };
    case actions.GET_DASHPGRAPH_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
      };
    case actions.GET_DASHGRAPH_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
      };
    case actions.GET_DASHGRAPH_SUCCESS:
      return {
        ...state,
        loading: false,
        uiData: action.payload.data,
      };
    case actions.GET_DASHGRAPH_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
      };
    case actions.GET_DASHBOARD_CAL_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
      };
    case actions.GET_DASHBOARD_CAL_SUCCESS:
      return {
        ...state,
        loading: false,
        calData: action.payload.data,
      };
    case actions.GET_DASHBOARD_CAL_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
      };
      
    case actions.GET_DASHGRAPH_MU_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
      };
    case actions.GET_DASHGRAPH_MU_SUCCESS:
      return {
        ...state,
        loading: false,
        muList: action.payload.data,
      };
    case actions.GET_DASHGRAPH_MU_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
      };
    case actions.GET_DASHGRAPH_YU_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
      };
    case actions.GET_DASHGRAPH_YU_SUCCESS:
      return {
        ...state,
        loading: false,
        yuList: action.payload.data,
      };
    case actions.GET_DASHGRAPH_YU_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
      };
    case actions.GET_DASHGRAPH_YP_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
      };
    case actions.GET_DASHGRAPH_YP_SUCCESS:
      return {
        ...state,
        loading: false,
        ypList: action.payload.data,
      };
    case actions.GET_DASHGRAPH_YP_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
      };
    case actions.GET_DASHGRAPH_MP_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
      };
    case actions.GET_DASHGRAPH_MP_SUCCESS:
      return {
        ...state,
        loading: false,
        mpList: action.payload.data,
      };
    case actions.GET_DASHGRAPH_MP_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
      };
    default:
      return state;
  }
};
