const PageConst = {
  title: "UPSI"
}
const MenuItem = [
  { name: "Dashboard" },
  { name: "User" },
  { name: "UPSI Information" },
  { name: "Window Closure" },
  { name: "Trading" },
  { name: "Disclosures" },
  { name: "BenPOS" },
  { name: "Declarations" },
  { name: "Reports" },
  { name: "Configurations" },
];
const DPItem = [
  { name: "Dashboard" },
  { name: "Connected Person" },
  { name: "UPSI Information" },
  { name: "Window Closure" },
  { name: "Trading" },
  { name: "Disclosures" },
  { name: "Declarations" },
  { name: "Draft Forms" },
];
const MobileMenu = [{ name: "Profile" }, { name: "Change Password" }, { name: "Log-out" }];
// const MasterMenu = ["Users", ];
const UserMenu = ["Compliance Officer", "Designated Person", "PCS", "Connected Person"];
const ConfMenu = [
  "Company",
  "User Role",
  "User Category",
  "UPSI Type",
  "Sharing Mode",
  "Purpose for UPSI",
  "Relationship Type",
  "Reference",
  "Security Type",
  "Templates",
  "SMTP Settings",
  "Draft Forms"
];
const PcsMenu = [
  "User Role",
  "User Category",
  "UPSI Type",
  "Sharing Mode",
  "Purpose for UPSI",
  "Relationship Type",
  "Reference",
  "Security type",
  "Draft Forms"
];
export { MenuItem, MobileMenu, UserMenu, ConfMenu, DPItem, PcsMenu, PageConst };
