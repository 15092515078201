const PageConst = {
  ad: "Add Connected Person",
  ed: "Edit Connected Person",
  ubd: "User Basic Detail",
  edu: "Education Details",
  emp: "Employment Details",
  bsd: "Basic Detail",
  ld: "Location Detail",
  cd: "Contact Detail",
  pd: "Personal Detail",
  bd: "Bank Details",
  name: "First Name*",
  userId: "User ID",
  surname: "Last Name*",
  fatherName: "Middle Name",
  email: "Email ID*",
  userType: "User Type*",
  category: "Category*",
  mobile: "Mobile No.",
  jd: "Joining Date",
  pan: "PAN*",
  aadhar: "Aadhar No.",
  gender: "Gender*",
  nation: "Nationality*",
  driving: "Driving License",
  country: "Country",
  state: "State*",
  city: "City*",
  pin: "Pin Code*",
  passport: "Passport No.",
  address: "Address*",
  bank: "Bank Name",
  account: "Account No",
  ifsc: "IFSC Code",
  branch: "Branch Name",
  din: "DIN",
  dpid: "DP ID",
  role: "Role",
  client: "Client ID",
  appiotment: "Date of Appointment",
  num: "Sr. No.",
  eduQ: "Education Qualification",
  uniName: "University Name",
  QuaName: "Qualification Name",
  date: "Date of Enrollment",
  year: "Year of Passing",
  grade: "Grade",
  direct: "Directorship",
  cname: "Name of Company",
  joinDate: "Date of Joining",
  exitDate: "Date of Exit",
  designation: "Designation",
  ae: "Add Education",
  un: "User Name*",
  password: "Password",
  panErr: "PAN number is already used",
};

export { PageConst };
