const FormValid = {
  emailRequired: "Email address is required.",
  emailInvalid: "Please enter valid email address.",
  panValid: "Please enter valid PAN number.",
  alphaValid: "Only alphabets are allowed.",
  digitsValid: "Only digits are allowed.",
  charValid: "Only Valid Character are allowed.",
  gstValid: "Only Valid GST number are allowed.",
  isinValid: "Only Valid ISIN number are allowed.",
  cinValid: "Only Valid cin number are allowed.",
  passportValid: "Please enter valid passport number.",
  dpidValid: "Please enter valid DPId.",
  ifscValid: "Please enter valid ifsc code.",
  pinInvalid: "Only 4 character allowed.",
  twoInvalid: "Field must be at least 2 characters",
  passwordMin: "Password must be at least 4 characters",
  exitValid: "Exit date should be greater than joining date",
  repatePWD: "'New Password' & 'Conform New Password' need to be the same.",
};
// passwordInvalid:"Must Contain One Uppercase, One Lowercase, One Number and One Special Case Character",
const ButtonConst = {
  cancel: "Cancel",
  save: "Save",
  reset: "Reset",
  remove: "Remove",
  edit: "Edit",
  view: "View",
  open: "Open",
  submit: "Submit",
  delete: "Delete",
  download: "Download",
  upload: "Upload",
  add: "Add",
  next: "Next",
  back: "Back",
  previous: "Previous",
  adda: "Add Another",
  an: "Add New",
  change: "Change",
  approve: "Approve",
  reject: "Reject",
  sall: "Select All",
  dsall: "Deselect All",
  clos: " Closure",
  search: "Search",
  sendMail: "Send Mail",
  rsm: "Resend Mail",
  added: " Added",

};
const RemoveConst = {
  yes: "Yes",
  danger: "danger",
  no: "No ",
  header: "Delete ",
  que: " ?",
  title: "Log-Out",
  deleteMessage: "Are you sure you want to remove ",
  logMessage: "Are you sure you want to logout?",
  dropText: "Drop image here or click to browse file here",
};

const appConst = {
  el: "Education List",
  eml: "Employment List",
  bd: "Bank Details",
  drl: "Directorship List",
  dl: "Disclosures List",
  dec: "Declaration",
  cl: "Company Information",
  cr: "Connection Reference",
  ul: "User List",
  ben: "BenPOS Date List",
  benl: "BenPOS List",
  holding: "Holding List",
  upl: "UPSI Information List",
  aul: "UPSI Approval Information",
  src: "Search..",
  af: "Add Form",
};
const panConst = /[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$/;
const ifscConst = /^[A-Z]{4}0[A-Z0-9]{6}$/;
const pwdMatch =
  /^(?=.*[aA-zZ])(?=.*\d)(?=.*[@$*#?&^_()[\]])[aA-zZ\d@$*#?&^_()[\]]{8,}$/;
const Holidays = ["Jan 26, 2021", "Mar 11, 2021", "Mar 29, 2021", "Apr 02, 2021", "Apr 14, 2021", "Apr 21, 2021", "Apr 25, 2021", "May 01, 2021", "May 13, 2021", "Jul 20, 2021", "Sep 10, 2021", "Oct 02, 2021", "Oct 15, 2021", "Oct 20, 2021", "Nov 04, 2021", "Nov 19, 2021", "Dec 25, 2021", "Jan 26, 2022", "Mar 01, 2022", "Mar 19, 2022", "Apr 10, 2022", "Apr 14, 2022", "Apr 14, 2022", "Apr 15, 2022", "May 01, 2022", "May 03, 2022", "Jul 10, 2022", "Aug 31, 2022", "Oct 02, 2022", "Oct 05, 2022", "Oct 24, 2022", "Oct 24, 2022", "Nov 08, 2022", "Dec 25, 2022", "Jan 26, 2023", "Mar 08, 2023", "Mar 18, 2023", "Mar 30, 2023", "Apr 04, 2023", "Apr 07, 2023", "Apr 14, 2023", "Apr 22, 2023", "May 01, 2023", "Jun 28, 2023", "Aug 15, 2023", "Aug 29, 2023", "Sep 19, 2023", "Oct 02, 2023", "Oct 24, 2023", "Nov 12, 2023", "Nov 14, 2023", "Nov 27, 2023", "Dec 25, 2023", "Jan 26, 2024", "Mar 08, 2024", "Mar 25, 2024", "Mar 29, 2024", "Apr 10, 2024", "Apr 14, 2024", "Apr 17, 2024", "Apr 21, 2024", "May 01, 2024", "Jun 17, 2024", "Jul 17, 2024", "Aug 15, 2024", "Sep 07, 2024", "Oct 02, 2024", "Oct 13, 2024", "Nov 01, 2024", "Nov 02, 2024", "Nov 15, 2024", "Dec 25, 2024"]

export {
  FormValid,
  ButtonConst,
  RemoveConst,
  panConst,
  ifscConst,
  appConst,
  pwdMatch,
  Holidays
};
