import React, { Component } from "react";
import { Select } from "antd";

import { SelectContainer } from "./style";
const { Option } = Select;

class FormSelect extends Component {
  render() {
    const { onChange, selectClass, defaultValue, className, data } = this.props;
    return (
      <SelectContainer id={"form-select"} className={className}>
        <Select
          mode="multiple"
          size={"large"}
          optionFilterProp="children"
          style={{ width: "100%" }}
          maxTagCount={"responsive"}
          placement="bottomLeft"
          popupClassName="dropdown-custom"
          allowClear
          showSearch
          defaultActiveFirstOption
          showArrow={false}
          defaultValue={defaultValue}
          onChange={onChange}
          className={`multys ${selectClass ? selectClass : ""}`}
          filterSort={(a, b) => a?.value?.localeCompare(b.value)}
          getPopupContainer={() => document.getElementById("form-select")}
        >
          {data?.map((a) => a?.trim() !== "" && <Option key={a}>{a}</Option>)}
        </Select>
      </SelectContainer>
    );
  }
}
FormSelect.defaultProps = {
  placeholder: null,
  className: "",
  disabled: false,
};
export default FormSelect;
