import React, { Component } from "react";
import { Chart } from "react-google-charts";
import { Calendar, Spin } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { StyleContainer } from "./style.js";
import { getAuthRole } from "modules/helper";
import { getUserList } from "redux/user/actions";
import { DashboardConst, MonthConst } from "./constant";
import { Row, Col, Table, LayOut, Modal, Select } from "components/Form";
import { getTradingList } from "redux/trading/actions";
import { getUpsiInfoList } from "redux/upsiInfo/actions";
import { getMenyClosureById } from "redux/closure/actions";
import {
  getDashboard,
  getCal,
  getMUList,
  getMPList,
  getYUList,
  getYPList,
} from "redux/dashboard/actions"; // getGraph,getpreGraph,getgiGraph,

var userRole = getAuthRole();
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      co: 0,
      gname: "Monthly",
      ghrap1: [],
      ulist: [],
      clist: [],
      mData: [],
      grpData: [],
      userlist: [],
      ygrpData: [],
      gtype: "Monthly",
      glist: ["Monthly", "Yearly"],
      ytype: "",
      ylist: [],
      init: {
        UPSIRequest: 0,
        TradingRequest: 0,
        DesignatedPerson: 0,
        ConnectedPerson: 0,
      },
    };
  }
  async componentDidMount() {
    try {
      userRole = userRole ? userRole : getAuthRole();
      // if (userRole === 67) this.props.history.push("/connected-person");
      // await this.props.getgiGraph();
      // await this.props.getpreGraph();
      await this.props.getUserList();
      await this.props.getCal();
      await this.props.getDashboard();
      await this.props.getUpsiInfoList();
      await this.props.getTradingList();
      this.setYears();
    } catch (error) {
      console.log(error);
    }
  }
  componentDidUpdate(prevProps) {
    try {
      const {
        dashData,
        graphData,
        calData,
        tradingList,
        upsiInfoList,
        closureData,
        closureMeny,
        userList,
      } = this.props;
      if (prevProps.dashData !== dashData) {
        dashData.length > 0 && this.setState({ init: dashData[0] });
      }
      if (upsiInfoList !== prevProps.upsiInfoList) {
        const { co, userlist } = this.state;
        let ulist = upsiInfoList?.filter((a) => a.is_deleted === 0);
        let srtData = ulist.sort(
          (a, b) => new Date(b?.created_date) - new Date(a?.created_date)
        );
        srtData?.forEach((b) => {
          let us = userlist?.find((f) => f.id === b.dpId);
          if (us) b.dpName = us.value;
          if (new Date(b?.created_date) < new Date("2022/05/19")) {
            if (b.dpId === co && b?.dpName.includes("Sahul"))
              b.dpName = "Niharika Shah (CO)";
            if (b?.receiverId.includes(co)) {
              let recv = b.receiver.replace(
                "CS Sahul Jotaniya (CO)",
                "Niharika Shah (CO)"
              );
              b.receiver = recv;
            }
          }
        });
        this.setState({ ulist: srtData });
      }
      if (prevProps.tradingList !== tradingList) {
        let ml = tradingList?.filter(
          (a) => a.is_deleted === 0 && a.type === "Pending"
        );
        this.setState({ clist: ml });
      }
      if (graphData !== prevProps.graphData) {
        const data = [["Month", "UPSI", "Trading"]];
        graphData.forEach((a) =>
          data.push([a.month, a.ConnectedPerson, a.designatedPerson])
        );
        this.setState({ ghrap1: data });
      }
      if (calData !== prevProps.calData) this.setCal(calData);
      if (closureData !== prevProps.closureData) {
        const { userlist } = this.state;
        let cd = closureData?.filter((a) => a.is_deleted === 0);
        cd?.forEach((f) => {
          let usr = userlist?.find((x) => x.id === f.uid);
          f.unmae = usr?.value;
        });
        this.setState({ mData: cd, visible: true });
      }
      if (closureMeny !== prevProps.closureMeny) {
        const { userlist } = this.state;
        let cm = closureMeny?.filter((a) => a.is_deleted === 0);
        cm?.forEach((f) => {
          let usr = userlist?.find((x) => x.id === f.uid);
          f.unmae = usr?.value;
        });
        this.setState({ mData: cm, visible: true });
      }
      if (userList !== prevProps.userList) {
        let urlist = userList?.filter((a) => a.is_deleted === 0);
        let data = [];
        urlist?.forEach((a) => {
          a.value = a.surname ? a.name + " " + a.surname : a.name;
          if (a.uid === 66 && a.id === 59) this.setState({ co: a.id });
          let pos =
            a.uid === 66
              ? " (CO"
              : a.uid === 67
              ? " (DP"
              : a.uid === 68
              ? " (PCS"
              : " (CP";
          if (data.find((b) => b.value === a.value + pos + ")")) {
            if (data.find((b) => b.value === a.value + pos + ") 2")) {
              if (data.find((b) => b.value === a.value + pos + ") 3"))
                data.push({ id: a.id, value: a.value + pos + ") 4" });
              else data.push({ id: a.id, value: a.value + pos + ") 3" });
            } else data.push({ id: a.id, value: a.value + pos + ") 2" });
          } else data.push({ id: a.id, value: a.value + pos + ")" });
        });
        this.setState({ ulist: userList, userlist: data });
      }
    } catch (error) {
      console.log(error);
    }
  }
  callcal = async (a) => setTimeout(() => this.setCal(a), 500);
  dateChange = (date) => {
    try {
      let day = new Date(date).getDate();
      day = day?.toString()?.length === 1 ? "0" + day : day;
      let month = new Date(date).getMonth() + 1;
      month = month?.toString()?.length === 1 ? "0" + month : month;
      let year = new Date(date).getFullYear();
      return date ? year + "-" + month + "-" + day : "";
    } catch (error) {
      console.log(error);
    }
  };
  setYears = async () => {
    try {
      let year = new Date().getFullYear();
      // let ydata = [];
      // for (let i = 2019; i < year + 2; i++) {ydata.push(i.toString());}
      // this.setState({ ytype: year.toString(), ylist: ydata });
      await this.callGraph(year);
      await this.props.getYUList();
      await this.props.getYPList();
      this.grapCalYear();
    } catch (error) {
      console.log(error);
    }
  };
  callGraph = async (y) => {
    await this.props.getMUList(y);
    await this.props.getMPList(y);
    this.grapCal();
  };
  setCal = (data) => {
    try {
      let sld = document.getElementsByClassName("selected"); // let date = [];
      for (let i = sld?.length - 1; i > -1; i--) {
        sld[i]?.classList.replace("selected", "ui");
      }
      data?.forEach((a) => {
        if (a.from) {
          let title = this.dateChange(a.from); // let title = a.from?.split("T")[0];
          let ele = document.querySelector(`[title="${title}"]`);
          if (ele?.className?.search(" selected") === -1) {
            ele.className = ele?.className + " selected";
            ele.addEventListener("click", this.onSelect);
            ele.id = a.id;
          } // date.push(title);let sld = document.querySelector(".selected"); // if (sld && !date?.includes(sld.title))sld.className = sld.className.replace(" selected", "");
        }
      }); // let sld2 = document.getElementsByClassName("selected");// for (let i = 0; i < sld2.length; i++) {if (!date?.includes(sld2[i].title))sld2[i].className = sld2[i].className.replace(" selected", "");}
    } catch (error) {
      console.log(error);
    }
  };
  selectUI = (val, name, data) => {
    try {
      return (
        <Select
          name={name}
          data={data}
          value={val}
          showSearch={"No"}
          defaultValue={val}
          onChange={async (value) => {
            if (name === "gtype") this.setState({ gname: value });
            else {
              await this.callGraph(value);
              this.setState({ ytype: value });
            }
          }}
        />
      );
    } catch (error) {
      console.log(error);
    }
  };
  grapCalOld = () => {
    try {
      const { uiData, preData } = this.props;
      const data = [];
      let yList = [],
        inx = 0;
      MonthConst?.forEach((a) => {
        let b = uiData?.find((c) => a === c.month);
        let d = preData?.find((e) => a === e.month);
        if (b || d) {
          yList.push({
            year: b?.year ? b.year : d?.year ? d.year : 0,
            idx: inx,
          });
          data.push([a, b ? b.dp : 0, d ? d.cp : 0]);
          inx = inx + 1;
        }
      });
      if (data.length > 1) {
        let srt = yList.sort((a, b) => a.year - b.year);
        let cData = [["Month", "UPSI", "Trading"]];
        srt?.forEach((v) => cData.push(data[v.idx]));
        this.setState({ grpData: cData });
      }
    } catch (error) {
      console.log(error);
    }
  };
  grapCalYear = () => {
    try {
      const { yuList, ypList } = this.props;
      let ydata = yuList.concat(ypList);
      let ylData = [];
      let srt = ydata.sort((a, b) => a.year - b.year);
      srt.forEach((x) => {
        let idx = ylData.findIndex((s) => s.year === x.year);
        if (idx > -1) {
          if (x.dp) ylData[idx].dp = x.dp;
          else if (x.cp) ylData[idx].cp = x.cp;
        } else ylData.push(x);
      });
      let yadata = [];
      if (ylData.length > 0) {
        let cData = [["Year", "UPSI", "Trading"]];
        ylData.forEach((v) => {
          cData.push([v.year.toString(), v.dp ? v.dp : 0, v.cp ? v.cp : 0]);
          yadata.push(v.year.toString());
        });
        this.setState({ ygrpData: cData, ylist: yadata });
      }
    } catch (error) {
      console.log(error);
    }
  };
  grapCal = () => {
    try {
      const { muList, mpList } = this.props;
      const data = [];
      let yList = [],
        inx = 0;
      MonthConst?.forEach((a) => {
        let b = muList?.find((c) => a === c.month);
        let d = mpList?.find((e) => a === e.month);
        if (b || d) {
          yList.push({
            year: b?.year ? b.year : d?.year ? d.year : 0,
            idx: inx,
          });
          data.push([a, b ? b.dp : 0, d ? d.cp : 0]);
          inx = inx + 1;
        }
      }); // if (data.length > 0) {}
      let srt = yList?.sort((a, b) => a.year - b.year);
      let cData = [["Month", "UPSI", "Trading"]];
      srt?.forEach((v) => cData?.push(data[v.idx]));
      this.setState({
        grpData: cData,
        ytype: new Date().getFullYear().toString(),
      });
    } catch (error) {
      console.log(error);
    }
  };
  grapUI = () => {
    try {
      const { gtype, glist, ytype, ylist, grpData, gname, ygrpData } =
        this.state;
      let chdata = gname === "Monthly" ? grpData : ygrpData;
      return (
        <div className="pDiv mh">
          <div className="gDiv">
            <h3>{DashboardConst.head1}</h3>
            <div className="ml-auto flex slDiv">
              {this.selectUI(gtype, "gtype", glist)}
              {ytype !== "" &&
                gname === "Monthly" &&
                this.selectUI(ytype, "ytype", ylist)}
            </div>
          </div>
          <hr />
          {chdata.length > 1 && (
            <Chart chartType="Bar" width="100%" height="300px" data={chdata} />
          )}
        </div>
      );
    } catch (error) {
      console.log(error);
    }
  };
  onSelect = async (e) => {
    try {
      const { calData } = this.props;
      if (e?.currentTarget?.title) {
        let a = new Date(e.currentTarget.title);
        let date = a?.toJSON().split("T")[0];
        let cDate = calData?.filter((c) => this.dateChange(c.from) === date);
        if (cDate?.length === 1 && cDate[0]?.id)
          await this.props.getMenyClosureById({ ids: cDate[0].id });
        else if (cDate?.length > 1) {
          let ids = [];
          cDate.forEach((b) => ids.push(b.id));
          await this.props.getMenyClosureById({ ids: ids });
        }
        this.setCal(calData);
      }
    } catch (error) {
      console.log(error);
    }
  };
  toggleModal = () => this.setState({ visible: !this.state.visible });
  render() {
    const { loading, loading1, calData } = this.props;
    const { init, ulist, clist, visible, mData } = this.state;
    return (
      <Spin size="large" spinning={loading || loading1}>
        <LayOut>
          <StyleContainer className="main anime">
            <Row gutter={24}>
              <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                <div className="pDiv">
                  <div className="rdiv">
                    <div className="round r4">
                      <h2 className="counter">{init.UPSIRequest}</h2>
                    </div>
                    <span className="desc">{DashboardConst.desc1}</span>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                <div className="pDiv">
                  <div className="rdiv">
                    <div className="round r1">
                      <h2 className="counter">{init.TradingRequest}</h2>
                    </div>
                    <span className="desc">{DashboardConst.desc2}</span>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                <div className="pDiv">
                  <div className="rdiv">
                    <div className="round r3">
                      <h2 className="counter">{init.DesignatedPerson}</h2>
                    </div>
                    <span className="desc">{DashboardConst.desc3}</span>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                <div className="pDiv">
                  <div className="rdiv">
                    <div className="round r2">
                      <h2 className="counter">{init.ConnectedPerson}</h2>
                    </div>
                    <span className="desc">{DashboardConst.desc4}</span>
                  </div>
                </div>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                {this.grapUI()}
              </Col>
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                <div className="pDiv mh">
                  <h3>{DashboardConst.head2}</h3>
                  <Calendar
                    fullscreen={false} // onSelect={(e) => this.onSelect(new Date(e), calData)}
                    onPanelChange={() => this.callcal(calData)}
                  />
                </div>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <div className="pDiv">
                  <div>
                    <h3>{DashboardConst.head3}</h3>
                    <Table data={ulist} type="upsiInfo" noAction psize />
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <div className="pDiv">
                  <h3>{DashboardConst.head4}</h3>
                  <Table noAction data={clist} type="trading" psize />
                </div>
              </Col>
            </Row>
          </StyleContainer>
        </LayOut>
        {visible && <Modal data={mData} toggleModal={this.toggleModal} />}
      </Spin>
    );
  }
}
const mapStateToProps = (state) => ({
  loading: state.dashboard.loading,
  loading1: state.closure.loading,
  dashData: state.dashboard.dashData,
  graphData: state.dashboard.graphData,
  calData: state.dashboard.calData,
  uiData: state.dashboard.uiData,
  preData: state.dashboard.preData,
  tradingList: state.trading.tradingList,
  upsiInfoList: state.upsiInfo.upsiInfoList,
  closureData: state.closure.closureData,
  closureMeny: state.closure.closureMeny,
  userList: state.user.userList,
  muList: state.dashboard.muList,
  yuList: state.dashboard.yuList,
  mpList: state.dashboard.mpList,
  ypList: state.dashboard.ypList,
});
const mapDispatchToProps = (dispatch) => ({
  getDashboard: (payload) => dispatch(getDashboard(payload)),
  getUserList: (payload) => dispatch(getUserList(payload)),
  getCal: (payload) => dispatch(getCal(payload)),
  getUpsiInfoList: (payload) => dispatch(getUpsiInfoList(payload)),
  getTradingList: (payload) => dispatch(getTradingList(payload)),
  getMenyClosureById: (payload) => dispatch(getMenyClosureById(payload)),
  getMUList: (payload) => dispatch(getMUList(payload)),
  getMPList: (payload) => dispatch(getMPList(payload)),
  getYUList: (payload) => dispatch(getYUList(payload)),
  getYPList: (payload) => dispatch(getYPList(payload)),
  // getGraph: (payload) => dispatch(getGraph(payload)),
  // getpreGraph: (payload) => dispatch(getpreGraph(payload)),
  // getgiGraph: (payload) => dispatch(getgiGraph(payload)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
