import React, { Component } from "react";
import { Spin } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { PageConst } from "container/AddUser/constant";
import { getUserByDp } from "redux/user/actions";
import { ButtonConst } from "App/AppConstant";
import { Label, Button, Row, Col, LayOut } from "components/Form";
import { getAuthUserID, getAuthRole } from "modules/helper";

var userId = getAuthUserID();
var userRole = getAuthRole();

class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = { user: {} };
  }
  async componentDidMount() {
    try {
      const { match } = this.props;
      localStorage.url = match.url;
      userId = userId ? userId : getAuthUserID();
      userRole = userRole ? userRole : getAuthRole();
      await this.props.getUserByDp(1);
    } catch (error) {
      console.log(error);
    }
  }
  componentDidUpdate(prevProps) {
    try {
      const { userData } = this.props;
      if (userData !== prevProps.userData) {
        let list = userData?.filter((a) => a.is_deleted === 0 && a.uid === 66);
        this.setState({ user: list[0] });
      }
    } catch (error) {
      console.log(error);
    }
  }
  dateChange = (date) => {
    let day = new Date(date).getDate();
    day = day?.toString()?.length === 1 ? "0" + day : day;
    let month = new Date(date).getMonth() + 1;
    month = month?.toString()?.length === 1 ? "0" + month : month;
    let year = new Date(date).getFullYear();
    return date ? day + "-" + month + "-" + year : "";
  };
  lblUI = (txt, txt2, a) => {
    try {
      if (txt?.charAt(txt.length - 1) === "*")
        txt = txt.substring(0, txt.length - 1);
      if (txt2)
        return (
          <Col xs={24} sm={24} md={24} lg={12} xl={a ? a : 8}>
            <Label title={txt + " : "} />
            <h3>{txt2}</h3>
          </Col>
        );
    } catch (error) {
      console.log(error);
    }
  };
  btnUI = (link, id, txt) => (
    <Button className="mt-1" onClick={() => this.back(link, id)}>
      {txt}
    </Button>
  );
  redirect = (link, id) =>
    this.props.history.push(link + "-compliance-officer/" + id);
  back = (link, id) => this.props.history.push(link + window.btoa(id));
  render() {
    const { user } = this.state;
    const { loading } = this.props;
    return (
      <Spin size="large" spinning={loading}>
        <LayOut link={"add-user"}>
          <div className="main anime">
            <div className="head">
              <h4 className="title">{PageConst.co}</h4>
              <div className="btnDiv">
                {userRole !== 68 && !user?.id && (
                  <Button onClick={() => this.redirect("/add", "")}>
                    {ButtonConst.add}
                  </Button>
                )}
              </div>
            </div>
            {user?.id && (
              <>
                <div className="display">
                  <h3 className="toptl">{PageConst.bsd}</h3>
                  <Row gutter={40}>
                    {this.lblUI(PageConst.name, user.name)}
                    {this.lblUI(PageConst.fatherName, user.fatherName)}
                    {this.lblUI(PageConst.surname, user.surname)}
                    {this.lblUI(PageConst.email, user.email)}
                    {this.lblUI(PageConst.mobile, user.mobile)}
                    {this.lblUI(PageConst.gender, user.gender)}
                  </Row>
                </div>
                <div className="display">
                  <h3 className="toptl">{PageConst.ld}</h3>
                  <Row gutter={40}>
                    {this.lblUI(PageConst.address, user.address)}
                    {this.lblUI(PageConst.city, user.city)}
                    {this.lblUI(PageConst.state, user.state)}
                    {this.lblUI(PageConst.pin, user.pincode)}
                  </Row>
                </div>
                <div className="display">
                  <h3 className="toptl">{PageConst.pd}</h3>
                  <Row gutter={40}>
                    {this.lblUI(PageConst.nation, user.nationality)}
                    {this.lblUI(PageConst.passport, user.passport)}
                    {this.lblUI(PageConst.pan, user.pan)}
                    {this.lblUI(PageConst.din, user.din)}
                    {this.lblUI(PageConst.aadhar, user.aadhar)}
                    {this.lblUI(PageConst.client, user.clientID)}
                    {this.lblUI(
                      PageConst.appiotment,
                      this.dateChange(user.dateOfAppointment)
                    )}
                    {this.lblUI(PageConst.category, user.category)}
                    {this.lblUI(PageConst.un, user.userName)}
                  </Row>
                </div>
                <div className="btnDiv anime mt-1">
                  <div className="btn">
                    {this.btnUI("/bank/", user.id, PageConst.vb)}
                    {this.btnUI("/education/", user.id, PageConst.ve)}
                    {this.btnUI("/employment/", user.id, PageConst.vme)}
                    {this.btnUI("/directorship/", user.id, PageConst.vd)}
                    {/* {this.btnUI("/holding/", user.id, PageConst.vh)} */}
                    {userRole === 66 && (
                      <Button
                        className="mt-1"
                        onClick={() =>
                          this.redirect("/edit", window.btoa(user.id))
                        }
                      >
                        {ButtonConst.edit}
                      </Button>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </LayOut>
      </Spin>
    );
  }
}
const mapStateToProps = (state) => ({
  loading: state.user.loading,
  userData: state.user.userData,
});
const mapDispatchToProps = (dispatch) => ({
  getUserByDp: (payload) => dispatch(getUserByDp(payload)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddUser)
);
