import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import app from "./app/reducer";
import login from "./login/reducer";
import master from "./master/reducer";
import company from "./company/reducer";
import trading from "./trading/reducer";
import closure from "./closure/reducer";
import user from "./user/reducer";
import upsiInfo from "./upsiInfo/reducer";
import declaration from "./declaration/reducer";
import benpo from "./benpo/reducer";
import benpolist from "./benpolist/reducer";
import education from "./education/reducer";
import employment from "./employment/reducer";
import disclosure from "./disclosure/reducer";
import director from "./director/reducer";
import holding from "./holding/reducer";
import connref from "./connRef/reducer";
import dashboard from "./dashboard/reducer";
import template from "./templates/reducer";
import smtp from "./smtp/reducer";
import bank from "./bank/reducer";
import auditLog from "./auditLog/reducer";
import subscription from "./subscription/reducer";

const rootReducer = (history) =>
  combineReducers({
    app,
    auditLog,
    bank,
    benpo,
    benpolist,
    connref,
    education,
    dashboard,
    employment,
    disclosure,
    director,
    holding,
    login,
    master,
    company,
    trading,
    closure,
    declaration,
    user,
    upsiInfo,
    template,
    smtp,
    subscription,
    router: connectRouter(history),
  });
export default rootReducer;
