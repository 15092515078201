import styled from "styled-components";

const TabsStyle = styled.div`
  margin-top: 1em;
  .ant-tabs {
    .ant-tabs-nav {
      :before {
        border-bottom: none !important;
      }
      .ant-tabs-nav-wrap .ant-tabs-nav-list {
        .ant-tabs-tab {
          border: 2px solid #e4e4e4;
          border-radius: 5px;
          :hover {
            border: 2px solid #1d2d50;
            background: #1d2d50;
            .ant-tabs-tab-btn {
              color: #fff;
            }
          }
        }
        .ant-tabs-tab + .ant-tabs-tab {
          margin-left: 0.7em;
        }
        .ant-tabs-tab-active {
          border: 2px solid #1d2d50;
          background: #1d2d50;
          .ant-tabs-tab-btn {
            color: #fff;
          }
        }
      }
    }
  }
`;

export { TabsStyle };
