export const GET_MASTER_LIST_INITIATED = "GET_MASTER_LIST_INITIATED";
export const GET_MASTER_LIST_SUCCESS = "GET_MASTER_LIST_SUCCESS";
export const GET_MASTER_LIST_ERROR = "GET_MASTER_LIST_ERROR";

export const GET_MASTER_BY_ID_INITIATED = "GET_MASTER_BY_ID_INITIATED";
export const GET_MASTER_BY_ID_SUCCESS = "GET_MASTER_BY_ID_SUCCESS";
export const GET_MASTER_BY_ID_ERROR = "GET_MASTER_BY_ID_ERROR";

export const ADD_MASTER_INITIATED = "ADD_MASTER_INITIATED";
export const ADD_MASTER_SUCCESS = "ADD_MASTER_SUCCESS";
export const ADD_MASTER_ERROR = "ADD_MASTER_ERROR";

export const UPDATE_MASTER_INITIATED = "UPDATE_MASTER_INITIATED";
export const UPDATE_MASTER_SUCCESS = "UPDATE_MASTER_SUCCESS";
export const UPDATE_MASTER_ERROR = "UPDATE_MASTER_ERROR";

export const DELETE_MASTER_INITIATED = "DELETE_MASTER_INITIATED";
export const DELETE_MASTER_SUCCESS = "DELETE_MASTER_SUCCESS";
export const DELETE_MASTER_ERROR = "DELETE_MASTER_ERROR";

export const GET_MASTER_TYPE_INITIATED = "GET_MASTER_TYPE_INITIATED";
export const GET_MASTER_TYPE_SUCCESS = "GET_MASTER_TYPE_SUCCESS";
export const GET_MASTER_TYPE_ERROR = "GET_MASTER_TYPE_ERROR";