const LoginConst = {
  user: "UserName",
  password: "Password",
  login: "Login",
  remember: "Remember me",
  fp: "Forgot Password?",
  eyei: "Enter your email id",
  sub: "Subscription",
  name: "Company Name*",
  sdate: "Start Date*",
  edate: "End Date*",
  mDate: "Proposed ending date is greater than starting date",
  submsg: "Your subscription package is expired.",
  fmsg: "Your subscription package will expire on ",
  tmsg: "Your subscription package expire today",
};
export { LoginConst };
