const PageConst = {
  dl: "Templates",
  uf: "Use For*",
  tmp: "Template*",
};
let TempConst = [
  { id: 1, value: "Pre Clearance with Undertaking" },
  { id: 2, value: "Pre Clearance Order" },
  { id: 3, value: "Post Transaction Reporting less than 10 lacs" },
  { id: 4, value: "Post Transaction Reporting above 10 lacs for DP" },
  {
    id: 5,
    value: "Post Transaction Reporting above 10 lacs for connected Person",
  },
  { id: 6, value: "Closure of Trading Window for All" },
  { id: 7, value: "Closure of Trading Window for UPSI Holder" },
  { id: 10, value: "Trading Window Open" },
  { id: 11, value: "UPSI Share" },
];
export { PageConst, TempConst };
