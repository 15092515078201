import { message } from "antd";
import { push } from "connected-react-router";
import { axiosAuthGet, axiosAuthPost } from "modules/Axios";
import { benpoConst, benpoListConst,AuditConst } from "modules/config";
import { addBenPoList, updateBenPoList } from "redux/benpolist/actions";
import * as actions from "./constant";

export const getBenPoList = () => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_BENPO_INITIATED });
    let response = await axiosAuthGet(benpoConst.GET_BENPO);
    if (response?.success) await dispatch({ type: actions.GET_BENPO_SUCCESS, payload: response });
    else dispatch({ type: actions.GET_BENPO_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.GET_BENPO_ERROR, error: "Network Error" });
  }
};

export const getBenPoById = (id) => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_BENPO_BY_ID_INITIATED });
    let response = await axiosAuthGet(benpoConst.GET_BENPO_BY_ID + id);
    if (response?.success) await dispatch({ type: actions.GET_BENPO_BY_ID_SUCCESS, payload: response });
    else dispatch({ type: actions.GET_BENPO_BY_ID_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.GET_BENPO_BY_ID_ERROR, error: "Network Error" });
  }
};

export const getBenPoByUId = (id) => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_BENPO_BY_UID_INITIATED });
    let response = await axiosAuthGet(benpoConst.GET_BENPO_BY_UID + id);
    if (response?.success) await dispatch({ type: actions.GET_BENPO_BY_UID_SUCCESS, payload: response });
    else dispatch({ type: actions.GET_BENPO_BY_UID_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.GET_BENPO_BY_UID_ERROR, error: "Network Error" });
  }
};

export const addBenPos = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.ADD_BENPO_INITIATED });
    let response = await axiosAuthPost(benpoListConst.ADD_BENPO_LIST, payload.new);
    if (response?.success) {
      let response1 = await axiosAuthPost(benpoConst.ADD_BENPO, payload.benpo);
      await axiosAuthPost(AuditConst.ADD_AUDIT,{uid:payload.benpo.uid,text:"BenPos added"});
      // await axiosAuthPost(AuditConst.ADD_AUDIT,{uid:payload.uid,text:"BenPoList added"});
      if (response1?.success) {
        await dispatch({ type: actions.ADD_BENPO_SUCCESS, payload: response });
        dispatch(push("/benPOS"));
      } else dispatch({ type: actions.ADD_BENPO_ERROR, error: response?.message });
    } else dispatch({ type: actions.ADD_BENPO_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.ADD_BENPO_ERROR, error: "Network Error" });
  }
};

export const addBenPosdif = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.ADD_BENPO_INITIATED });
    let response = await axiosAuthPost(benpoListConst.ADD_BENPODIF_LIST, payload.new);
    if (response?.success) {
      let response1 = await axiosAuthPost(benpoConst.ADD_BENPO, payload.benpo);
      if (response1?.success) {
        await dispatch({ type: actions.ADD_BENPO_SUCCESS, payload: response });
        await axiosAuthPost(AuditConst.ADD_AUDIT,{uid:payload.uid,text:"BenPos added"});
        // await axiosAuthPost(AuditConst.ADD_AUDIT,{uid:payload.uid,text:"BenPoList added"}); 
        dispatch(push("/benPOS"));
      } else dispatch({ type: actions.ADD_BENPO_ERROR, error: response?.message });
    } else dispatch({ type: actions.ADD_BENPO_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.ADD_BENPO_ERROR, error: "Network Error" });
  }
};

export const updateBenPo = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.UPDATE_BENPO_INITIATED });
    let response = await axiosAuthPost(benpoConst.UPDATE_BENPO, payload.benpo);
    if (response?.success) {
      payload.new?.length > 0 && dispatch(addBenPoList(payload.new));
      payload.old?.length > 0 && dispatch(updateBenPoList(payload.old));
      await axiosAuthPost(AuditConst.ADD_AUDIT,{uid:payload.uid,toid:payload.id,text:"BenPos updated"});
      await dispatch({ type: actions.UPDATE_BENPO_SUCCESS, payload: response });
      dispatch(push("/benPOS"));
    }
    else dispatch({ type: actions.UPDATE_BENPO_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.UPDATE_BENPO_ERROR, error: "Network Error" });
  }
};

export const deleteBenPo = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.DELETE_BENPO_INITIATED });
    let response = await axiosAuthGet(benpoConst.DELETE_BENPO + payload.id);
    if (response?.success) {
      await axiosAuthPost(AuditConst.ADD_AUDIT,{uid:payload.uid,toid:payload.id,text:"BenPos updated"});
      await dispatch({ type: actions.DELETE_BENPO_SUCCESS, payload: response });
      message.success(response?.message)
    }
    else dispatch({ type: actions.DELETE_BENPO_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.DELETE_BENPO_ERROR, error: "Network Error" });
  }
};
