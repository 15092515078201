import styled from "styled-components";
import { size } from "App/device";

const StyleContainer = styled.div`
  .mh {
    min-height: 27em;
  }
  .rdiv {
    display: flex;
    align-items: center;
    justify-content: space-around;
    .round {
      width: 80px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      @media screen and (max-width: 1280px) {
        width: 65px;
        height: 65px;
      }
      .counter {
        margin: 0;
      }
      &.r1 {
        border: 10px solid #ff6b6b;
      }
      &.r2 {
        border: 10px solid #ffd93d;
      }
      &.r3 {
        border: 10px solid #6bcb77;
      }
      &.r4 {
        border: 10px solid #4d96ff;
      }
    }
    .desc {
      font-size: 14px;
      line-height: 22px;
      color: #1d2d50;
      max-width: 135px;
      font-weight: 700;
      @media screen and (max-width: 1280px) {
        max-width: 100px;
        margin-left: 5px;
      }
    }
  }
  .ant-picker-calendar-header {
    justify-content: center;
    // .ant-picker-calendar-year-select,
    .ant-radio-button-wrapper {
      display: none;
    }
    .ant-picker-calendar-month-select {
      margin-left: auto;
    }
  }
  .selected .ant-picker-cell-inner {
    background: rgb(9, 68, 125);
    color: rgb(255, 255, 255);
    :hover {
      color: rgb(9, 68, 125);
    }
  }
  .gDiv {
    display:flex;
    @media ${size["mobile-md-max"]} {
      display:block;
    }
    .slDiv .ant-select {
      width: 6em;
      margin-left: 5px;
    }
  }
`;
export { StyleContainer };
