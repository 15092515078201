import React, { Component } from "react";
import * as Yup from "yup";
import { message, Spin } from "antd";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { PageConst } from "container/AddUser/constant";
import { ButtonConst, FormValid } from "App/AppConstant";
import {
  addEmployment,
  updateEmployment,
  getEmploymentById,
  deleteEmployment,
} from "redux/employment/actions";
import {
  Label,
  Input,
  Button,
  Row,
  Col,
  Collapse,
  LayOut, DatePicker
} from "components/Form";
const ValidationSchema = Yup.object().shape({
  companyName: Yup.string().trim().nullable().required(" ").min(1).max(50).matches(/^[aA-zZ09\s~!@#$%^&*()_+-/.]+$/, FormValid.charValid),
  // exitDate: Yup.string().trim().nullable().required(" ").min(2).max(55).matches(/^[aA-zZ0-9-,.\s]+$/, FormValid.charValid),
  // joiningDate: Yup.string().trim().nullable().required(" ").min(2).max(55).matches(/^[aA-zZ0-9-,.\s]+$/, FormValid.charValid),
  designation: Yup.string().trim().nullable().min(2).max(55).matches(/^[aA-zZ\s.,-]+$/, FormValid.charValid),
  joiningDate: Yup.date().nullable().required(" "),
  exitDate: Yup.date().nullable().required(" ").when("joiningDate", (joiningDate, schema) => joiningDate && schema.min(joiningDate, FormValid.exitValid)),
});
class EmploymentDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 1,
      id: "",
      disable: false,
      show: true,
      deleteEdu: [],
      init: {
        id: 0,
        companyName: "",
        designation: "",
        joiningDate: "",
        exitDate: "",
      },
      employee: [
        {
          id: 0,
          companyName: "",
          designation: "",
          joiningDate: "",
          exitDate: "",
        },
      ],
      counter: 0,
    };
  }
  async componentDidMount() {
    try {
      const { match } = this.props;
      await this.props.getEmploymentById(window.atob(match?.params?.id));
      this.setState({ id: match?.params?.id });
    } catch (error) {
      console.log(error);
    }
  }
  componentDidUpdate(prevProps) {
    try {
      const { employmentData } = this.props;
      if (employmentData !== prevProps.employmentData) {
        let arr = [];
        employmentData?.forEach((a) => {
          let data = {
            id: a.id,
            uId: a.uId,
            companyName: a.companyName,
            designation: a.designation,
            joiningDate: a.joiningDate,
            exitDate: a.exitDate,
          };
          if (a.is_deleted === 0) arr.push(data);
        });
        if (arr.length > 0) this.setState({ employee: arr, counter: arr.length });
      }
    } catch (error) {
      console.log(error);
    }
  }
  btnUI = (i, values, handleReset) => {
    try {
      const { employee, counter, disable } = this.state;
      let length = employee.length;
      if (!employee[length - 1]) length = length - 1;
      return (
        <div className="btnDiv">
          {length - 1 === i ? (
            <>
              {values.companyName !== "" && values.exitDate !== "" && values.joiningDate !== "" && (
                <Button onClick={() => { this.addEdu(counter + 1, i, values); }}>
                  {ButtonConst.an}
                </Button>
              )}
              <Button htmlType="submit" disabled={disable} onClick={() => { this.save(i + 1, values); }}>
                {ButtonConst.save}
              </Button>
            </>
          ) : (
            <Button htmlType="submit" onClick={() => { this.save(i + 1, values); }}>
              {ButtonConst.save}
            </Button>
          )}
          {employee.length > 1 && (
            <Button onClick={() => { this.remove(i, handleReset); }}>
              {ButtonConst.remove}
            </Button>
          )}
        </div>
      );
    } catch (error) {
      console.log(error);
    }
  };
  addEdu = (key, i, val) => {
    try {
      const { employee, init } = this.state;
      if (key === i + 1) employee[key - 1] = val;
      else employee[i] = val;
      employee[key] = init;
      this.setState({ employee, counter: key });
    } catch (error) {
      console.log(error);
    }
  };
  save = (key, val) => {
    try {
      const { employee } = this.state;
      employee[key - 1] = val;
      this.setState({ employee });
    } catch (error) {
      console.log(error);
    }
  };
  remove = (key, handleReset) => {
    try {
      const { deleteEdu } = this.state;
      const { employee, counter } = this.state;
      employee[key].id !== 0 && deleteEdu.push(employee[key].id);
      employee.splice(key, 1);
      this.setState({ employee, counter: counter - 1, deleteEdu });
      handleReset();
    } catch (error) {
      console.log(error);
    }
  };
  handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { employee, id, deleteEdu } = this.state;
      this.setState({ disable: true });
      setTimeout(() => this.setState({ disable: false }), 5000);
      employee.forEach(async (a) => {
        if (a.companyName && a.joiningDate && a.exitDate) {
          let data = {
            uId: window.atob(id),
            companyName: a.companyName,
            designation: a.designation,
            joiningDate: a.joiningDate,
            exitDate: a.exitDate,
          };
          if (a.id > 0) {
            data.id = a.id;
            await this.props.updateEmployment(data);
          } else await this.props.addEmployment(data);
        }
      });
      deleteEdu?.forEach(async (a) => await this.props.deleteEmployment(a));
      message.success("Data saved");
      this.back(id);
      setSubmitting(false);
    } catch (error) {
      console.log(error);
    }
  };
  back = (id) => this.props.history.push("/employment/" + id);
  render() {
    const { employee } = this.state;
    const { loading } = this.props;
    return (
      <Spin size="large" spinning={loading}>
        <LayOut link={"add-user"}>
          <div className="main anime">
            <div className="head">
              <h4 className="title">{PageConst.emd}</h4>
              <div className="bottomDiv">
                <div className="btn">
                  <Button onClick={() => this.props.history.goBack()}>
                    {ButtonConst.back}
                  </Button>
                </div>
              </div>
            </div>
            {employee.map((data, i) => (
              <Collapse key={i}
                data={[
                  {
                    header: PageConst.em,
                    pData: (
                      <Formik
                        initialValues={data}
                        validationSchema={ValidationSchema}
                        onSubmit={this.handleSubmit}
                        enableReinitialize
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          handleReset,
                        }) => (
                          <Form
                            onSubmit={handleSubmit}
                            noValidate
                            key={"emp" + i}
                          >
                            <Row gutter={24}>
                              <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                                <Label
                                  title={PageConst.cn}
                                  className={
                                    errors.companyName &&
                                      touched.companyName
                                      ? "empty"
                                      : ""
                                  }
                                />
                                <Input
                                  onBlur={handleBlur}
                                  name="companyName"
                                  value={values.companyName}
                                  handleChange={handleChange}
                                  tabIndex="1"
                                  className={
                                    errors.companyName &&
                                      touched.companyName
                                      ? "empty"
                                      : ""
                                  }
                                />
                                {errors.companyName &&
                                  touched.companyName ? (
                                  <div className="form-error">
                                    {errors.companyName}
                                  </div>
                                ) : null}
                              </Col>
                              <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                                <Label
                                  title={PageConst.jd}
                                  className={
                                    errors.joiningDate &&
                                      touched.joiningDate
                                      ? "empty"
                                      : ""
                                  }
                                />
                                {/* <Input onBlur={handleBlur} name="joiningDate" value={values.joiningDate} handleChange={handleChange} tabIndex="2" className={   errors.joiningDate &&   touched.joiningDate     ? "empty"     : "" }/> */}
                                <DatePicker
                                  value={values.joiningDate}
                                  handleChange={handleChange("joiningDate")}
                                  className={errors.joiningDate && touched.joiningDate ? "empty" : ""}
                                  tabIndex="3"
                                />
                                {errors.joiningDate &&
                                  touched.joiningDate ? (
                                  <div className="form-error">
                                    {errors.joiningDate}
                                  </div>
                                ) : null}
                              </Col>
                              <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                                <Label
                                  title={PageConst.exd}
                                  className={
                                    errors.exitDate && touched.exitDate
                                      ? "empty"
                                      : ""
                                  }
                                />
                                <DatePicker
                                  value={values.exitDate}
                                  handleChange={handleChange("exitDate")}
                                  className={errors.exitDate && touched.exitDate ? "empty" : ""}
                                  tabIndex="4"
                                />
                                {/* <Input onBlur={handleBlur} name="exitDate" value={values.exitDate} handleChange={handleChange} tabIndex="2" className={errors.exitDate && touched.exitDate? "empty": ""}/> */}
                                {errors.exitDate && touched.exitDate ? (
                                  <div className="form-error">
                                    {errors.exitDate}
                                  </div>
                                ) : null}
                              </Col>
                              <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                                <Label
                                  title={PageConst.des}
                                  className={
                                    errors.designation &&
                                      touched.designation
                                      ? "empty"
                                      : ""
                                  }
                                />
                                <Input
                                  onBlur={handleBlur}
                                  name="designation"
                                  value={values.designation}
                                  handleChange={handleChange}
                                  tabIndex="2"
                                  className={
                                    errors.designation &&
                                      touched.designation
                                      ? "empty"
                                      : ""
                                  }
                                />
                                {errors.designation &&
                                  touched.designation ? (
                                  <div className="form-error">
                                    {errors.designation}
                                  </div>
                                ) : null}
                              </Col>
                            </Row>
                            {this.btnUI(i, values, handleReset)}
                          </Form>
                        )}
                      </Formik>
                    ),
                  },
                ]}
              />
            ))}
          </div>
        </LayOut>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.employment.loading,
  employmentData: state.employment.employmentData,
});
const mapDispatchToProps = (dispatch) => ({
  addEmployment: (payload) => dispatch(addEmployment(payload)),
  updateEmployment: (payload) => dispatch(updateEmployment(payload)),
  getEmploymentById: (payload) => dispatch(getEmploymentById(payload)),
  deleteEmployment: (payload) => dispatch(deleteEmployment(payload)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EmploymentDetail)
);
