import { message } from "antd";
import { push } from "connected-react-router";

import { axiosAuthGet, axiosAuthPost, fetchAuthPost } from "modules/Axios";
import { tradingConst,AuditConst } from "modules/config";
import * as actions from "./constant";

export const getTradingList = () => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_TRADING_LIST_INITIATED });
    let response = await axiosAuthGet(tradingConst.GET_TRADING_LIST);
    if (response?.success)
      await dispatch({type: actions.GET_TRADING_LIST_SUCCESS,payload: response});
    else dispatch({type: actions.GET_TRADING_LIST_ERROR,error: response?.message});
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.GET_TRADING_LIST_ERROR, error: "Network Error" });
  }
};

export const getTradingById = (id) => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_TRADING_BY_ID_INITIATED });
    let response = await axiosAuthGet(tradingConst.GET_TRADING_BY_ID + id);
    if (response?.success) await dispatch({type: actions.GET_TRADING_BY_ID_SUCCESS,payload: response});
    else dispatch({type: actions.GET_TRADING_BY_ID_ERROR,error: response?.message});
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.GET_TRADING_BY_ID_ERROR, error: "Network Error" });
  }
};

export const addTrading = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.ADD_TRADING_INITIATED });
    payload.data && await fetchAuthPost(payload.data);
    payload.data && delete payload.data;
    let response = await axiosAuthPost(tradingConst.ADD_TRADING, payload);
    if (response?.success){
      await axiosAuthPost(AuditConst.ADD_AUDIT,{uid:payload.uid,text:"Trading Clearance Request record Added"});      
      await dispatch({ type: actions.ADD_TRADING_SUCCESS, payload: response });
      dispatch(push("/trading"));}
    else
      dispatch({ type: actions.ADD_TRADING_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.ADD_TRADING_ERROR, error: "Network Error" });
  }
};

export const updateTrading = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.UPDATE_TRADING_INITIATED });
    payload.data && await fetchAuthPost(payload.data);
    payload.data && delete payload.data;
    await axiosAuthPost(AuditConst.ADD_AUDIT,{uid:payload.uid,toid:payload.id,text:"Trading Clearance Request record updated"});
    payload.uid && delete payload.uid;
    let response = await axiosAuthPost(tradingConst.UPDATE_TRADING + payload.id,payload);
    if (response?.success) {
      await dispatch({type: actions.UPDATE_TRADING_SUCCESS,payload: response});
      dispatch(push("/trading"));
    } else dispatch({type: actions.UPDATE_TRADING_ERROR,error: response?.message});
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.UPDATE_TRADING_ERROR, error: "Network Error" });
  }
};

export const deleteTrading = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.DELETE_TRADING_INITIATED });
    let response = await axiosAuthGet(tradingConst.DELETE_TRADING + payload.id);
    if (response?.success) {
      await axiosAuthPost(AuditConst.ADD_AUDIT,{uid:payload.uid,toid:payload.id,text:"Trading Clearance Request record deleted"});
      await dispatch({type: actions.DELETE_TRADING_SUCCESS,payload: response});
      message.success(response?.message);
    } else dispatch({type: actions.DELETE_TRADING_ERROR,error: response?.message});
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.DELETE_TRADING_ERROR, error: "Network Error" });
  }
};
