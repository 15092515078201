export const GET_TEMPLATE_LIST_INITIATED = "GET_TEMPLATE_LIST_INITIATED";
export const GET_TEMPLATE_LIST_SUCCESS = "GET_TEMPLATE_LIST_SUCCESS";
export const GET_TEMPLATE_LIST_ERROR = "GET_TEMPLATE_LIST_ERROR";

export const GET_TEMPLATE_BY_ID_INITIATED = "GET_TEMPLATE_BY_ID_INITIATED";
export const GET_TEMPLATE_BY_ID_SUCCESS = "GET_TEMPLATE_BY_ID_SUCCESS";
export const GET_TEMPLATE_BY_ID_ERROR = "GET_TEMPLATE_BY_ID_ERROR";

export const ADD_TEMPLATE_INITIATED = "ADD_TEMPLATE_INITIATED";
export const ADD_TEMPLATE_SUCCESS = "ADD_TEMPLATE_SUCCESS";
export const ADD_TEMPLATE_ERROR = "ADD_TEMPLATE_ERROR";

export const UPDATE_TEMPLATE_INITIATED = "UPDATE_TEMPLATE_INITIATED";
export const UPDATE_TEMPLATE_SUCCESS = "UPDATE_TEMPLATE_SUCCESS";
export const UPDATE_TEMPLATE_ERROR = "UPDATE_TEMPLATE_ERROR";

export const DELETE_TEMPLATE_INITIATED = "DELETE_TEMPLATE_INITIATED";
export const DELETE_TEMPLATE_SUCCESS = "DELETE_TEMPLATE_SUCCESS";
export const DELETE_TEMPLATE_ERROR = "DELETE_TEMPLATE_ERROR";