const PageConst = {
  title: "Add Company",
  title2: "Edit Company",
  cnm: "Company Name*",
  cin: "CIN No.*",
  pan: "PAN No.*",
  isin: "ISIN*",
  gst: "GST No.",
  di: "Date of Incorporation",
  add: "Address Registered Office*",
  city: "City*",
  state: "State*",
  pin: "PIN*",
  up: "Upload ",
  symbol: "Symbol",
  le: "Listed exchange",
  ld: "Listing Date",
  lc: "Listing code*",
  ts: "Total share*",
  lfi: "listedDate field must be later than Date of Incorporation",
};
const elist = [
  { id: 1, value: "BSE" },
  { id: 2, value: "NSE" },
];
export { PageConst, elist };
