export const GET_CLOSURE_LIST_INITIATED = "GET_CLOSURE_LIST_INITIATED";
export const GET_CLOSURE_LIST_SUCCESS = "GET_CLOSURE_LIST_SUCCESS";
export const GET_CLOSURE_LIST_ERROR = "GET_CLOSURE_LIST_ERROR";

export const GET_CLOSURE_BY_ID_INITIATED = "GET_CLOSURE_BY_ID_INITIATED";
export const GET_CLOSURE_BY_ID_SUCCESS = "GET_CLOSURE_BY_ID_SUCCESS";
export const GET_CLOSURE_BY_ID_ERROR = "GET_CLOSURE_BY_ID_ERROR";

export const GET_MENYCLOSURE_BY_ID_INITIATED = "GET_MENYCLOSURE_BY_ID_INITIATED";
export const GET_MENYCLOSURE_BY_ID_SUCCESS = "GET_MENYCLOSURE_BY_ID_SUCCESS";
export const GET_MENYCLOSURE_BY_ID_ERROR = "GET_MENYCLOSURE_BY_ID_ERROR";

export const ADD_CLOSURE_INITIATED = "ADD_CLOSURE_INITIATED";
export const ADD_CLOSURE_SUCCESS = "ADD_CLOSURE_SUCCESS";
export const ADD_CLOSURE_ERROR = "ADD_CLOSURE_ERROR";

export const UPDATE_CLOSURE_INITIATED = "UPDATE_CLOSURE_INITIATED";
export const UPDATE_CLOSURE_SUCCESS = "UPDATE_CLOSURE_SUCCESS";
export const UPDATE_CLOSURE_ERROR = "UPDATE_CLOSURE_ERROR";

export const DELETE_CLOSURE_INITIATED = "DELETE_CLOSURE_INITIATED";
export const DELETE_CLOSURE_SUCCESS = "DELETE_CLOSURE_SUCCESS";
export const DELETE_CLOSURE_ERROR = "DELETE_CLOSURE_ERROR";