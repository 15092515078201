import { push } from "connected-react-router";
import { notification, message } from "antd";

import { axiosPost } from "modules/Axios"; //,axiosAuthPost
import { loader } from "redux/app/actions";
import { apiConstant } from "modules/config"; //,AuditConst
import * as actions from "./constant";

export const login = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.LOGIN_INITIATED });
    emptyCache();
    let response = await axiosPost(apiConstant.AUTH_LOGIN, payload);
    if (response.data) {
      let data = response.data;
      data.prj = "upsi";
      data.date = new Date();
      if (data.uid !== 69) {
        localStorage.setItem("auth", JSON.stringify(data));
        let log =
          data.uid === 66
            ? "Compliance Officer"
            : data.uid === 67
            ? "Designated Person"
            : data.uid === 68
            ? "PCS"
            : "";
        if (window.innerWidth > 1000) {
          notification["success"]({
            message: "User Login",
            description: data.name + " login as " + log,
          });
        } else message.success("User Login as " + log);
        // await axiosAuthPost(AuditConst.ADD_AUDIT,{toid:data.userid,text:"User login"});
        dispatch(push("/")); // window.location.reload();
      } else {
        if (window.innerWidth > 1000) {
          notification["error"]({
            message: "User Login",
            description: "User can't login as Connected Person",
          });
        } else message.error("User can't login as Connected Person");
      }
      await dispatch({ type: actions.LOGIN_SUCCESS, payload: payload });
    } else dispatch({ type: actions.LOGIN_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.LOGIN_ERROR, error: "Network Error" });
  }
};
export const setAuth = (payload) => (dispatch) => {
  dispatch({ type: actions.SET_AUTH, payload });
};
export const logout = () => async (dispatch) => {
  try {
    dispatch({ type: actions.LOGOUT_INITIATED });
    dispatch(loader(true));
    // let session_id = JSON.parse(localStorage.auth).sessionId;
    // let user_Id = JSON.parse(localStorage.auth).userId;
    // let token = JSON.parse(localStorage.auth).token;
    localStorage.removeItem("auth");
    emptyCache();
    dispatch(loader(false));
    dispatch({ type: actions.LOGOUT });
    // await axiosLogout(session_id + "/" + user_Id, token);
    window.location.pathname !== "/login" && dispatch(push("/login"));
    window.location.reload();
  } catch (error) {
    dispatch(loader(false));
    console.log(error, "Logout Error");
  }
};

export const emptyCache = () => {
  if ("caches" in window)
    caches.keys().then((a) => {
      a?.forEach((b) => {
        caches.delete(b);
      });
    });
};
