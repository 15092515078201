import styled from "styled-components";
import { Theme } from "App/theme";

const StyleContainer = styled.div`
  .ant-collapse {
    .ant-collapse-header {
      padding: 0;
    }
    .title {
      display: flex;
      width: -webkit-fill-available;
      > div {
        width: -webkit-fill-available;
      }
    }
    .ant-collapse-item-active .title{
      border-bottom: 1px dashed #c5c5c5;
    }
    .ant-collapse-header {
      padding-left: 0 !important;
    }
    .ant-collapse-header-text {
      width: 100%;
    }
    .anticon-minus-circle,
    .anticon-plus-circle {
      color: ${Theme.mainColor};
      font-size: 20px;
      width: 10%;
      padding-right: 5px;
    }
  }
`;

export { StyleContainer };
