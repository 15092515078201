const DecConst = {dec: "Draft Form"};
const downName = [
  {
    name: "FORM B Disclosure on Appointment",
    url: "files/FORM B Disclosure on Appointment.docx",
    dname: "FORM B Disclosure on Appointment.docx",
  },
  {
    name: "FORM C Disclosure of Change in Holding",
    url: "files/FORM C Disclosure of Change in Holding.docx",
    dname: "FORM C Disclosure of Change in Holding.docx",
  },
  {
    name: "FORM D Disclosure by Other Connected persons",
    url: "files/Form D Disclosure by Other Connected persons.docx",
    dname: "Form D Disclosure by Other Connected persons.docx",
  },
  {
    name: "FORM E Application for Pre-clearrance Approval",
    url: "files/FORM E Application for Pre-clearrance Approval.docx",
    dname: "FORM E Application for Pre-clearrance Approval.docx",
  },
  {
    name: "FORM F Undertaking",
    url: "files/FORM F Undertaking.docx",
    dname: "FORM F Undertaking.docx",
  },
  {
    name: "Benpose Template",
    url: "files/Benpose Template.xlsx",
    dname: "Benpose Template.xlsx",
  },
];
export { DecConst, downName };
