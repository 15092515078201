import React, { Component } from "react";
import { Chart } from "react-google-charts";
import { Calendar, Spin } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { StyleContainer } from "./style.js";
import { getUserList } from "redux/user/actions";
import { getTradingList } from "redux/trading/actions";
import { DashboardConst, MonthConst } from "./constant";
import { getUpsiInfoList } from "redux/upsiInfo/actions";
import { getConRefList } from "redux/connRef/actions";
import { getMenyClosureById, getClosureList } from "redux/closure/actions";
import { getAuthRole, getAuthUserID } from "modules/helper";
import { Row, Col, Table, LayOut, Modal, Select } from "components/Form";
import {
  getMUList,
  getMPList,
  getYUList,
  getYPList,
} from "redux/dashboard/actions";

var userRole = getAuthRole();
var userId = getAuthUserID();
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      gname: "Monthly",
      ytype: "",
      ghrap1: [],
      ulist: [],
      clist: [],
      mData: [],
      mlList: [],
      userlist: [],
      ygrpData: [],
      urlist: [],
      rids: [],
      clList: [],
      conlist: [],
      trdList: [],
      monthData: [],
      gtype: "Monthly",
      glist: ["Monthly", "Yearly"],
      ylist: [],
      init: {
        UPSIRequest: 0,
        TradingRequest: 0,
        DesignatedPerson: 0,
        ConnectedPerson: 0,
      },
    };
  }
  async componentDidMount() {
    try {
      userRole = userRole ? userRole : getAuthRole();
      userId = userId ? userId : getAuthUserID();
      await this.props.getConRefList(userId);
      await this.props.getUserList();
      await this.props.getUpsiInfoList();
      await this.props.getTradingList();
      await this.props.getClosureList();
      setTimeout(() => this.grapCalYear(), 800);
    } catch (error) {
      console.log(error);
    }
  }
  componentDidUpdate(prevProps) {
    try {
      const {
        dashData,
        graphData,
        tradingList,
        upsiInfoList,
        closureData,
        closureMeny,
        userList,
        connRefList,
        closureList,
      } = this.props;
      if (prevProps.dashData !== dashData) {
        dashData.length > 0 && this.setState({ init: dashData[0] });
      }
      if (connRefList !== prevProps.connRefList) {
        let list = [userId.toString()];
        connRefList?.filter(
          (c) =>
            c.dpId === userId &&
            c.is_deleted === 0 &&
            list.push(c.uId?.toString())
        );
        this.setState({ conlist: list });
      }
      if (prevProps.closureList !== closureList) {
        const { conlist, userlist } = this.state;
        let ml = closureList?.filter((a) => a.is_deleted === 0);
        let ml2 = [];
        if (userRole === 67) {
          ml = ml?.filter((a) => a.uid === userId || a.upid === userId);
          conlist?.forEach((s) => {
            let cl = closureList?.filter(
              (a) => a.is_deleted === 0 && a.sid?.includes(s?.toString())
            );
            cl?.forEach((f) => {
              if (
                ml2?.findIndex((x) => x.id === f.id) === -1 &&
                ml?.findIndex((d) => d.id === f.id) === -1
              )
                ml2.push(f);
            });
          });
        }
        let allcl = ml?.concat(ml2);
        allcl?.forEach((f) => {
          let usr = userlist?.find((x) => x.id === f.upid || x.id === f.uid);
          f.unmae = usr?.value;
        });
        let sortData = allcl?.sort((a, b) => b.id - a.id);
        this.setState({ clList: sortData });
        setTimeout(() => this.setCal(), 500);
      }
      if (upsiInfoList !== prevProps.upsiInfoList) {
        const { userlist, rids, conlist } = this.state;
        let upist = upsiInfoList?.filter((a) => a.is_deleted === 0);
        let data = [];
        let rec = [],
          dp = [];
        upist.forEach((v) => {
          if (v.uid === userId) data.push(v);
          else
            conlist?.forEach(
              (s) => v?.receiverId?.includes(s?.toString()) && data.push(v)
            );
        });
        data?.forEach((b) => {
          let s = b.receiver?.split(",");
          let bm = new Date(b.sdate).getMonth();
          b.month = MonthConst[bm];
          b.year = new Date(b.sdate).getFullYear().toString();
          s?.forEach((t) => !rec.includes(t) && rec.push(t));
          if (!dp.includes(b.dpName)) {
            if (rids?.includes(b.dpId)) {
              let idx = rids.findIndex((f) => f === b.dpId);
              if (!dp.includes(userlist[idx].value))
                dp.push(userlist[idx].value);
              b.dpName = userlist[idx].value;
            } else dp.push(b.dpName);
          }
        });
        let srtData = data.sort(
          (a, b) => new Date(b?.created_date) - new Date(a?.created_date)
        );
        this.setState({ ulist: srtData });
      }
      if (prevProps.tradingList !== tradingList) {
        const { conlist } = this.state;
        let tList = tradingList?.filter((a) => a.is_deleted === 0);
        let tList2 = [],
          tList3 = [];
        if (userRole === 67) {
          conlist?.forEach((s) => {
            let cl = tList?.filter(
              (a) => a.connectedID?.toString() === s || a.uid?.toString() === s
            );
            cl?.forEach((f) => {
              if (tList2?.findIndex((d) => d.id === f.id) === -1) {
                let ixd = new Date(f.startDate).getMonth();
                f.month = MonthConst[ixd];
                f.year = new Date(f.startDate).getFullYear().toString();
                tList2.push(f);
                if (f.type === "Pending") tList3.push(f);
              }
            });
          });
        }
        tList3?.forEach((f) => (f.pid = "PreClearance-" + f.id));
        let sortData = tList3?.sort((a, b) => b.id - a.id);
        this.setState({ clist: sortData, trdList: tList2 });
      }
      if (graphData !== prevProps.graphData) {
        const data = [["Month", "UPSI", "Trading"]];
        graphData.forEach((a) =>
          data.push([a.month, a.ConnectedPerson, a.designatedPerson])
        );
        this.setState({ ghrap1: data });
      }
      if (closureData !== prevProps.closureData) {
        const { userlist } = this.state;
        let cd = closureData?.filter((a) => a.is_deleted === 0);
        cd?.forEach((f) => {
          let usr = userlist?.find((x) => x.id === f.uid);
          f.unmae = usr?.value;
        });
        this.setState({ mData: cd, visible: true });
      }
      if (closureMeny !== prevProps.closureMeny) {
        const { userlist } = this.state;
        let cm = closureMeny?.filter((a) => a.is_deleted === 0);
        cm?.forEach((f) => {
          let usr = userlist?.find((x) => x.id === f.uid);
          f.unmae = usr?.value;
        });
        this.setState({ mData: cm, visible: true });
      }
      if (userList !== prevProps.userList) {
        let urlist = userList?.filter((a) => a.is_deleted === 0);
        let data = [],
          rids = [];
        urlist?.forEach((a) => {
          a.value = a.surname
            ? a.name?.trim() + " " + a.surname?.trim()
            : a.name?.trim();
          let pos =
            a.uid === 66
              ? " (CO"
              : a.uid === 67
              ? " (DP"
              : a.uid === 68
              ? " (PCS"
              : " (CP";
          if (data.find((b) => b.value === a.value + pos + ")")) {
            if (data.find((b) => b.value === a.value + pos + ") 2")) {
              if (data.find((b) => b.value === a.value + pos + ") 3"))
                data.push({ id: a.id, value: a.value + pos + ") 4" });
              else data.push({ id: a.id, value: a.value + pos + ") 3" });
            } else data.push({ id: a.id, value: a.value + pos + ") 2" });
          } else data.push({ id: a.id, value: a.value + pos + ")" });
          rids.push(a.id);
        });
        this.setState({ urlist: userList, userlist: data, rids });
      }
    } catch (error) {
      console.log(error);
    }
  }
  dateChange = (date) => {
    try {
      let day = new Date(date).getDate();
      day = day?.toString()?.length === 1 ? "0" + day : day;
      let month = new Date(date).getMonth() + 1;
      month = month?.toString()?.length === 1 ? "0" + month : month;
      let year = new Date(date).getFullYear();
      return date ? year + "-" + month + "-" + day : "";
    } catch (error) {
      console.log(error);
    }
  };
  callcal = async () => setTimeout(() => this.setCal(), 500);
  setCal = () => {
    try {
      const { clList } = this.state;
      let sld = document.getElementsByClassName("selected"); // let date = [];
      for (let i = sld?.length - 1; i > -1; i--) {
        sld[i]?.classList.replace("selected", "ui");
      }
      clList?.forEach((a) => {
        if (a.from) {
          let title = this.dateChange(a.from); // let title = a.from?.split("T")[0];
          let ele = document.querySelector(`[title="${title}"]`);
          if (ele?.className?.search(" selected") === -1) {
            ele.className = ele?.className + " selected";
            ele.addEventListener("click", this.onSelect);
            ele.id = a.id;
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  selectUI = (val, name, data) => {
    try {
      const { mlList } = this.state;
      return (
        <Select
          name={name}
          data={data}
          value={val}
          showSearch={"No"}
          defaultValue={val}
          onChange={async (value) => {
            if (name === "gtype") this.setState({ gname: value });
            else {
              let lst = mlList?.find((s) => s.year === value);
              this.setState({ ytype: value, monthData: lst ? lst.val : [] });
            }
          }}
        />
      );
    } catch (error) {
      console.log(error);
    }
  };
  grapCalYear = () => {
    try {
      const { ulist, trdList } = this.state;
      let ylData = [["Year", "UPSI", "Trading"]];
      let mlData = [];
      let conData = ulist.concat(trdList);
      let yadata = [];
      let srt = conData.sort((a, b) => a.year - b.year);
      srt?.forEach((x) => {
        let idx = ylData?.findIndex((s) => s[0] === x.year);
        if (idx > -1) {
          let midx = mlData?.findIndex((s) => s.year === x.year);
          let midx2 = mlData[midx]?.val?.findIndex((s) => s[0] === x.month);
          if (x.dpId) {
            ylData[idx][1] = ylData[idx][1] + 1;
            if (midx > -1) {
              if (midx2 > -1)
                mlData[midx].val[midx2][1] = mlData[midx].val[midx2][1] + 1;
              else mlData[midx].val.push([x.month, 1, 0]);
            } else mlData.push({ year: x.year, val: [[x.month, 1, 0]] });
          } else if (x.connectedID) {
            ylData[idx][2] = ylData[idx][2] + 1;
            if (midx > -1) {
              if (midx2 > -1)
                mlData[midx].val[midx2][2] = mlData[midx].val[midx2][2] + 1;
              else mlData[midx].val.push([x.month, 0, 1]);
            } else mlData.push({ year: x.year, val: [[x.month, 0, 1]] });
          }
        } else {
          ylData.push([x.year, x.dpId ? 1 : 0, x.connectedID ? 1 : 0]);
          mlData.push({
            year: x.year,
            val: [[x.month, x.dpId ? 1 : 0, x.connectedID ? 1 : 0]],
          });
          yadata.push(x.year);
        }
      });
      if (ylData.length > 1) {
        let molist = [];
        mlData?.forEach((a) => {
          let list = [["Month", "UPSI", "Trading"]];
          MonthConst.forEach((u) => {
            let k = a.val?.findIndex((b) => b[0] === u);
            if (k > -1) list.push(a.val[k]);
            // else list.push([u, 0, 0]);
          });
          molist.push({ year: a.year, val: list });
        });
        let cy = new Date().getFullYear().toString();
        let md = molist.find((s) => s.year === cy)?.val;
        this.setState({
          ytype: cy,
          ygrpData: ylData,
          ylist: yadata,
          mlList: molist,
          monthData: md ? md : [],
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  grapUI = () => {
    try {
      const { gtype, glist, ytype, ylist, gname, ygrpData, monthData } =
        this.state;
      let chdata = gname === "Monthly" ? monthData : ygrpData;
      return (
        <div className="pDiv mh">
          <div className="gDiv">
            <h3>{DashboardConst.head1}</h3>
            <div className="ml-auto flex slDiv">
              {this.selectUI(gtype, "gtype", glist)}
              {ytype !== "" &&
                gname === "Monthly" &&
                this.selectUI(ytype, "ytype", ylist)}
            </div>
          </div>
          <hr />
          {chdata.length > 1 && (
            <Chart chartType="Bar" width="100%" height="300px" data={chdata} />
          )}
        </div>
      );
    } catch (error) {
      console.log(error);
    }
  };
  onSelect = async (e) => {
    try {
      const { clList } = this.state;
      if (e?.currentTarget?.title) {
        let a = new Date(e.currentTarget.title);
        let date = a?.toJSON().split("T")[0];
        let cDate = clList?.filter((c) => this.dateChange(c.from) === date);
        if (cDate?.length === 1 && cDate[0]?.id)
          await this.props.getMenyClosureById({ ids: cDate[0].id });
        else if (cDate?.length > 1) {
          let ids = [];
          cDate.forEach((b) => ids.push(b.id));
          await this.props.getMenyClosureById({ ids: ids });
        }
      }
      setTimeout(() => this.setCal(), 500);
    } catch (error) {
      console.log(error);
    }
  };
  toggleModal = () => this.setState({ visible: !this.state.visible });
  render() {
    const { loading, loading1 } = this.props;
    const { ulist, clist, visible, mData } = this.state;
    return (
      <Spin size="large" spinning={loading || loading1}>
        <LayOut>
          <StyleContainer className="main anime">
            <Row gutter={24}>
              <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                {this.grapUI()}
              </Col>
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                <div className="pDiv mh">
                  <h3>{DashboardConst.head2}</h3>
                  <Calendar
                    fullscreen={false} // onSelect={(e) => this.onSelect(new Date(e))}
                    onPanelChange={() => this.callcal()}
                  />
                </div>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <div className="pDiv">
                  <div>
                    <h3>{DashboardConst.head3}</h3>
                    <Table data={ulist} type="upsiInfo" noAction psize />
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <div className="pDiv">
                  <h3>{DashboardConst.head4}</h3>
                  <Table noAction data={clist} type="trading" />
                </div>
              </Col>
            </Row>
          </StyleContainer>
        </LayOut>
        {visible && <Modal data={mData} toggleModal={this.toggleModal} />}
      </Spin>
    );
  }
}
const mapStateToProps = (state) => ({
  loading: state.dashboard.loading,
  loading1: state.closure.loading,
  closureMeny: state.closure.closureMeny,
  userList: state.user.userList,
  tradingList: state.trading.tradingList,
  upsiInfoList: state.upsiInfo.upsiInfoList,
  closureList: state.closure.closureList,
  connRefList: state.connref.connRefList,
  muList: state.dashboard.muList,
  yuList: state.dashboard.yuList,
  mpList: state.dashboard.mpList,
  ypList: state.dashboard.ypList,
});
const mapDispatchToProps = (dispatch) => ({
  getMUList: (payload) => dispatch(getMUList(payload)),
  getMPList: (payload) => dispatch(getMPList(payload)),
  getYUList: (payload) => dispatch(getYUList(payload)),
  getYPList: (payload) => dispatch(getYPList(payload)),
  getUserList: (payload) => dispatch(getUserList(payload)),
  getUpsiInfoList: (payload) => dispatch(getUpsiInfoList(payload)),
  getTradingList: (payload) => dispatch(getTradingList(payload)),
  getConRefList: (payload) => dispatch(getConRefList(payload)),
  getClosureList: (payload) => dispatch(getClosureList(payload)),
  getMenyClosureById: (payload) => dispatch(getMenyClosureById(payload)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
