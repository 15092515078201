import React, { Component } from "react";
import { Spin } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  getDeclarationList,
  getDeclarationByUId,
  deleteDeclaration,
  updateDeclaration,
} from "redux/declaration/actions";
import { getUserList } from "redux/user/actions";
import AddDeclaration from "container/AddDeclaration";
import { appConst, ButtonConst } from "App/AppConstant";
import { getAuthUserID, getAuthRole } from "modules/helper";
import { Table, LayOut, Row, Col, Button, Input } from "components/Form";

var role = getAuthRole();
var userId = getAuthUserID();
class Declaration extends Component {
  constructor(props) {
    super(props);
    this.state = { dList: [], userlist: [], forms: false, search: "" };
  }
  async componentDidMount() {
    try {
      const { match } = this.props;
      userId = userId ? userId : getAuthUserID();
      role = role ? role : getAuthRole();
      await this.props.getUserList();
      if (localStorage.url === "/profile") localStorage.url = "/";
      let path = match.path.replace(/\//g, "");
      if (path !== "add-draft-forms") {
        if (role === 67) await this.props.getDeclarationByUId(userId);
        else await this.props.getDeclarationList();
      } else this.setState({ forms: true });
    } catch (error) {
      console.log(error);
    }
  }
  async componentDidUpdate(prevProps) {
    try {
      const { docuData, docList, isSaved, userList } = this.props;
      if (prevProps.isSaved !== isSaved)
        if (isSaved) {
          if (role !== 66) await this.props.getDeclarationByUId(userId);
          else await this.props.getDeclarationList();
        }
      if (prevProps.docuData !== docuData) {
        const { userlist } = this.state;
        let dList = docuData?.filter(
          (a) => a.is_deleted === 0 && a.form !== "form"
        );
        dList?.forEach((a) => {
          a.id = a.dmid;
          let usr = userlist?.find((x) => x.id === a.uid);
          a.unmae = usr?.value;
        });
        this.setState({ dList });
      }
      if (prevProps.docList !== docList) {
        const { userlist } = this.state;
        let dList = docList?.filter(
          (a) => a.is_deleted === 0 && a.form !== "form"
        );
        dList?.forEach((a) => {
          a.id = a.dmid;
          let usr = userlist?.find((x) => x.id === a.uid);
          a.unmae = usr?.value;
        });
        this.setState({ dList });
      }
      if (userList !== prevProps.userList) {
        let urlist = userList?.filter((a) => a.is_deleted === 0);
        let data = [];
        urlist?.forEach((a) => {
          a.value = a.surname ? a.name + " " + a.surname : a.name;
          let pos = a.uid === 67 ? " (DP" : ""; // a.uid === 66? " (CO": a.uid === 68? " (PCS": " (CP";
          if (data.find((b) => b.value === a.value + pos + ")")) {
            if (data.find((b) => b.value === a.value + pos + ") 2")) {
              if (data.find((b) => b.value === a.value + pos + ") 3"))
                data.push({ id: a.id, value: a.value + pos + ") 4" });
              else data.push({ id: a.id, value: a.value + pos + ") 3" });
            } else data.push({ id: a.id, value: a.value + pos + ") 2" });
          } else data.push({ id: a.id, value: a.value + pos + ")" });
        });
        this.setState({ userlist: data });
      } 
    } catch (error) {
      console.log(error);
    }
  }
  removeData = async (id) => {
    try {
      await this.props.deleteDeclaration({ id: id, uid: userId });
      if (role !== 66) await this.props.getDeclarationByUId(userId);
      else await this.props.getDeclarationList();
    } catch (error) {
      console.log(error);
    }
  };
  setclr = async (id, b) => {
    try {
      await this.props.updateDeclaration({
        dmid: id,
        type: b ? "Approved" : "Rejected",
      });
      if (role === 67) await this.props.getDeclarationByUId(userId);
      else await this.props.getDeclarationList();
    } catch (error) {
      console.log(error);
    }
  };
  changetxt = (e) => this.setState({ search: e.target.value });
  render() {
    const { dList, forms, search } = this.state;
    const { loading } = this.props;
    return (
      <Spin size="large" spinning={loading}>
        <LayOut>
          <div className="main anime">
            <div className="head">
              <h4 className="title">{!forms ? appConst.dec : appConst.af}</h4>
              <div className="btnDiv">
                {forms ? (
                  <Button onClick={() => this.props.history.goBack()}>
                    {ButtonConst.back}
                  </Button>
                ) : (
                  <Input
                    placeholder={appConst.src}
                    handleChange={this.changetxt}
                  />
                )}
              </div>
            </div>
            <Row gutter={24}>
              {!forms && (
                <Col xs={24} sm={24} md={24} lg={24} xl={role === 67 ? 15 : 24}>
                  <div className="display">
                    <Table
                      data={dList}
                      type="declaration"
                      tab="Declaration"
                      search={search}
                      deleteCol={this.removeData}
                      setclr={this.setclr}
                      editCol={(id) =>
                        this.props.history.push("/edit-declaration/" + id)
                      }
                    />
                  </div>
                </Col>
              )}
              {(role === 67 || forms) && (
                <Col xs={24} sm={24} md={24} lg={24} xl={!forms ? 9 : 24}>
                  <AddDeclaration />
                </Col>
              )}
            </Row>
          </div>
        </LayOut>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.declaration.loading,
  docuData: state.declaration.docuData,
  isSaved: state.declaration.isSaved,
  docList: state.declaration.docList,
  userList: state.user.userList,
});
const mapDispatchToProps = (dispatch) => ({
  getUserList: (payload) => dispatch(getUserList(payload)),
  getDeclarationByUId: (payload) => dispatch(getDeclarationByUId(payload)),
  deleteDeclaration: (payload) => dispatch(deleteDeclaration(payload)),
  getDeclarationList: (payload) => dispatch(getDeclarationList(payload)),
  updateDeclaration: (payload) => dispatch(updateDeclaration(payload)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Declaration)
);
