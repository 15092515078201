export const GET_SUBSCRIPTION_LIST_INITIATED = "GET_SUBSCRIPTION_LIST_INITIATED";
export const GET_SUBSCRIPTION_LIST_SUCCESS = "GET_SUBSCRIPTION_LIST_SUCCESS";
export const GET_SUBSCRIPTION_LIST_ERROR = "GET_SUBSCRIPTION_LIST_ERROR";

export const GET_SUBSCRIPTION_BY_ID_INITIATED = "GET_SUBSCRIPTION_BY_ID_INITIATED";
export const GET_SUBSCRIPTION_BY_ID_SUCCESS = "GET_SUBSCRIPTION_BY_ID_SUCCESS";
export const GET_SUBSCRIPTION_BY_ID_ERROR = "GET_SUBSCRIPTION_BY_ID_ERROR";

export const ADD_SUBSCRIPTION_INITIATED = "ADD_SUBSCRIPTION_INITIATED";
export const ADD_SUBSCRIPTION_SUCCESS = "ADD_SUBSCRIPTION_SUCCESS";
export const ADD_SUBSCRIPTION_ERROR = "ADD_SUBSCRIPTION_ERROR";

export const UPDATE_SUBSCRIPTION_INITIATED = "UPDATE_SUBSCRIPTION_INITIATED";
export const UPDATE_SUBSCRIPTION_SUCCESS = "UPDATE_SUBSCRIPTION_SUCCESS";
export const UPDATE_SUBSCRIPTION_ERROR = "UPDATE_SUBSCRIPTION_ERROR";

export const DELETE_SUBSCRIPTION_INITIATED = "DELETE_SUBSCRIPTION_INITIATED";
export const DELETE_SUBSCRIPTION_SUCCESS = "DELETE_SUBSCRIPTION_SUCCESS";
export const DELETE_SUBSCRIPTION_ERROR = "DELETE_SUBSCRIPTION_ERROR";