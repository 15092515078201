import React, { Component } from "react";
import * as Yup from "yup";
import { Spin } from "antd";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import { withRouter } from "react-router-dom";

import { PageConst } from "./constant";
import { ButtonConst, FormValid } from "App/AppConstant";
import {
  Label,
  Input,
  Button,
  Row,
  Col,
  LayOut,
  Table,
  Checkbox,
  Modal,
} from "components/Form";
import { getAuthUserID, getAuthRole } from "modules/helper";
import {
  addSMTP,
  getSMTPList,
  getSMTPById,
  deleteSMTP,
  updateSMTP,
  updateActiveSMTP,
  sendmail,
} from "redux/smtp/actions";
var userId = getAuthUserID();
var role = getAuthRole();
const ValidationSchema = Yup.object().shape({
  fromName: Yup.string()
    .trim()
    .nullable()
    .required(" ")
    .matches(/^[aA-zZ\s]+$/, FormValid.alphaValid),
  email: Yup.string().trim().min(3).email(FormValid.emailInvalid).required(" "),
  port: Yup.string().trim().nullable().required(" "),
  host: Yup.string().trim().nullable().required(" "),
  username: Yup.string().trim().nullable().required(" "),
  password: Yup.string().trim().nullable().required(" "),
});

class AddDeclaration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disable: false,
      checked: false,
      visible: false,
      mdata: {},
      init: {
        fromName: "",
        email: "",
        port: "",
        host: "",
        username: "",
        password: "",
      },
    };
  }
  async componentDidMount() {
    userId = userId ? userId : getAuthUserID();
    role = role ? role : getAuthRole();
    await this.props.getSMTPList(role !== 66 ? userId : "");
  }
  componentDidUpdate(prevProps) {
    try {
      const { smptList, isSaved, smptData, isSend } = this.props;
      if (prevProps.isSaved !== isSaved) {
        this.reset();
        isSaved && this.props.getSMTPList(userId);
      }
      if (prevProps.isSend !== isSend)
        if (isSend) this.setState({ visible: false });
      if (prevProps.smptList !== smptList) {
        let ulist = smptList?.filter((a) => a.is_deleted === 0);
        this.setState({ dList: ulist });
      }
      if (prevProps.smptData !== smptData) {
        this.setState({
          init: {
            id: smptData.id,
            fromName: smptData.fromName,
            email: smptData.email,
            port: smptData.port,
            host: smptData.host,
            username: smptData.username,
            password: "-",
          },
          checked: smptData.active,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
  reset = (setFieldValue) => {
    if (setFieldValue) {
      setFieldValue("fromName", "");
      setFieldValue("email", "");
      setFieldValue("port", "");
      setFieldValue("host", "");
      setFieldValue("username", "");
      setFieldValue("password", "");
    }
    this.setState({
      init: {
        fromName: "",
        email: "",
        port: "",
        host: "",
        username: "",
        password: "",
      },
      checked: false,
    });
  };
  edit = (id) => this.props.getSMTPById(window.atob(id));
  onChange = () => this.setState({ checked: !this.state.checked });
  handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      this.setState({ disable: true });
      setTimeout(() => this.setState({ disable: false }), 5000);
      const { checked } = this.state;
      values.active = checked ? 1 : 0;
      values.uid = userId;
      if (values.active === 1) await this.props.updateActiveSMTP();
      if (values.password === "-") delete values.password;
      if (values.id) await this.props.updateSMTP(values);
      else await this.props.addSMTP(values);
      setSubmitting(false);
      resetForm();
    } catch (error) {
      console.log(error, "handle error");
    }
  };
  sendMail = async (a) => this.setState({ visible: true, mdata: a });
  removeData = async (id) => {
    try {
      await this.props.deleteSMTP({ id: id, uid: userId });
      await this.props.getSMTPList(userId);
    } catch (error) {
      console.log(error);
    }
  };
  sendTest = async (email) => {
    try {
      const { mdata } = this.state;
      let temp = "<h2>Hello!, This is test mail!</h2>";
      let mailData = {
        id: mdata.id,
        to: email,
        subject: "Test Mail",
        html: temp,
      };
      let postData = {
        uid: userId,
        mail: mailData,
        text: { type: "Test mail", from: mdata.email, send: email, html: temp },
      };
      await this.props.sendmail(postData);
    } catch (error) {
      console.log(error);
    }
  };
  toggleModal = () => this.setState({ visible: !this.state.visible });
  render() {
    const { init, disable, dList, checked, visible } = this.state;
    const { loading } = this.props;
    return (
      <Spin spinning={loading} size="large">
        <LayOut>
          <div className="main anime">
            <div className="head">
              <h4 className="title">{PageConst.dl}</h4>
            </div>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                <div className="display">
                  <Table
                    data={dList}
                    type="smtp"
                    tab="smtp"
                    editCol={(id) => this.edit(id)}
                    deleteCol={this.removeData}
                    sendMail={this.sendMail}
                  />
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                <div className="display">
                  <Formik
                    initialValues={init}
                    validationSchema={ValidationSchema}
                    onSubmit={this.handleSubmit}
                    enableReinitialize
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      setFieldValue,
                    }) => (
                      <Form onSubmit={handleSubmit} noValidate>
                        <Row gutter={40}>
                          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Label
                              title={PageConst.email}
                              className={
                                errors.email && touched.email ? "empty" : ""
                              }
                            />
                            <Input
                              name="email"
                              value={values.email}
                              onBlur={handleBlur}
                              handleChange={handleChange}
                              className={
                                errors.email && touched.email ? "empty" : ""
                              }
                            />
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Label
                              title={PageConst.nd}
                              className={
                                errors.fromName && touched.fromName
                                  ? "empty"
                                  : ""
                              }
                            />
                            <Input
                              name="fromName"
                              value={values.fromName}
                              onBlur={handleBlur}
                              handleChange={handleChange}
                              className={
                                errors.fromName && touched.fromName
                                  ? "empty"
                                  : ""
                              }
                            />
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Label
                              title={PageConst.host}
                              className={
                                errors.host && touched.host ? "empty" : ""
                              }
                            />
                            <Input
                              name="host"
                              value={values.host}
                              onBlur={handleBlur}
                              handleChange={handleChange}
                              className={
                                errors.host && touched.host ? "empty" : ""
                              }
                            />
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Label
                              title={PageConst.port}
                              className={
                                errors.port && touched.port ? "empty" : ""
                              }
                            />
                            <Input
                              type="number"
                              name="port"
                              value={values.port}
                              onBlur={handleBlur}
                              handleChange={handleChange}
                              className={
                                errors.port && touched.port ? "empty" : ""
                              }
                            />
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Label
                              title={PageConst.us}
                              className={
                                errors.username && touched.username
                                  ? "empty"
                                  : ""
                              }
                            />
                            <Input
                              name="username"
                              value={values.username}
                              onBlur={handleBlur}
                              handleChange={handleChange}
                              className={
                                errors.username && touched.username
                                  ? "empty"
                                  : ""
                              }
                            />
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Label
                              title={PageConst.pwd}
                              className={
                                errors.password && touched.password
                                  ? "empty"
                                  : ""
                              }
                            />
                            <Input
                              password
                              name="password"
                              autoComplete="new-password"
                              value={values.password}
                              onBlur={handleBlur}
                              handleChange={handleChange}
                              className={
                                errors.password && touched.password
                                  ? "empty"
                                  : ""
                              }
                            />
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Checkbox
                              onChange={() => this.onChange()}
                              checked={checked}
                              text={PageConst.active}
                            />
                          </Col>
                        </Row>
                        <div className="btnDiv anime">
                          <Button
                            disabled={disable}
                            onClick={() => this.reset(setFieldValue)}
                          >
                            {ButtonConst.reset}
                          </Button>
                          <Button disabled={disable} htmlType={"submit"}>
                            {ButtonConst.save}
                          </Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Col>
            </Row>
          </div>
        </LayOut>
        {visible && (
          <Modal mail toggleModal={this.toggleModal} sendTest={this.sendTest} />
        )}
      </Spin>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.smtp.loading,
  isSaved: state.smtp.isSaved,
  smptList: state.smtp.smptList,
  smptData: state.smtp.smptData,
  isSend: state.smtp.isSend,
});
const mapDispatchToProps = (dispatch) => ({
  addSMTP: (payload) => dispatch(addSMTP(payload)),
  getSMTPList: (payload) => dispatch(getSMTPList(payload)),
  getSMTPById: (payload) => dispatch(getSMTPById(payload)),
  deleteSMTP: (payload) => dispatch(deleteSMTP(payload)),
  updateSMTP: (payload) => dispatch(updateSMTP(payload)),
  sendmail: (payload) => dispatch(sendmail(payload)),
  updateActiveSMTP: (payload) => dispatch(updateActiveSMTP(payload)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddDeclaration)
);
