import React, { Component } from "react";
import * as Yup from "yup";
import { Spin, message } from "antd";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import { withRouter } from "react-router-dom";

import { PageConst } from "./constant";
import { getUserList } from "redux/user/actions";
import { addHolding } from "redux/holding/actions";
import { getCompanyList } from "redux/company/actions";
import { ButtonConst, FormValid, Holidays } from "App/AppConstant";
import { getAuthRole, getAuthUserID } from "modules/helper";
import { addBenPos, addBenPosdif, getBenPoList } from "redux/benpo/actions";
import {
  Label,
  Input,
  Button,
  Row,
  Col,
  LayOut,
  FileUpload,
  DatePicker,
} from "components/Form";

var role = getAuthRole();
var userId = getAuthUserID();
const ValidationSchema = Yup.object().shape({
  date: Yup.string().trim().nullable().required(" "),
  isin: Yup.string()
    .trim()
    .nullable()
    .matches(/^([A-Z]{2})([A-Z0-9]{10})$/, FormValid.isinValid),
});

class AddBenPo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disable: false,
      mList: [],
      ulist: [],
      totalshare:0,
      init: {
        date: new Date().toISOString().split("T")[0],
        isin: "",
      },
    };
  }
  async componentDidMount() {
    try {
      role = role ? role : getAuthRole();
      userId = userId ? userId : getAuthUserID();
      await this.props.getBenPoList();
      await this.props.getUserList();
      await this.props.getCompanyList();
      this.checkHDate();
    } catch (error) {
      console.log(error);
    }
  }
  async componentDidUpdate(prevProps) {
    try {
      const { userList, companyList } = this.props;
      if (userList !== prevProps.userList) {
        let ulist = userList?.filter((a) => a.is_deleted === 0);
        this.setState({ ulist });
      }
      if (companyList !== prevProps.companyList) {
        const { init } = this.state;
        let company = companyList?.filter((a) => a.is_deleted === 0);
        init.isin = company[0].insi?.toUpperCase();
        this.setState({ init,totalshare:company[0].totalshare });
      }
    } catch (error) {
      console.log(error);
    }
  }
  checkHDate = () => {
    try {
      const { init } = this.state;
      if (this.disabledHDate(init.date)) init.date = "";
      this.setState({ init });
    } catch (error) {
      console.log(error);
    }
  };
  disabledHDate = (current) => {
    try {
      let match = Holidays?.find(
        (a) => new Date(a)?.toDateString() === new Date(current)?.toDateString()
      );
      return (
        match ||
        new Date(current).getDay() === 0 ||
        new Date(current).getDay() === 6
      );
    } catch (error) {
      console.log(error);
    }
  };
  getAllData = (data) => {
    try {
      let ml = [];
      data?.forEach((a) => {
        if (!a.HOLDER)
          ml.push({
            dpid: a["DPID"] ? a["DPID"] : null,
            clientID:
              a["Client ID "] || a["Client ID"]
                ? a["Client ID "] || a["Client ID"]
                : null,
            name:
              a["Shareholder Name "] || a["Shareholder Name"]
                ? a["Shareholder Name "] || a["Shareholder Name"]
                : null,
            address1: a["Shareholder Address (1)"]
              ? a["Shareholder Address (1)"]
              : null,
            address2: a["Shareholder Address (2)"]
              ? a["Shareholder Address (2)"]
              : null,
            address3: a["Shareholder Address (3)"]
              ? a["Shareholder Address (3)"]
              : null,
            address4: a["Shareholder Address (4)"]
              ? a["Shareholder Address (4)"]
              : null,
            pinCode:
              a["PIN Code "] || a["PIN Code"]
                ? a["PIN Code "] || a["PIN Code"]
                : null,
            totalShare:
              a["Total Share"] || a["Total Share"] === 0
                ? a["Total Share"]
                : null,
            totalSharesPledged:
              a["Total Shares Pledged "] || a["Total Shares Pledged"]
                ? a["Total Shares Pledged "] || a["Total Shares Pledged"]
                : null,
            totalSharesunderLien: a["Total Shares under Lien"]
              ? a["Total Shares under Lien"]
              : null,
            category: a["Category"] ? a["Category"] : null,
            depositoryType:
              a["Depository Type "] || a["Depository Type"]
                ? a["Depository Type"] || a["Depository Type "]
                : null,
            pan: a["PAN No."] ? a["PAN No."] : null,
            phone: a["PHONE"] ? a["PHONE"] : null,
            email: a["EMAIL_ID"] ? a["EMAIL_ID"] : null,
            uid: userId,
          });
        else
          ml.push({
            unitCode: a["UNIT_CODE"] ? a["UNIT_CODE"] : null,
            secCode: a["SEC_CODE"] ? a["SEC_CODE"] : null,
            hdpid: a["DPID"] ? a["DPID"] : null,
            holderFol: a["HOLDER_FOL"] ? a["HOLDER_FOL"] : null,
            holder: a["HOLDER"] ? a["HOLDER"] : null,
            second: a["SECOND"] ? a["SECOND"] : null,
            third: a["THIRD"] ? a["THIRD"] : null,
            fhName: a["FH_NAME"] ? a["FH_NAME"] : null,
            haddress: a["HOLDER_ADD"] ? a["HOLDER_ADD"] : null,
            haddress1: a["HOLDER_AD1"] ? a["HOLDER_AD1"] : null,
            haddress2: a["HOLDER_AD2"] ? a["HOLDER_AD2"] : null,
            haddress3: a["HOLDER_AD3"] ? a["HOLDER_AD3"] : null,
            haddress4: a["HOLDER_PIN"] ? a["HOLDER_PIN"] : null,
            totalShar: a["TOTAL_SHAR"] ? a["TOTAL_SHAR"] : null,
            holdMinor: a["HOLD_MINOR"] ? a["HOLD_MINOR"] : null,
            type: a["TYPE"] ? a["TYPE"] : null,
            panNo: a["PAN_NO"] ? a["PAN_NO"] : null,
            hphone: a["PHONE"] ? a["PHONE"] : null,
            fax: a["FAX"] ? a["FAX"] : null,
            hemail: a["EMAIL_ID"] ? a["EMAIL_ID"] : null,
            uid: userId,
          });
      });
      this.setState({ mList: ml });
    } catch (error) {
      console.log(error);
    }
  };
  dateChange = (date, format) => {
    let day = new Date(date).getDate();
    day = day?.toString()?.length === 1 ? "0" + day : day;
    let month = new Date(date).getMonth() + 1;
    month = month?.toString()?.length === 1 ? "0" + month : month;
    let year = new Date(date).getFullYear();
    if (format) return year + "-" + month + "-" + day;
    return date ? day + "-" + month + "-" + year : "";
  };
  handleSubmit = async (values, { setSubmitting }) => {
    try {
      this.setState({ disable: true });
      setTimeout(() => this.setState({ disable: false }), 5000);
      const { mList, ulist ,totalshare } = this.state;
      if (mList.length === 0) message.error(PageConst.ucd);
      else {
        const { benpoList } = this.props;
        let inx = benpoList?.findIndex(
          (b) => new Date(b.date).getTime() > new Date(values.date).getTime()
        );
        let flage = false;
        benpoList.forEach((b) => {
          flage = flage
            ? flage
            : new Date(this.dateChange(b.date, true)).getTime() >=
              new Date(values.date).getTime();
        });
        if (flage) message.error(PageConst.dcd);
        else {
          let newData = [],
            difData = [];
          mList.forEach((a) => {
            a.date = values.date;
            if (
              a.name?.trim() !== "" &&
              a.pan?.trim() !== "" &&
              a.totalShare?.toString().trim() !== ""
            )
              newData.push(a);
            else if (
              a.holder?.trim() !== "" &&
              a.panNo?.trim() !== "" &&
              a.totalShar?.toString().trim() !== ""
            )
              difData.push(a);
          });
          values.uid = userId;
          values.isin = values.isin?.toUpperCase();
          ulist?.forEach(async (a) => {
            let index = newData?.findIndex(
              (b) => b.pan?.trim() === a.pan?.trim()
            );
            if (index > -1) {
              newData[index]["category"] = a.category;
              let holdData = {
                uId: a.id,
                securityName: "Naapbooks",
                security: "Equity Shares",
                securityType: 54,
                securityNo: newData[index].totalShare,
                holdingHeld: (
                  (newData[index].totalShare * 100) /
                  totalshare
                )?.toFixed(2),
                cid: userId,
                holdingDate: values.date,
              };
              await this.props.addHolding(holdData);
            }
          });
          if (newData.length > 0)
            await this.props.addBenPos({ benpo: values, new: newData });
          else if (difData.length > 0)
            await this.props.addBenPosdif({ benpo: values, new: difData });
          else message.error(PageConst.ucd);
        }
      }
      setSubmitting(false);
    } catch (error) {
      console.log(error, "handle error");
    }
  };
  setByte = () => {};
  render() {
    const { init, disable, edit } = this.state;
    const { loading, loading2 } = this.props;
    return (
      <Spin spinning={loading || loading2} size="large">
        <LayOut>
          <div className="main anime">
            <div className="head">
              <h4 className="title">{!edit ? PageConst.ad : PageConst.ed}</h4>
              {role !== 67 && (
                <Button onClick={() => this.props.history.push("/benPOS")}>
                  {ButtonConst.back}
                </Button>
              )}
            </div>
            <div className="display">
              <Formik
                initialValues={init}
                validationSchema={ValidationSchema}
                onSubmit={this.handleSubmit}
                enableReinitialize
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <Form onSubmit={handleSubmit} noValidate>
                    <Row gutter={40}>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.date}
                          className={errors.date && touched.date ? "empty" : ""}
                        />
                        <DatePicker
                          holiday
                          value={values.date}
                          handleChange={handleChange("date")}
                          className={errors.date && touched.date ? "empty" : ""}
                          tabIndex="1"
                          satSun
                        />
                        {errors.date && touched.date && (
                          <div className="form-error">{errors.date}</div>
                        )}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.isin}
                          className={errors.isin && touched.isin ? "empty" : ""}
                        />
                        <Input
                          name="isin"
                          value={values.isin?.toUpperCase()}
                          onBlur={handleBlur}
                          handleChange={handleChange}
                          className={errors.isin && touched.isin ? "empty" : ""}
                          tabIndex="2"
                        />
                        {errors.isin && touched.isin ? (
                          <div className="form-error">{errors.isin}</div>
                        ) : null}
                      </Col>
                      <Col>
                        <Label title={PageConst.dl} />
                        <FileUpload
                          nm={PageConst.up}
                          accept=".xlsx"
                          excel
                          sendByte={this.setByte}
                          sendData={this.getAllData}
                          tabIndex="3"
                        />
                      </Col>
                    </Row>
                    <div className="btnDiv anime">
                      <Button disabled={disable} htmlType={"submit"}>
                        {ButtonConst.save}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </LayOut>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.benpo.loading,
  benpoList: state.benpo.benpoList,
  userList: state.user.userList,
  loading2: state.holding.loading,
  companyList: state.company.companyList,
});
const mapDispatchToProps = (dispatch) => ({
  addBenPos: (payload) => dispatch(addBenPos(payload)),
  addBenPosdif: (payload) => dispatch(addBenPosdif(payload)),
  getBenPoList: (payload) => dispatch(getBenPoList(payload)),
  getUserList: (payload) => dispatch(getUserList(payload)),
  addHolding: (payload) => dispatch(addHolding(payload)),
  getCompanyList: (payload) => dispatch(getCompanyList(payload)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddBenPo)
);
