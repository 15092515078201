import React, { Component } from "react";
import { Space, Spin, Timeline } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  FolderAddOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";

import { PageConst } from "./constant";
import { getUserList } from "redux/user/actions";
import { ButtonConst, appConst } from "App/AppConstant";
import { getAuthRole, getAuthUserID } from "modules/helper";
import { getUpsiInfoList, deleteUpsiInfo } from "redux/upsiInfo/actions";
import { getAuditLog } from "redux/auditLog/actions";
import { getConRefList } from "redux/connRef/actions";
import {
  Button,
  Table,
  LayOut,
  Input,
  DatePicker,
  Label,
  Row,
  Col,
  MultiSelect,
} from "components/Form";
var role = getAuthRole();
var userId = getAuthUserID();

class UpsiInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schange: false,
      toError: false,
      fromDate: "",
      toDate: "",
      search: "",
      co: 0,
      sBy: [],
      rids: [],
      sWith: [],
      clist: [],
      ulist: [],
      urlist: [],
      showData: [],
      sharedBy: [],
      auditLogs: [],
      sharedWith: [],
      holdCh: [],
      disData: [
        "Type of UPSI",
        "Sender",
        "Receiver",
        "Purpose",
        "Mode",
        "Description",
        "Type",
        "UPSI Date",
        "Time Stamp",
      ],
    };
  }
  async componentDidMount() {
    const { disData } = this.state;
    role = role ? role : getAuthRole();
    userId = userId ? userId : getAuthUserID();
    this.setState({ holdCh: disData, showData: disData });
    if (role === 67) await this.props.getConRefList(userId);
    await this.props.getUserList();
    await this.props.getUpsiInfoList();
    await this.props.getAuditLog();
  }
  componentDidUpdate(prevProps) {
    try {
      const { upsiInfoList, auditLogList, userList, connRefList } = this.props;
      if (connRefList !== prevProps.connRefList) {
        let clist = connRefList.filter(
          (c) => c.dpId === userId && c.is_deleted === 0
        );
        this.setState({ clist });
      }
      if (upsiInfoList !== prevProps.upsiInfoList) {
        const { urlist, rids, clist, co } = this.state;
        let ulist = upsiInfoList?.filter((a) => a.is_deleted === 0);
        let data = [],
          rec = [],
          dp = [];
        if (role === 67) {
          let uhlist = [userId];
          clist?.forEach((l) => {
            let s = userList?.find((a) => a.id === l.uId);
            if (s) uhlist.push(s.id);
          }); // ulist = ulist?.filter((a) => a.uid === userId || a.receiverId?.includes(userId?.toString()));
          ulist.forEach((v) => {
            if (v.uid === userId) data.push(v);
            else
              uhlist?.forEach((s) => {
                if (v.receiverId?.includes(s?.toString())) data.push(v);
              });
          });
          ulist = data;
        }
        ulist?.forEach((b) => {
          let s = b.receiver?.split(",");
          s?.forEach((t) => !rec.includes(t) && rec.push(t));
          if (!dp.includes(b.dpName)) {
            if (rids?.includes(b.dpId)) {
              let idx = rids.findIndex((f) => f === b.dpId);
              if (!dp.includes(urlist[idx])) dp.push(urlist[idx]);
              b.dpName = urlist[idx];
            } else dp.push(b.dpName);
          }
          if (new Date(b?.created_date) < new Date("2022/05/19")) {
            if (b.dpId === co && b?.dpName.includes("Sahul")) {
              b.dpName = "Niharika Shah (CO)";
              if (!dp.includes("Niharika Shah (CO)"))
                dp.push("Niharika Shah (CO)");
            }
            if (b?.receiverId.includes(co) && b?.receiver?.includes("Sahul")) {
              let recv = b.receiver.replace(
                "CS Sahul Jotaniya (CO)",
                "Niharika Shah (CO)"
              );
              if (!rec.includes("Niharika Shah (CO)"))
                rec.push("Niharika Shah (CO)");
              b.receiver = recv;
            }
          }
        });
        let srtData = ulist.sort(
          (a, b) => new Date(b?.created_date) - new Date(a?.created_date)
        );
        this.setState({
          ulist: srtData,
          difData: srtData,
          sharedBy: dp,
          sharedWith: rec,
        });
      }
      if (auditLogList !== prevProps.auditLogList) {
        let srtData = auditLogList?.sort(
          (a, b) => new Date(b?.created_date) - new Date(a?.created_date)
        );
        this.setState({ auditLogs: srtData });
      }
      if (userList !== prevProps.userList) {
        let urlist = userList?.filter((a) => a.is_deleted === 0); // && a.uid !== 68
        let data = [],
          rids = [];
        urlist.forEach((a) => {
          a.value = a.surname ? a.name + " " + a.surname : a.name;
          if (a.uid === 66 && a.id === 59) this.setState({ co: a.id });
          let pos =
            a.uid === 66
              ? " (CO"
              : a.uid === 67
              ? " (DP"
              : a.uid === 68
              ? " (PCS"
              : " (CP";
          if (data.includes(a.value + pos + ")")) {
            if (data.includes(a.value + pos + ") 2")) {
              if (data.includes(a.value + pos + ") 3"))
                data.push(a.value + pos + ") 4");
              else data.push(a.value + pos + ") 3");
            } else data.push(a.value + pos + ") 2");
          } else data.push(a.value + pos + ")");
          rids.push(a.id);
        });
        this.setState({ urlist: data, rids });
      }
    } catch (error) {
      console.log(error);
    }
  }
  removeData = async (id) => {
    try {
      await this.props.deleteUpsiInfo({ id: id, uid: userId });
      await this.props.getUpsiInfoList();
    } catch (error) {
      console.log(error);
    }
  };
  handleChange = (e) => this.setState({ showData: e, schange: true });
  handleChange1 = (e) => this.setState({ sBy: e, schange: true });
  handleChange2 = (e) => this.setState({ sWith: e, schange: true });
  selectUI = (name, val, data) => {
    try {
      let dData = val?.length === 0 ? [] : val;
      return (
        <MultiSelect
          name={name}
          defaultValue={dData}
          data={data}
          onChange={(e) => {
            if (name === "record") this.handleChange(e);
            else if (name === "by") this.handleChange1(e);
            else if (name === "with") this.handleChange2(e);
          }}
        />
      );
    } catch (error) {
      console.log(error);
    }
  };
  handleDate = (d) =>
    this.setState({ fromDate: d, schange: true, toError: false });
  handleDate2 = (d) =>
    this.setState({ toDate: d, schange: true, toError: false });
  reset = () => {
    const { difData, holdCh } = this.state;
    this.setState({
      schange: false,
      toDate: "",
      fromDate: "",
      toError: false,
      sBy: [],
      sWith: [],
      ulist: difData,
      showData: holdCh,
      disData: holdCh,
    });
  };
  doSearch = () => {
    try {
      if (this.state.schange) {
        const { difData, toDate, fromDate, sWith, sBy, showData } = this.state;
        let rData = [],
          tData = [],
          toData = [];
        let fData =
          sBy.length > 0
            ? difData.filter((x) => sBy.includes(x.dpName))
            : difData;
        if (sWith.length > 0) {
          fData?.forEach((b) => {
            let recv = b.receiver?.split(",");
            for (let i = 0; i < recv.length; i++) {
              if (sWith.includes(recv[i])) {
                rData.push(b);
                break;
              }
            }
          });
          fData = rData;
        }
        if (fromDate !== "") {
          fData?.forEach(
            (b) =>
              (this.dateChange(b.sdate) === this.dateChange(fromDate) ||
                new Date(b.sdate)?.getTime() > new Date(fromDate)?.getTime()) &&
              tData.push(b)
          );
          fData = tData;
        }
        if (toDate !== "") {
          if (
            fromDate !== "" &&
            new Date(fromDate)?.getTime() > new Date(toDate)?.getTime()
          ) {
            this.setState({ toError: true });
            fData = difData;
          } else
            fData?.forEach(
              (b) =>
                (this.dateChange(b.sdate) === this.dateChange(toDate) ||
                  new Date(b.sdate)?.getTime() < new Date(toDate)?.getTime()) &&
                toData.push(b)
            );
          fData = toData;
        }
        this.setState({ ulist: fData, disData: showData }); //showData.length > 0 ? showData :disData
      }
    } catch (error) {
      console.log(error);
    }
  };
  dateChange = (date) => {
    let day = new Date(date).getDate();
    day = day?.toString()?.length === 1 ? "0" + day : day;
    let month = new Date(date).getMonth() + 1;
    month = month?.toString()?.length === 1 ? "0" + month : month;
    let year = new Date(date).getFullYear();
    return date ? day + "-" + month + "-" + year : "";
  };
  changeTxt = (txt, name, b) => {
    try {
      let a = txt === "Record updated " ? txt + "for " : txt + ", ";
      let v =
        a?.toString() + name?.toString() + PageConst.ct + b[name]?.toString();
      return v;
    } catch (error) {
      console.log(error);
    }
  };
  timeUI = () => {
    try {
      const { auditLogs, urlist, rids, co } = this.state;
      let uiData = [];
      auditLogs?.forEach((a) => {
        if (role === 67 && a.uid !== userId) return null;
        else {
          if (a.text?.match("UPSI Infomation")) {
            let b = a.otherText?.length > 2 && JSON.parse(a.otherText);
            let txt = "";
            a.stxt = "Record " + a.text?.replace("UPSI Infomation record ", "");
            a.timeStamp = a.created_date
              ? this.dateChange(a.created_date) +
                " " +
                new Date(a.created_date).toLocaleTimeString()
              : "";
            let inx = rids.findIndex((b) => b === a.uid);
            if (inx > -1) {
              a.stxt = a.stxt + " by ";
              if (
                rids[inx] === co &&
                new Date(a?.created_date) < new Date("2022/05/19")
              )
                a.stxt = a.stxt + "Niharika Shah (CO)";
              else a.stxt = a.stxt + urlist[inx];
            }
            if (b) {
              txt = "Record updated ";
              if (b.sdate && b.receiver)
                txt = txt + "for change UPSI Date to " + b.receiver;
              if (b.sdate && !b.receiver) txt = txt + "for change UPSI Date ";
              if (b.approve)
                txt = txt + "for change UPSI Date to add Window Closure ";
              if (b.type) txt = this.changeTxt(txt, "type", b);
              if (b.purpose) txt = this.changeTxt(txt, "purpose", b);
              if (b.mode) txt = this.changeTxt(txt, "mode", b);
              if (b.receiver) txt = this.changeTxt(txt, "receiver", b);
              if (b.description) txt = this.changeTxt(txt, "description", b);
              if (inx > -1) txt = txt + " by " + urlist[inx];
            }
            a.showText = txt;
            uiData.push(a);
          }
        }
      });
      return (
        <div className="timeLineMain">
          <Timeline>
            {uiData.map((a, i) => (
              <Timeline.Item
                key={i}
                color={`${a?.text?.match("updated") && "gray"}`}
                dot={
                  a?.text?.match("Added") ? (
                    <div className="icon">
                      <FolderAddOutlined />
                    </div>
                  ) : a.text?.match("deleted") ? (
                    <div className="icon">
                      <DeleteOutlined />
                    </div>
                  ) : (
                    a.text?.match("updated") && (
                      <div className="icon">
                        <EditOutlined />
                      </div>
                    )
                  )
                }
              >
                {a.showText ? a.showText : a.stxt}
                {" (" + a.timeStamp + ")"}
              </Timeline.Item>
            ))}
          </Timeline>
        </div>
      );
    } catch (error) {
      console.log(error);
    }
  };
  redirect = (link) => this.props.history.push(link);
  changetxt = (e) => this.setState({ search: e.target.value });
  render() {
    const {
      ulist,
      search,
      disData,
      holdCh,
      sharedBy,
      sharedWith,
      sBy,
      sWith,
      fromDate,
      toDate,
      schange,
      difData,
      toError,
    } = this.state;
    const { loading } = this.props;
    return (
      <Spin size="large" spinning={loading}>
        <LayOut>
          <div className="main anime">
            <div className="head hdDiv">
              <h4 className="title">{appConst.upl}</h4>
              <div className="btnDiv">
                <Input
                  placeholder={appConst.src}
                  handleChange={this.changetxt}
                />
                {role !== 68 && (
                  <Button
                    onClick={() => this.redirect("/add-upsi-information")}
                  >
                    {ButtonConst.add}
                  </Button>
                )}
              </div>
            </div>
            {difData?.length > 0 && (
              <div>
                <Row gutter={20}>
                  <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                    <Label title={PageConst.sr} />
                    {disData?.length === holdCh?.length &&
                      this.selectUI("record", disData, holdCh)}
                    {disData?.length !== holdCh?.length &&
                      this.selectUI("record", disData, holdCh)}
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                    <Label title={PageConst.sb} />
                    {sBy?.length === 0 &&
                      sBy === sharedBy &&
                      this.selectUI("by", sBy, sharedBy)}
                    {sBy?.length === 0 &&
                      sBy !== sharedBy &&
                      this.selectUI("by", sBy, sharedBy)}
                    {sBy?.length > 0 &&
                      sBy === sharedBy &&
                      this.selectUI("by", sBy, sharedBy)}
                    {sBy?.length > 0 &&
                      sBy !== sharedBy &&
                      this.selectUI("by", sBy, sharedBy)}
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                    <Label title={PageConst.sw} />
                    {sWith?.length === 0 &&
                      sWith === sharedWith &&
                      this.selectUI("with", sWith, sharedWith)}
                    {sWith?.length === 0 &&
                      sWith !== sharedWith &&
                      this.selectUI("with", sWith, sharedWith)}
                    {sWith?.length > 0 &&
                      sWith !== sharedWith &&
                      this.selectUI("with", sWith, sharedWith)}
                    {sWith?.length > 0 &&
                      sWith === sharedWith &&
                      this.selectUI("with", sWith, sharedWith)}
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                    <Label title={PageConst.fd} />
                    <DatePicker
                      value={fromDate}
                      handleChange={this.handleDate}
                      tabIndex="4"
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                    <Label
                      title={PageConst.td}
                      className={toError ? "empty" : ""}
                    />
                    <DatePicker
                      value={toDate}
                      handleChange={this.handleDate2}
                      tabIndex="5"
                      className={toError ? "empty" : ""}
                    />
                    {toError && (
                      <div className="form-error">{PageConst.dateValid}</div>
                    )}
                  </Col>
                </Row>
                <Space>
                  <Button onClick={() => this.doSearch()}>
                    {ButtonConst.search}
                  </Button>
                  {schange && (
                    <Button onClick={() => this.reset()}>
                      {ButtonConst.reset}
                    </Button>
                  )}
                </Space>
              </div>
            )}
            <div className="display">
              <Table
                data={ulist}
                search={search}
                showCol={disData}
                type="upsiInfo"
                tab="UPSI information record"
                deleteCol={this.removeData}
                editCol={(id) => this.redirect("/edit-upsi-information/" + id)}
                viewCol={(id) =>
                  this.redirect("/approve-upsi-info/" + window.btoa(id))
                }
              />
            </div>
            {this.timeUI()}
          </div>
        </LayOut>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.upsiInfo.loading,
  upsiInfoList: state.upsiInfo.upsiInfoList,
  userList: state.user.userList,
  loading2: state.auditLog.loading,
  auditLogList: state.auditLog.auditLogList,
  connRefList: state.connref.connRefList,
});
const mapDispatchToProps = (dispatch) => ({
  getUpsiInfoList: (payload) => dispatch(getUpsiInfoList(payload)),
  deleteUpsiInfo: (payload) => dispatch(deleteUpsiInfo(payload)),
  getUserList: (payload) => dispatch(getUserList(payload)),
  getAuditLog: (payload) => dispatch(getAuditLog(payload)),
  getConRefList: (payload) => dispatch(getConRefList(payload)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UpsiInfo)
);
