export const GET_HOLDING_LIST_INITIATED = "GET_HOLDING_LIST_INITIATED";
export const GET_HOLDING_LIST_SUCCESS = "GET_HOLDING_LIST_SUCCESS";
export const GET_HOLDING_LIST_ERROR = "GET_HOLDING_LIST_ERROR";

export const GET_HOLDING_BY_ID_INITIATED = "GET_HOLDING_BY_ID_INITIATED";
export const GET_HOLDING_BY_ID_SUCCESS = "GET_HOLDING_BY_ID_SUCCESS";
export const GET_HOLDING_BY_ID_ERROR = "GET_HOLDING_BY_ID_ERROR";

export const GET_HOLDING_BY_UID_INITIATED = "GET_HOLDING_BY_UID_INITIATED";
export const GET_HOLDING_BY_UID_SUCCESS = "GET_HOLDING_BY_UID_SUCCESS";
export const GET_HOLDING_BY_UID_ERROR = "GET_HOLDING_BY_UID_ERROR";

export const ADD_HOLDING_INITIATED = "ADD_HOLDING_INITIATED";
export const ADD_HOLDING_SUCCESS = "ADD_HOLDING_SUCCESS";
export const ADD_HOLDING_ERROR = "ADD_HOLDING_ERROR";

export const UPDATE_HOLDING_INITIATED = "UPDATE_HOLDING_INITIATED";
export const UPDATE_HOLDING_SUCCESS = "UPDATE_HOLDING_SUCCESS";
export const UPDATE_HOLDING_ERROR = "UPDATE_HOLDING_ERROR";

export const DELETE_HOLDING_INITIATED = "DELETE_HOLDING_INITIATED";
export const DELETE_HOLDING_SUCCESS = "DELETE_HOLDING_SUCCESS";
export const DELETE_HOLDING_ERROR = "DELETE_HOLDING_ERROR";

export const DELETEBYDATE_HOLDING_INITIATED = "DELETEBYDATE_HOLDING_INITIATED";
export const DELETEBYDATE_HOLDING_SUCCESS = "DELETEBYDATE_HOLDING_SUCCESS";
export const DELETEBYDATE_HOLDING_ERROR = "DELETEBYDATE_HOLDING_ERROR";