import React, { Component, Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import Login from "container/Login";
import User from "container/User";
import AddUser from "container/AddUser";
import Dashboard from "container/Dashboard";
import Master from "container/Master";
import Company from "container/Company";
import AddMaster from "container/AddMaster";
import UPSIInfo from "container/UPSIInfo";
import WindowClosure from "container/WindowClosure";
import ClearanceRequest from "container/Trading";
import BenPOS from "container/BenPOS";
import Forms from "container/Forms";
import AddTrading from "container/AddTrading";
import Disclosures from "container/Disclosures";
import AddClosure from "container/AddClosure";
import AddCompany from "container/AddCompany";
import ConnectedPersons from "container/ConnectedPersons";
import AddPerson from "container/AddPerson";
import AddDeclaration from "container/AddDeclaration";
import AddBenPOS from "container/AddBenPOS";
import AddEducation from "container/AddEducation";
import AddDisclosures from "container/AddDisclosures";
import Education from "container/Education";
import Employment from "container/Employment";
import AddEmployment from "container/AddEmployment";
import Directorment from "container/Directorment";
import AddDirector from "container/AddDirector";
import AddUPSIInfo from "container/AddUPSIInfo";
import ApproveUPSIInfo from "container/ApproveUPSIInfo";
import Holding from "container/Holding";
import AddHolding from "container/AddHolding";
import ChangePassword from "container/ChangePassword";
import AddConnectionReference from "container/AddConnectionReference";
import ConnectionReference from "container/ConnectionReference";
import Declarations from "container/Declarations";
import SMTP from "container/SMTP";
import Templates from "container/Templates";
import BenPOSList from "container/BenPOSList";
import Report from "container/Reports";
import { getAuthUserID } from "modules/helper";
import PageLoader from "components/PageLoader";
import Bank from "container/Bank";
import AddBank from "container/AddBank";
import CO from "container/CO";
import Subscription from "container/Subscription";
const routes = [
  {
    path: "/login",
    exact: true,
    AuthRoute: false,
    component: Login,
  },
  {
    path: "/subscription",
    exact: true,
    AuthRoute: false,
    component: Subscription,
  },
  {
    path: "/",
    exact: true,
    AuthRoute: true,
    component: Dashboard,
  },
  {
    path: "/change-password",
    exact: true,
    AuthRoute: true,
    component: ChangePassword,
  },
  {
    path: "/dashboard",
    exact: true,
    AuthRoute: true,
    component: Dashboard,
  },
  {
    path: "/company",
    exact: true,
    AuthRoute: true,
    component: Company,
  },
  {
    path: "/add-company",
    exact: true,
    AuthRoute: true,
    component: AddCompany,
  },
  {
    path: "/edit-company/:id",
    exact: true,
    AuthRoute: true,
    component: AddCompany,
  },
  {
    path: "/trading",
    exact: true,
    AuthRoute: true,
    component: ClearanceRequest,
  },
  {
    path: "/add-trading",
    exact: true,
    AuthRoute: true,
    component: AddTrading,
  },
  {
    path: "/edit-trading/:id",
    exact: true,
    AuthRoute: true,
    component: AddTrading,
  },
  {
    path: "/declarations",
    exact: true,
    AuthRoute: true,
    component: Declarations,
  },
  {
    path: "/add-declaration",
    exact: true,
    AuthRoute: true,
    component: AddDeclaration,
  },
  {
    path: "/edit-declaration/:id",
    exact: true,
    AuthRoute: true,
    component: AddDeclaration,
  },
  {
    path: "/disclosures",
    exact: true,
    AuthRoute: true,
    component: Disclosures,
  },
  {
    path: "/add-disclosures",
    exact: true,
    AuthRoute: true,
    component: AddDisclosures,
  },
  {
    path: "/edit-disclosures/:id",
    exact: true,
    AuthRoute: true,
    component: AddDisclosures,
  },
  {
    path: "/upsi-information",
    exact: true,
    AuthRoute: true,
    component: UPSIInfo,
  },
  {
    path: "/add-upsi-information",
    exact: true,
    AuthRoute: true,
    component: AddUPSIInfo,
  },
  {
    path: "/edit-upsi-information/:id",
    exact: true,
    AuthRoute: true,
    component: AddUPSIInfo,
  },
  {
    path: "/approve-upsi-info/:id",
    exact: true,
    AuthRoute: true,
    component: ApproveUPSIInfo,
  },
  {
    path: "/user-list",
    exact: true,
    AuthRoute: true,
    component: User,
  },
  {
    path: "/compliance-officer",
    exact: true,
    AuthRoute: true,
    component: CO,
  },
  {
    path: "/add-compliance-officer",
    exact: true,
    AuthRoute: true,
    component: AddUser,
  },
  {
    path: "/edit-compliance-officer/:id",
    exact: true,
    AuthRoute: true,
    component: AddUser,
  },
  {
    path: "/designated-person",
    exact: true,
    AuthRoute: true,
    component: User,
  },
  {
    path: "/add-designated-person",
    exact: true,
    AuthRoute: true,
    component: AddUser,
  },
  {
    path: "/edit-designated-person/:id",
    exact: true,
    AuthRoute: true,
    component: AddUser,
  },
  {
    path: "/pcs",
    exact: true,
    AuthRoute: true,
    component: User,
  },
  {
    path: "/add-pcs",
    exact: true,
    AuthRoute: true,
    component: AddUser,
  },
  {
    path: "/edit-pcs/:id",
    exact: true,
    AuthRoute: true,
    component: AddUser,
  },
  {
    path: "/add-user",
    exact: true,
    AuthRoute: true,
    component: AddUser,
  },
  {
    path: "/edit-user/:id",
    exact: true,
    AuthRoute: true,
    component: AddUser,
  },
  {
    path: "/profile",
    exact: true,
    AuthRoute: true,
    component: AddUser,
  },
  {
    path: "/add-user",
    exact: true,
    AuthRoute: true,
    component: AddUser,
  },
  {
    path: "/connected-person",
    exact: true,
    AuthRoute: true,
    component: ConnectedPersons,
  },
  {
    path: "/add-connected-person",
    exact: true,
    AuthRoute: true,
    component: AddPerson,
  },
  {
    path: "/add-connected-person-relation/:id",
    exact: true,
    AuthRoute: true,
    component: AddConnectionReference,
  },
  {
    path: "/connected-person-relation/:id",
    exact: true,
    AuthRoute: true,
    component: ConnectionReference,
  },
  {
    path: "/userprofile",
    exact: true,
    AuthRoute: true,
    component: AddPerson,
  },
  {
    path: "/edit-connected-person/:id",
    exact: true,
    AuthRoute: true,
    component: AddPerson,
  },
  {
    path: "/user-role",
    exact: true,
    AuthRoute: true,
    component: Master,
  },
  {
    path: "/add-user-role",
    exact: true,
    AuthRoute: true,
    component: AddMaster,
  },
  {
    path: "/edit-user-role/:id",
    exact: true,
    AuthRoute: true,
    component: AddMaster,
  },
  {
    path: "/user-category",
    exact: true,
    AuthRoute: true,
    component: Master,
  },
  {
    path: "/add-user-category",
    exact: true,
    AuthRoute: true,
    component: AddMaster,
  },
  {
    path: "/edit-user-category/:id",
    exact: true,
    AuthRoute: true,
    component: AddMaster,
  },
  {
    path: "/upsi-type",
    exact: true,
    AuthRoute: true,
    component: Master,
  },
  {
    path: "/add-upsi-type",
    exact: true,
    AuthRoute: true,
    component: AddMaster,
  },
  {
    path: "/edit-upsi-type/:id",
    exact: true,
    AuthRoute: true,
    component: AddMaster,
  },
  {
    path: "/reference",
    exact: true,
    AuthRoute: true,
    component: Master,
  },
  {
    path: "/add-reference",
    exact: true,
    AuthRoute: true,
    component: AddMaster,
  },
  {
    path: "/edit-reference/:id",
    exact: true,
    AuthRoute: true,
    component: AddMaster,
  },
  {
    path: "/security-type",
    exact: true,
    AuthRoute: true,
    component: Master,
  },
  {
    path: "/add-security-type",
    exact: true,
    AuthRoute: true,
    component: AddMaster,
  },
  {
    path: "/edit-security-type/:id",
    exact: true,
    AuthRoute: true,
    component: AddMaster,
  },
  {
    path: "/relationship-type",
    exact: true,
    AuthRoute: true,
    component: Master,
  },
  {
    path: "/add-relationship-type",
    exact: true,
    AuthRoute: true,
    component: AddMaster,
  },
  {
    path: "/edit-relationship-type/:id",
    exact: true,
    AuthRoute: true,
    component: AddMaster,
  },
  {
    path: "/purpose-for-upsi",
    exact: true,
    AuthRoute: true,
    component: Master,
  },
  {
    path: "/add-purpose-for-upsi",
    exact: true,
    AuthRoute: true,
    component: AddMaster,
  },
  {
    path: "/edit-purpose-for-upsi/:id",
    exact: true,
    AuthRoute: true,
    component: AddMaster,
  },
  {
    path: "/sharing-mode",
    exact: true,
    AuthRoute: true,
    component: Master,
  },
  {
    path: "/add-sharing-mode",
    exact: true,
    AuthRoute: true,
    component: AddMaster,
  },
  {
    path: "/edit-sharing-mode/:id",
    exact: true,
    AuthRoute: true,
    component: AddMaster,
  },
  {
    path: "/Window-Closure",
    exact: true,
    AuthRoute: true,
    component: WindowClosure,
  },
  {
    path: "/add-window-closure",
    exact: true,
    AuthRoute: true,
    component: AddClosure,
  },
  {
    path: "/add-window-closure/:id",
    exact: true,
    AuthRoute: true,
    component: AddClosure,
  },
  {
    path: "/edit-window-closure/:id",
    exact: true,
    AuthRoute: true,
    component: AddClosure,
  },
  {
    path: "/benpos",
    exact: true,
    AuthRoute: true,
    component: BenPOS,
  },
  {
    path: "/add-BenPOS",
    exact: true,
    AuthRoute: true,
    component: AddBenPOS,
  },
  {
    path: "/edit-BenPOS/:id",
    exact: true,
    AuthRoute: true,
    component: AddBenPOS,
  },
  {
    path: "/BenPOSList/:id",
    exact: true,
    AuthRoute: true,
    component: BenPOSList,
  },
  {
    path: "/reports",
    exact: true,
    AuthRoute: true,
    component: Report,
  },
  {
    path: "/draft-forms",
    exact: true,
    AuthRoute: true,
    component: Forms,
  },
  {
    path: "/add-draft-forms",
    exact: true,
    AuthRoute: true,
    component: Declarations,
  },
  {
    path: "/bank/:id",
    exact: true,
    AuthRoute: true,
    component: Bank,
  },
  {
    path: "/add-bank/:id",
    exact: true,
    AuthRoute: true,
    component: AddBank,
  },
  {
    path: "/edit-bank/:id",
    exact: true,
    AuthRoute: true,
    component: AddBank,
  },
  {
    path: "/education/:id",
    exact: true,
    AuthRoute: true,
    component: Education,
  },
  {
    path: "/add-education/:id",
    exact: true,
    AuthRoute: true,
    component: AddEducation,
  },
  {
    path: "/edit-education/:id",
    exact: true,
    AuthRoute: true,
    component: AddEducation,
  },
  {
    path: "/employment/:id",
    exact: true,
    AuthRoute: true,
    component: Employment,
  },
  {
    path: "/add-employment/:id",
    exact: true,
    AuthRoute: true,
    component: AddEmployment,
  },
  {
    path: "/edit-employment/:id",
    exact: true,
    AuthRoute: true,
    component: AddEmployment,
  },
  {
    path: "/directorship/:id",
    exact: true,
    AuthRoute: true,
    component: Directorment,
  },
  {
    path: "/add-directorship/:id",
    exact: true,
    AuthRoute: true,
    component: AddDirector,
  },
  {
    path: "/edit-directorship/:id",
    exact: true,
    AuthRoute: true,
    component: AddDirector,
  },
  {
    path: "/holding/:id",
    exact: true,
    AuthRoute: true,
    component: Holding,
  },
  {
    path: "/add-holding/:id",
    exact: true,
    AuthRoute: true,
    component: AddHolding,
  },
  {
    path: "/edit-holding/:id",
    exact: true,
    AuthRoute: true,
    component: AddHolding,
  },
  {
    path: "/smtp-settings",
    exact: true,
    AuthRoute: true,
    component: SMTP,
  },
  {
    path: "/templates",
    exact: true,
    AuthRoute: true,
    component: Templates,
  },
];
const PrivateRoute = ({ component: Component, ...rest }) => {
  if (getAuthUserID()) {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  } else return <Redirect to="/login" />;
};
const RestrictedRoute = ({ component: Component, publicAccess, ...rest }) => {
  if (getAuthUserID()) {
    return (
      <Route
        {...rest}
        render={(props) =>
          publicAccess ? <Component {...props} /> : <Redirect to="/" />
        }
      />
    );
  } else
    return <Route {...rest} render={(props) => <Component {...props} />} />;
};
class Routes extends Component {
  render() {
    return (
      <Suspense fallback={<PageLoader />}>
        <Switch>
          {routes.map((route, index) => {
            return !route.AuthRoute ? (
              <RestrictedRoute {...route} key={index} />
            ) : (
              <PrivateRoute {...route} key={index} />
            );
          })}
          <Route render={(props) => <h1>404 Page</h1>} />
        </Switch>
      </Suspense>
    );
  }
}
export default Routes;
