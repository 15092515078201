import React, { Component } from "react";
import Workbook from "react-excel-workbook";
import { withRouter } from "react-router-dom";
import { DownloadOutlined } from "@ant-design/icons";
import jsPDF from "jspdf";
import "jspdf-autotable";

import { getAuthUserID } from "modules/helper";
import { DevConst, ContraKeys, VariactionKeys } from "./constant";
import { Table, Select, Button } from "components/Form";

var userId = getAuthUserID();
class DR extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bList: [],
      vrList: [],
      selectID: 1,
      selectValue: "Contra Trades",
    };
  }
  async componentDidMount() {
    try {
      const { data, prList, vrList } = this.props;
      userId = userId ? userId : getAuthUserID();
      this.setState({ bList: data, pcvList: prList, vrList: vrList });
    } catch (error) {
      console.log(error);
    }
  }
  componentDidUpdate(prevProps) {
    try {
      const { data, prList, vrList } = this.props;
      if (data !== prevProps.data) this.setState({ bList: data });
      if (prList !== prevProps.prList) this.setState({ pcvList: prList });
      if (vrList !== prevProps.vrList) this.setState({ vrList: vrList });
    } catch (error) {
      console.log(error);
    }
  }
  excleUI = () => {
    try {
      const { selectValue, selectID, bList, pcvList, vrList } = this.state;
      let array = selectID === 1 ? ContraKeys : VariactionKeys;
      let data = selectID === 1 ? bList : selectID === 2 ? pcvList : vrList;
      return (
        <Workbook
          filename={selectValue + ".xlsx"}
          element={
            <Button className="exclebtn">
              <DownloadOutlined />
              {"Excel"}
            </Button>
          }
        >
          <Workbook.Sheet data={data} name={"report"}>
            {array?.map((a, i) => (
              <Workbook.Column label={a.label} value={a.value} key={i} />
            ))}
          </Workbook.Sheet>
        </Workbook>
      );
    } catch (error) {
      console.log(error);
    }
  };
  selChange = (value, data) =>
    this.setState({ selectValue: value, selectID: data.id });
  downloadPDF = () => {
    try {
      const { selectValue, selectID, bList, pcvList, vrList } = this.state;
      let array = selectID === 1 ? ContraKeys : VariactionKeys;
      let data = selectID === 1 ? bList : selectID === 2 ? pcvList : vrList;

      const unit = "pt";
      const size = "A4";
      const orientation = "portrait";
      const marginLeft = 10;
      const doc = new jsPDF(orientation, unit, size);
      doc.setFontSize(15);
      const title = selectValue;
      const headers = [
        array.map(function (obj) {
          return obj.label;
        }),
      ];
      const dataCol =
        selectID === 1
          ? data.map((elt, i) => [
              elt.key,
              elt.name,
              elt.pan,
              elt.buy,
              elt.sale,
              elt.quality,
              elt.category,
              elt.hdate,
            ])
          : data.map((elt, i) => [
              elt.key,
              elt.name,
              elt.pan,
              elt.approval,
              elt.purpose,
              elt.qty,
              elt.buy,
              elt.sale,
              elt.prevShare,
              elt.totalShare,
              elt.variation,
              elt.sDate,
              elt.eDate,
              elt.hdate,
            ]);
      let content = {
        startY: 50,
        margin: { left: 10,right:10 },
        head: headers,
        body: dataCol,
      };
      doc.text(title, marginLeft, 40);
      doc.autoTable(content);
      doc.save(title + ".pdf");
    } catch (error) {
      console.log(error);
    }
  };
  render() {
    const { selectValue, selectID, bList, pcvList, vrList } = this.state;
    return (
      <>
        <div className="head">
          <Select
            withID
            showSearch={"No"}
            data={DevConst}
            value={selectValue}
            defaultValue={selectValue}
            className="reportSelect"
            onChange={(value, data) => this.selChange(value, data)}
          />
          <div className="btnDiv">
            {this.excleUI()}
            <Button className="exclebtn" onClick={this.downloadPDF}>
              <DownloadOutlined />
              {"PDF"}
            </Button>
          </div>
        </div>
        {selectID === 1 && <Table type={"contra"} data={bList} />}
        {selectID === 2 && <Table type={"pcv"} data={pcvList} />}
        {selectID === 3 && <Table type={"vr"} data={vrList} />}
      </>
    );
  }
}
export default withRouter(DR);
