import { message } from "antd";

import { axiosAuthGet, axiosAuthPost } from "modules/Axios";
import { benpoListConst, AuditConst } from "modules/config";
import * as actions from "./constant";

export const getBenPosList = () => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_BENPO_LIST_INITIATED });
    let response = await axiosAuthGet(benpoListConst.GET_ALLBENPO_LIST);
    if (response?.success) await dispatch({ type: actions.GET_BENPO_LIST_SUCCESS, payload: response });
    else dispatch({ type: actions.GET_BENPO_LIST_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.GET_BENPO_LIST_ERROR, error: "Network Error" });
  }
};

export const getBenPoById = (id) => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_BENPO_LIST_BY_ID_INITIATED });
    let response = await axiosAuthGet(benpoListConst.GET_BENPO_LIST_BY_ID + id);
    if (response?.success) await dispatch({ type: actions.GET_BENPO_LIST_BY_ID_SUCCESS, payload: response });
    else dispatch({ type: actions.GET_BENPO_LIST_BY_ID_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.GET_BENPO_LIST_BY_ID_ERROR, error: "Network Error" });
  }
};

export const getBenPoByDay = (date) => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_BENPO_LIST_BY_DAY_INITIATED });
    let response = await axiosAuthGet(benpoListConst.GET_BENPO_LIST_BY_DAY + date);
    if (response?.success) await dispatch({ type: actions.GET_BENPO_LIST_BY_DAY_SUCCESS, payload: response });
    else dispatch({ type: actions.GET_BENPO_LIST_BY_DAY_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.GET_BENPO_LIST_BY_DAY_ERROR, error: "Network Error" });
  }
};

export const addBenPoList = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.ADD_BENPO_LIST_INITIATED });
    let response = await axiosAuthPost(benpoListConst.ADD_BENPO_LIST, payload);
    if (response?.success) {
      await axiosAuthPost(AuditConst.ADD_AUDIT, { uid: payload.uid, text: "BenPoList added" });
      await dispatch({ type: actions.ADD_BENPO_LIST_SUCCESS, payload: response });
    }
    else dispatch({ type: actions.ADD_BENPO_LIST_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.ADD_BENPO_LIST_ERROR, error: "Network Error" });
  }
};

export const addBenPodifList = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.ADD_BENPODIF_LIST_INITIATED });
    let response = await axiosAuthPost(benpoListConst.ADD_BENPODIF_LIST, payload);
    if (response?.success) {
      await axiosAuthPost(AuditConst.ADD_AUDIT, { uid: payload.uid, text: "BenPoList added" });
      await dispatch({ type: actions.ADD_BENPODIF_LIST_SUCCESS, payload: response });
    }
    else dispatch({ type: actions.ADD_BENPODIF_LIST_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.ADD_BENPODIF_LIST_ERROR, error: "Network Error" });
  }
};

export const updateBenPoList = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.UPDATE_BENPO_LIST_INITIATED });
    let response = await axiosAuthPost(benpoListConst.UPDATE_BENPO_LIST, payload);
    if (response?.success) {
      await axiosAuthPost(AuditConst.ADD_AUDIT, { uid: payload.uid, toid: payload.id, text: "BenPoList updated" });
      await dispatch({ type: actions.UPDATE_BENPO_LIST_SUCCESS, payload: response });
    }
    else dispatch({ type: actions.UPDATE_BENPO_LIST_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.UPDATE_BENPO_LIST_ERROR, error: "Network Error" });
  }
};

export const deleteBenPo = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.DELETE_BENPO_LIST_INITIATED });
    let response = await axiosAuthGet(benpoListConst.DELETE_BENPO_LIST + payload.id);
    if (response?.success) {
      await axiosAuthPost(AuditConst.ADD_AUDIT, { uid: payload.uid, toid: payload.id, text: "BenPoList delete" });
      await dispatch({ type: actions.DELETE_BENPO_LIST_SUCCESS, payload: response });
      message.success(response?.message);
    }
    else dispatch({ type: actions.DELETE_BENPO_LIST_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.DELETE_BENPO_LIST_ERROR, error: "Network Error" });
  }
};
export const deleteByDate = (date) => async (dispatch) => {
  try {
    dispatch({ type: actions.DELETE_BENPO_DATE_INITIATED });
    let response = await axiosAuthGet(benpoListConst.DELETE_BENPO_DATE + date);
    if (response?.success)
      await dispatch({ type: actions.DELETE_BENPO_DATE_SUCCESS, payload: response });
    else dispatch({ type: actions.DELETE_BENPO_DATE_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.DELETE_BENPO_DATE_ERROR, error: "Network Error" });
  }
};