export const GET_UPSIINFO_LIST_INITIATED = "GET_UPSIINFO_LIST_INITIATED";
export const GET_UPSIINFO_LIST_SUCCESS = "GET_UPSIINFO_LIST_SUCCESS";
export const GET_UPSIINFO_LIST_ERROR = "GET_UPSIINFO_LIST_ERROR";

export const GET_UPSIINFO_BY_ID_INITIATED = "GET_UPSIINFO_BY_ID_INITIATED";
export const GET_UPSIINFO_BY_ID_SUCCESS = "GET_UPSIINFO_BY_ID_SUCCESS";
export const GET_UPSIINFO_BY_ID_ERROR = "GET_UPSIINFO_BY_ID_ERROR";

export const ADD_UPSIINFO_INITIATED = "ADD_UPSIINFO_INITIATED";
export const ADD_UPSIINFO_SUCCESS = "ADD_UPSIINFO_SUCCESS";
export const ADD_UPSIINFO_ERROR = "ADD_UPSIINFO_ERROR";

export const UPDATE_UPSIINFO_INITIATED = "UPDATE_UPSIINFO_INITIATED";
export const UPDATE_UPSIINFO_SUCCESS = "UPDATE_UPSIINFO_SUCCESS";
export const UPDATE_UPSIINFO_ERROR = "UPDATE_UPSIINFO_ERROR";

export const DELETE_UPSIINFO_INITIATED = "DELETE_UPSIINFO_INITIATED";
export const DELETE_UPSIINFO_SUCCESS = "DELETE_UPSIINFO_SUCCESS";
export const DELETE_UPSIINFO_ERROR = "DELETE_UPSIINFO_ERROR";