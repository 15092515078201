const PageConst = {
  title: "Connection Reference",
  pct: "Relationship with DP",
  dp:"DP Name*",
  ref:"Reference*",
  rel:"Relation",
  yes: "Yes",
  no: "No",
};
export { PageConst };
