const PageConst = {
  ad: "Add User",
  ediu: "Edit User",
  pf: "Profile",
  ve: "View Education",
  vme: "View Employment",
  vd: "View Directorship",
  vb: "View Bank",
  vh: "View Holding",
  ubd: "User Basic Detail",
  edu: "Education Details",
  emp: "Employment Details",
  kyc: "KYC Details",
  bd: "Bank Details",
  pd: "Personal Detail",
  name: "First Name*",
  userId: "User ID",
  surname: "Last Name*",
  fatherName: "Middle Name",
  email: "Email ID*",
  userType: "User Type*",
  category: "Category*",
  mobile: "Mobile No.",
  pan: "PAN*",
  aadhar: "Aadhar No.",
  gender: "Gender*",
  nation: "Nationality*",
  driving: "Driving License",
  country: "Country",
  state: "State*",
  city: "City*",
  pin: "Pin Code*",
  passport: "Passport No.",
  address: "Address*",
  bank: "Bank Name*",
  branch: "Branch Name*",
  account: "Account No*",
  ifsc: "IFSC Code*",
  din: "DIN",
  dpid: "DP ID",
  role: "Role",
  client: "Client ID",
  appiotment: "Date of Appointment",
  num: "Sr. No.",
  eduQ: "Education Qualification*",
  uniName: "University Name",
  QuaName: "Qualification Name",
  date: "Date of Enrollment",
  year: "Year of Passing*",
  grade: "Grade",
  direct: "Directorship",
  cname: "Name of Company",
  joinDate: "Date of Joining",
  exitDate: "Date of Exit",
  ae: "Add Education",
  un: "User Name*",
  password: "Password",
  ed: "Education Data",
  emd: "Employment Data",
  dd: "Directorship Data",
  em: "Employment Details",
  dm: "Directorship Details",
  cn: "Company Name*",
  des: "Designation",
  jd: "Joining Date*",
  exd: "Exit Date*",
  cp: "Connected Person List",
  bsd: "Basic Detail",
  ld: "Location Detail",
  dtl: "Detail",
  co: "Compliance Officer",
  panErr: "PAN number is already used",
};
const genderList = [
  { id: 1, value: "Male" },
  { id: 2, value: "Female" },
  { id: 3, value: "Others" },
];
export { PageConst, genderList };
