export const GET_EDUCATION_LIST_INITIATED = "GET_EDUCATION_LIST_INITIATED";
export const GET_EDUCATION_LIST_SUCCESS = "GET_EDUCATION_LIST_SUCCESS";
export const GET_EDUCATION_LIST_ERROR = "GET_EDUCATION_LIST_ERROR";

export const GET_EDUCATION_BY_ID_INITIATED = "GET_EDUCATION_BY_ID_INITIATED";
export const GET_EDUCATION_BY_ID_SUCCESS = "GET_EDUCATION_BY_ID_SUCCESS";
export const GET_EDUCATION_BY_ID_ERROR = "GET_EDUCATION_BY_ID_ERROR";

export const ADD_EDUCATION_INITIATED = "ADD_EDUCATION_INITIATED";
export const ADD_EDUCATION_SUCCESS = "ADD_EDUCATION_SUCCESS";
export const ADD_EDUCATION_ERROR = "ADD_EDUCATION_ERROR";

export const UPDATE_EDUCATION_INITIATED = "UPDATE_EDUCATION_INITIATED";
export const UPDATE_EDUCATION_SUCCESS = "UPDATE_EDUCATION_SUCCESS";
export const UPDATE_EDUCATION_ERROR = "UPDATE_EDUCATION_ERROR";

export const DELETE_EDUCATION_INITIATED = "DELETE_EDUCATION_INITIATED";
export const DELETE_EDUCATION_SUCCESS = "DELETE_EDUCATION_SUCCESS";
export const DELETE_EDUCATION_ERROR = "DELETE_EDUCATION_ERROR";