export const GET_BENPO_INITIATED = "GET_BENPO_INITIATED";
export const GET_BENPO_SUCCESS = "GET_BENPO_SUCCESS";
export const GET_BENPO_ERROR = "GET_BENPO_ERROR";

export const GET_BENPO_BY_ID_INITIATED = "GET_BENPO_BY_ID_INITIATED";
export const GET_BENPO_BY_ID_SUCCESS = "GET_BENPO_BY_ID_SUCCESS";
export const GET_BENPO_BY_ID_ERROR = "GET_BENPO_BY_ID_ERROR";

export const GET_BENPO_BY_UID_INITIATED = "GET_BENPO_BY_UID_INITIATED";
export const GET_BENPO_BY_UID_SUCCESS = "GET_BENPO_BY_UID_SUCCESS";
export const GET_BENPO_BY_UID_ERROR = "GET_BENPO_BY_UID_ERROR";

export const ADD_BENPO_INITIATED = "ADD_BENPO_INITIATED";
export const ADD_BENPO_SUCCESS = "ADD_BENPO_SUCCESS";
export const ADD_BENPO_ERROR = "ADD_BENPO_ERROR";

export const UPDATE_BENPO_INITIATED = "UPDATE_BENPO_INITIATED";
export const UPDATE_BENPO_SUCCESS = "UPDATE_BENPO_SUCCESS";
export const UPDATE_BENPO_ERROR = "UPDATE_BENPO_ERROR";

export const DELETE_BENPO_INITIATED = "DELETE_BENPO_INITIATED";
export const DELETE_BENPO_SUCCESS = "DELETE_BENPO_SUCCESS";
export const DELETE_BENPO_ERROR = "DELETE_BENPO_ERROR";