export const GET_DISCLOSURE_LIST_INITIATED = "GET_DISCLOSURE_LIST_INITIATED";
export const GET_DISCLOSURE_LIST_SUCCESS = "GET_DISCLOSURE_LIST_SUCCESS";
export const GET_DISCLOSURE_LIST_ERROR = "GET_DISCLOSURE_LIST_ERROR";

export const GET_DISCLOSURE_BY_ID_INITIATED = "GET_DISCLOSURE_BY_ID_INITIATED";
export const GET_DISCLOSURE_BY_ID_SUCCESS = "GET_DISCLOSURE_BY_ID_SUCCESS";
export const GET_DISCLOSURE_BY_ID_ERROR = "GET_DISCLOSURE_BY_ID_ERROR";

export const ADD_DISCLOSURE_INITIATED = "ADD_DISCLOSURE_INITIATED";
export const ADD_DISCLOSURE_SUCCESS = "ADD_DISCLOSURE_SUCCESS";
export const ADD_DISCLOSURE_ERROR = "ADD_DISCLOSURE_ERROR";

export const UPDATE_DISCLOSURE_INITIATED = "UPDATE_DISCLOSURE_INITIATED";
export const UPDATE_DISCLOSURE_SUCCESS = "UPDATE_DISCLOSURE_SUCCESS";
export const UPDATE_DISCLOSURE_ERROR = "UPDATE_DISCLOSURE_ERROR";

export const DELETE_DISCLOSURE_INITIATED = "DELETE_DISCLOSURE_INITIATED";
export const DELETE_DISCLOSURE_SUCCESS = "DELETE_DISCLOSURE_SUCCESS";
export const DELETE_DISCLOSURE_ERROR = "DELETE_DISCLOSURE_ERROR";