import React, { Component } from "react";
import { Spin } from "antd";
import { connect } from "react-redux";
import Workbook from "react-excel-workbook";
import { withRouter } from "react-router-dom";
import { DownloadOutlined } from "@ant-design/icons";

import { appConst, ButtonConst } from "App/AppConstant";
import { getAuthRole, getAuthUserID } from "modules/helper";
import { getBenPoByDay, deleteBenPo } from "redux/benpolist/actions";
import { Button, Table, LayOut, Input, MultiSelect } from "components/Form";
var role = getAuthRole();
var userId = getAuthUserID();
class BenPOSList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blist: [],
      disData: [],
      search: "",
      id: 0,
      hold: false,
      children: ["Name", "DPID", "ClientID", "Phone", "Pan", "Depository Type", "Category", "Total Share", "Total Shares Pledged", "Total Shares under Lien", "Address1", "Address2", "Address3", "Address4", "Pincode", "Email"],
      holdCh: ["Unit Code", "Sec Code", "DPID", "HolderFol", "Holder", "Second", "Third", "FH Name", "Address", "Address1", "Address2", "Address3", "Holder Pin", "Total Shares", "Hold Minor", "Type", "Pan NO", "Phone", "Fax", "Email"],
    };
  }
  async componentDidMount() {
    try {
      const { match } = this.props;
      role = role ? role : getAuthRole();
      userId = userId ? userId : getAuthUserID();
      this.setState({ disData: this.state.children, id: match.params.id });
      await this.props.getBenPoByDay(match.params.id);
      let blist = localStorage.blist && JSON.parse(localStorage.blist);
      if (blist) {
        this.setState({ disData: [] });
        let inx = blist.findIndex((a) => a.uid === userId);
        if (inx > -1) this.setState({ disData: blist[inx].disData });
      }
    } catch (error) {
      console.log(error);
    }
  }
  componentDidUpdate(prevProps) {
    try {
      const { benpoDateData } = this.props;
      if (benpoDateData !== prevProps.benpoDateData) {
        const { children, holdCh } = this.state;
        let list = benpoDateData?.filter((a) => a.is_deleted === 0);
        this.setState({
          blist: list,
          hold: list[0]?.holder,
          disData: list[0]?.holder ? holdCh : children,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
  removeData = async (id, all) => {
    try {
      if (role === 66) {
        if (!all) await this.props.deleteBenPo({ id: id, uid: userId });
        else this.removeAllData(id);
      }
      await this.props.getBenPoByDay(this.state.id);
    } catch (error) {
      console.log(error);
    }
  };
  excleUI = () => {
    try {
      return (
        <Workbook
          filename={this.state.id + ".xlsx"}
          element={
            <Button className="exclebtn">
              <DownloadOutlined />
            </Button>
          }
        >
          {this.inside()}
        </Workbook>
      );
    } catch (error) {
      console.log(error);
    }
  };
  inside = () => {
    try {
      const { blist, hold, id } = this.state;
      if (!hold)
        return (
          <Workbook.Sheet data={blist} name={id}>
            <Workbook.Column label={"Sr.No"} value="key" />
            <Workbook.Column label={"DPID"} value={"dpid"} />
            <Workbook.Column label={"Client ID"} value={"clientID"} />
            <Workbook.Column label={"Shareholder Name"} value={"name"} />
            <Workbook.Column
              label={"Shareholder Address (1)"}
              value={"address1"}
            />
            <Workbook.Column
              label={"Shareholder Address (2)"}
              value={"address2"}
            />
            <Workbook.Column
              label={"Shareholder Address (3)"}
              value={"address3"}
            />
            <Workbook.Column
              label={"Shareholder Address (4)"}
              value={"address4"}
            />
            <Workbook.Column label={"PIN Code"} value={"pinCode"} />
            <Workbook.Column label={"Total Share"} value={"totalShare"} />
            <Workbook.Column
              label={"Total Shares Pledged"}
              value={"totalSharesPledged"}
            />
            <Workbook.Column
              label={"Total Shares under Lien"}
              value={"totalSharesunderLien"}
            />
            <Workbook.Column label={"Category"} value={"category"} />
            <Workbook.Column
              label={"Depository Type"}
              value={"depositoryType"}
            />
            <Workbook.Column label={"PAN No."} value={"pan"} />
            <Workbook.Column label={"PHONE"} value={"phone"} />
            <Workbook.Column label={"EMAIL_ID"} value={"email"} />
          </Workbook.Sheet>
        );
      else
        return (
          <Workbook.Sheet data={blist} name={id}>
            <Workbook.Column label={"UNIT_CODE"} value={"unitCode"} />
            <Workbook.Column label={"SEC_CODE"} value={"secCode"} />
            <Workbook.Column label={"DPID"} value={"hdpid"} />
            <Workbook.Column label={"HOLDER_FOL"} value={"holderFol"} />
            <Workbook.Column label={"HOLDER"} value={"holder"} />
            <Workbook.Column label={"SECOND"} value={"second"} />
            <Workbook.Column label={"THIRD"} value={"third"} />
            <Workbook.Column label={"FH_NAME"} value={"fhName"} />
            <Workbook.Column label={"HOLDER_ADD"} value={"haddress"} />
            <Workbook.Column label={"HOLDER_AD1"} value={"haddress1"} />
            <Workbook.Column label={"HOLDER_AD2"} value={"haddress2"} />
            <Workbook.Column label={"HOLDER_AD3"} value={"haddress3"} />
            <Workbook.Column label={"HOLDER_PIN"} value={"haddress4"} />
            <Workbook.Column label={"TOTAL_SHAR"} value={"totalShar"} />
            <Workbook.Column label={"HOLD_MINOR"} value={"holdMinor"} />
            <Workbook.Column label={"TYPE"} value={"type"} />
            <Workbook.Column label={"PAN_NO"} value={"panNo"} />
            <Workbook.Column label={"PHONE"} value={"hphone"} />
            <Workbook.Column label={"FAX"} value={"fax"} />
            <Workbook.Column label={"EMAIL_ID"} value={"hemail"} />
          </Workbook.Sheet>
        );
    } catch (error) {
      console.log(error);
    }
  };
  removeAllData = async (row) => {
    try {
      const { id } = this.state;
      row.forEach(
        async (a) => await this.props.deleteBenPo({ id: a, uid: userId })
      );
      await this.props.getBenPoByDay(id);
    } catch (error) {
      console.log(error);
    }
  };
  selectUI = () => {
    try {
      const { children, disData } = this.state;
      return (
        <MultiSelect
          defaultValue={disData}
          onChange={this.handleChange}
          data={children}
        />
      );
    } catch (error) {
      console.log(error);
    }
  };
  selectUI2 = () => {
    try {
      const { holdCh, disData } = this.state;
      return (
        <MultiSelect
          defaultValue={disData}
          onChange={this.handleChange}
          data={holdCh}
        />
      );
    } catch (error) {
      console.log(error);
    }
  };
  changetxt = (e) => this.setState({ search: e.target.value });
  handleChange = (e) => {
    try {
      this.setState({ disData: e });
      let blist = localStorage.blist && JSON.parse(localStorage.blist);
      if (blist) {
        let inx = blist.findIndex((a) => a.uid === userId);
        if (inx > -1) {
          blist[inx] = { uid: userId, disData: e };
        } else blist.push({ uid: userId, disData: e });
        localStorage.blist = JSON.stringify(blist);
      } else localStorage.blist = JSON.stringify([{ uid: userId, disData: e }]);
    } catch (error) {
      console.log(error);
    }
  };
  render() {
    const { blist, search, disData, hold } = this.state;
    const { loading } = this.props;
    return (
      <Spin size="large" spinning={loading}>
        <LayOut>
          <div className="main anime">
            <div className="head todiv">
              <h4 className="title">{appConst.benl}</h4>
              <div className="btnDiv">
                <Input
                  placeholder={appConst.src}
                  handleChange={this.changetxt}
                />
                {!hold && disData.length === 0 && this.selectUI()}
                {!hold && disData.length > 0 && this.selectUI()}
                {hold && disData.length > 0 && this.selectUI2()}
                {hold && disData.length === 0 && this.selectUI2()}
                {blist.length > 0 && this.excleUI()}
                <Button onClick={() => this.props.history.goBack()}>
                  {ButtonConst.back}
                </Button>
              </div>
            </div>
            <div className="display benDiv">
              <Table
                data={blist}
                type="benpoList"
                tab={!hold ? "benpos" : "holders"}
                search={search}
                showCol={disData}
                deleteCol={this.removeData} // deleteAllRow={this.removeAllData}
              />
            </div>
          </div>
        </LayOut>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.benpolist.loading,
  benpoDateData: state.benpolist.benpoDateData,
});
const mapDispatchToProps = (dispatch) => ({
  getBenPoByDay: (payload) => dispatch(getBenPoByDay(payload)),
  deleteBenPo: (payload) => dispatch(deleteBenPo(payload)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BenPOSList)
);
