import { size } from "App/device";
import { Theme } from "App/theme";
import styled from "styled-components";

const LoginStyle = styled.div`
  display: flex;
  height: 100vh;
  align-items: center;
  @media ${size["tablet-max"]} {
    align-items: flex-start;
    padding-top: 3em;
  }
  background-color: ${Theme.mainColor};
  .logo {
    width: 559.19px;
    height: 112px;
  }
  .coffee {
    margin: 0 65%;
    @media ${size["tablet-md-max"]} {
      margin: 0 20%;
    }
    @media ${size["tablet-max"]} {
      margin: 0;
      display: none;
    }
  }
  .box {
    max-height: 32em;
    max-width: 420px;
    min-width: 310px;
    width: 38%;
    margin-left: 35%;
    background: #fff;
    border-radius: 10px !important;
    padding: 20px 60px;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.15);
    text-align: center;
    @media ${size["tablet-md-max"]} {
      margin-left: 25%;
      width: 50%;
      padding: 20px;
    }
    @media ${size["tablet-max"]} {
      margin-left: 0;
      position: relative;
      transform: translateX(50%);
    }
    @media ${size["tablet-sm-max"]} {
      min-width: 85%;
      transform: translateX(10%);
    }
    label {
      text-align: left;
    }
    img {
      margin-top: 1em;
      max-height: 95px;
      margin-bottom: 11px;
    }
    .head {
      font-weight: bold;
      font-size: 30px;
    }
    .forgot {
      text-align: end;
      font-size: 12px;
    }
    .btntDiv {
      margin-top: 2rem;
    }
  }
`;
export { LoginStyle };
