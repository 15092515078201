import styled from "styled-components";
import { arrowDown } from "components/Images";

const SelectContainer = styled.div`
  width: 100%;
  .ant-select {
    width: inherit;
    min-width: 16em;
    height: 2.5em;
    overflow-x: auto;
    border-radius: 6px;
    border: 1px solid #e4e4e4;
    .ant-select-selector {
      background: #fafafa;
      height: 2.36em;
      border-radius: 5px;
      padding-right: 8px;
      :after {
        background: url(${arrowDown}) no-repeat center;
      }
    }
  }
  &.empty .ant-select {
    border: 1px solid #e81c1c;
    box-shadow: red 0 0 10px !important;
  }
`;
export { SelectContainer };
