const PageConst = {
  add:"Add ",
  edit:"Edit ",
  val:" value*",
  type:"Type*",
  addMessage: "Are you sure you want to add new ",

};
const glist = [
  { id: 1, value: "User" },
  { id: 2, value: "Admin" },
];
export { PageConst, glist };
