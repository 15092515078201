// import { message } from "antd";
import { push } from "connected-react-router";

import { axiosAuthGet, axiosAuthPost } from "modules/Axios";
import { userConst, AuditConst } from "modules/config";
import * as actions from "./constant";
import { addConRef } from "redux/connRef/actions"
import { getAuthUserID } from "modules/helper";
var userId = getAuthUserID();
userId = userId ? userId : getAuthUserID();
export const getUserList = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_USERS_INITIATED });
    let response = await axiosAuthGet(!payload ? userConst.GET_USERS : userConst.GET_USER_ALL);
    if (response?.success)
      await dispatch({ type: actions.GET_USERS_SUCCESS, payload: response });
    else dispatch({ type: actions.GET_USERS_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.GET_USERS_ERROR, error: "Network Error" });
  }
};
export const getUserById = (id) => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_USER_BY_ID_INITIATED });
    let response = await axiosAuthGet(userConst.GET_USER_BY_ID + id);
    if (response?.success)
      await dispatch({ type: actions.GET_USER_BY_ID_SUCCESS, payload: response });
    else dispatch({ type: actions.GET_USER_BY_ID_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.GET_USER_BY_ID_ERROR, error: "Network Error" });
  }
};
export const getUserByDp = (id) => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_USER_BY_DP_INITIATED });
    let response = await axiosAuthGet(userConst.GET_USER_BY_DP + id);
    if (response?.success)
      await dispatch({ type: actions.GET_USER_BY_DP_SUCCESS, payload: response });
    else dispatch({ type: actions.GET_USER_BY_DP_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.GET_USER_BY_DP_ERROR, error: "Network Error" });
  }
};
export const addUser = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.ADD_USER_INITIATED });
    let response = await axiosAuthPost(userConst.ADD_USER, payload);
    if (response?.success) {
      await axiosAuthPost(AuditConst.ADD_AUDIT, { uid: payload.cid, text: "User added" });
      if (payload.uid === 69) {
        let postData = {
          uId: response.data.insertId,
          dpRelationship: 1,
          dpId: payload.cid,
          dpName: payload.dpName,
          cid: payload.cid,
          relation: "Other",
        };
        await dispatch(addConRef(postData));
      }
      await dispatch({ type: actions.ADD_USER_SUCCESS, payload: response });
    } else dispatch({ type: actions.ADD_USER_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.ADD_USER_ERROR, error: "Network Error" });
  }
};
export const updateUser = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.UPDATE_USER_INITIATED });
    let response = await axiosAuthPost(userConst.UPDATE_USER + payload.id, payload);
    if (response?.success) {
      userId = userId ? userId : getAuthUserID();
      await axiosAuthPost(AuditConst.ADD_AUDIT, { uid: userId, toid: payload.id, text: "User updated" });
      await dispatch({ type: actions.UPDATE_USER_SUCCESS, payload: response });
    } else dispatch({ type: actions.UPDATE_USER_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.UPDATE_USER_ERROR, error: "Network Error" });
  }
};
export const updatePassword = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.UPDATE_PWD_USER_INITIATED });
    let response = await axiosAuthPost(userConst.UPDATE_PWD_USER + payload.id, payload);
    if (response?.success) {
      userId = userId ? userId : getAuthUserID();
      await axiosAuthPost(AuditConst.ADD_AUDIT, { uid: userId, toid: payload.id, text: "User updated" });
      await dispatch({ type: actions.UPDATE_PWD_USER_SUCCESS, payload: response });
      dispatch(push("/"));
    } else dispatch({ type: actions.UPDATE_PWD_USER_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.UPDATE_PWD_USER_ERROR, error: "Network Error" });
  }
};
export const deleteUser = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.DELETE_USER_INITIATED });
    let response = await axiosAuthPost(userConst.DELETE_USER + payload.id, payload);
    if (response?.success) {
      await axiosAuthPost(AuditConst.ADD_AUDIT, { uid: payload.uid, toid: payload.id, text: "User deleted" });
      await dispatch({ type: actions.DELETE_USER_SUCCESS, payload: response });
    } else dispatch({ type: actions.DELETE_USER_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.DELETE_USER_ERROR, error: "Network Error" });
  }
};
