import React, { Component } from "react";
import { Tabs } from "antd";

import { TabsStyle } from "./style";

class TabsUI extends Component {
  render() {
    const { callback, children } = this.props;
    return (
      <TabsStyle>
        <Tabs defaultActiveKey="1" onChange={callback} type="card">
          {children}
        </Tabs>
      </TabsStyle>
    );
  }
}
export default TabsUI;
