export const GET_COMPANY_LIST_INITIATED = "GET_COMPANY_LIST_INITIATED";
export const GET_COMPANY_LIST_SUCCESS = "GET_COMPANY_LIST_SUCCESS";
export const GET_COMPANY_LIST_ERROR = "GET_COMPANY_LIST_ERROR";

export const GET_COMPANY_BY_ID_INITIATED = "GET_COMPANY_BY_ID_INITIATED";
export const GET_COMPANY_BY_ID_SUCCESS = "GET_COMPANY_BY_ID_SUCCESS";
export const GET_COMPANY_BY_ID_ERROR = "GET_COMPANY_BY_ID_ERROR";

export const ADD_COMPANY_INITIATED = "ADD_COMPANY_INITIATED";
export const ADD_COMPANY_SUCCESS = "ADD_COMPANY_SUCCESS";
export const ADD_COMPANY_ERROR = "ADD_COMPANY_ERROR";

export const UPDATE_COMPANY_INITIATED = "UPDATE_COMPANY_INITIATED";
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";
export const UPDATE_COMPANY_ERROR = "UPDATE_COMPANY_ERROR";

export const DELETE_COMPANY_INITIATED = "DELETE_COMPANY_INITIATED";
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS";
export const DELETE_COMPANY_ERROR = "DELETE_COMPANY_ERROR";