import * as actions from "./constant";
const initialState = {
  error: false,
  message: false,
  loading: false,
  isSaved: false,
  isDeleted: false,// isEdited: false,
  connRefData: {},
  connRefList: [],
  connRefuData: [],
  connRefdpData: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_CONNREFERENCE_LIST_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
      };
    case actions.GET_CONNREFERENCE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        connRefList: action.payload.data,
      };
    case actions.GET_CONNREFERENCE_LIST_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
      };
    case actions.GET_CONNREFERENCE_BY_ID_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
      };
    case actions.GET_CONNREFERENCE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        connRefData: action.payload.data,
      };
    case actions.GET_CONNREFERENCE_BY_ID_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
      };
    case actions.GET_CONNREFERENCE_BY_UID_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
      };
    case actions.GET_CONNREFERENCE_BY_UID_SUCCESS:
      return {
        ...state,
        loading: false,
        connRefuData: action.payload.data,
      };
    case actions.GET_CONNREFERENCE_BY_UID_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
      };
    case actions.GET_CONNREFERENCE_BY_DPID_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
      };
    case actions.GET_CONNREFERENCE_BY_DPID_SUCCESS:
      return {
        ...state,
        loading: false,
        connRefdpData: action.payload.data,
      };
    case actions.GET_CONNREFERENCE_BY_DPID_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
      };
    case actions.ADD_CONNREFERENCE_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
        isSaved: false,
      };
    case actions.ADD_CONNREFERENCE_SUCCESS:
      return {
        ...state,
        loading: false,
        isSaved: true,
      };
    case actions.ADD_CONNREFERENCE_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
        isSaved: false,
      };
    case actions.UPDATE_CONNREFERENCE_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
        isSaved: false,
      };
    case actions.UPDATE_CONNREFERENCE_SUCCESS:
      return {
        ...state,
        loading: false,
        isSaved: true,
      };
    case actions.UPDATE_CONNREFERENCE_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
        isSaved: false,
      };
    case actions.DELETE_CONNREFERENCE_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
        isDeleted: false,
      };
    case actions.DELETE_CONNREFERENCE_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: true,
      };
    case actions.DELETE_CONNREFERENCE_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: false,
        isDeleted: false,
      };
    default:
      return state;
  }
};
