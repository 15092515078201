import React, { Component } from "react";
import { Spin, message } from "antd";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { ChangePWDStyle } from "./style";
import { PageConst } from "./constant";
import { LayOut } from "components/Form";
import { Button, Input } from "components/Form";
import { FormValid, ButtonConst } from "App/AppConstant";
import { updatePassword, getUserById } from "redux/user/actions";
import { getAuthUserID } from "modules/helper";
var userId = getAuthUserID();
const validationSchema = Yup.object().shape({
  curPwd: Yup.string().min(4, FormValid.passwordMin).required(" "),
  // .matches(pwdMatch, FormValid.passwordInvalid),
  password: Yup.string().min(4, FormValid.passwordMin).required(" "),
  // .matches(pwdMatch, FormValid.passwordInvalid),
  conPwd: Yup.string()
    .min(4, FormValid.passwordMin)
    .required(" ")
    .when("password", {
      is: (val) => val && val.length > 0,
      then: Yup.string().oneOf([Yup.ref("password")], FormValid.repatePWD),
    }),
  // .matches(pwdMatch, FormValid.passwordInvalid),
});

class ChangePWD extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disable: false,
      initialState: { curPwd: "", password: "", conPwd: "" },
    };
  }
  async componentDidMount() {
    userId = userId ? userId : getAuthUserID();
    await this.props.getUserById(userId);
  }
  handleSubmit = async (value, { setSubmitting }) => {
    try {
      this.setState({ disable: true });
      setTimeout(() => this.setState({ disable: false }), 5000);
      const { userData } = this.props;
      value.oldpwd = userData.password;
      value.id = userData.id;
      if (value.curPwd === value.password) message.warning(PageConst.samepwd);
      else await this.props.updatePassword(value);
      setSubmitting(false);
    } catch (error) {
      console.log(error);
    }
  };
  render() {
    const { initialState, disable } = this.state;
    const { loading } = this.props;
    return (
      <Spin spinning={loading} size="large">
        <LayOut link={"add-user"}>
          <ChangePWDStyle className="main anime">
            <div className="head">
              <h4 className="title">{PageConst.cp}</h4>
            </div>
            <div className="display">
              <Formik
                initialValues={initialState}
                validationSchema={validationSchema}
                onSubmit={this.handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  onBlur,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="form_div anime">
                      <div className="field anime">
                        <Input
                          password
                          placeholder={PageConst.curPwd}
                          onBlur={handleBlur}
                          name="curPwd"
                          value={values.curPwd}
                          handleChange={handleChange}
                          tabIndex="1"
                          className={
                            errors.curPwd && touched.curPwd ? "empty" : ""
                          }
                        />
                        {errors.curPwd && touched.curPwd ? (
                          <div className="form-error">{errors.curPwd}</div>
                        ) : null}
                      </div>
                      <div className="field anime">
                        <Input
                          placeholder={PageConst.newPwd}
                          onBlur={handleBlur}
                          name="password"
                          password
                          value={values.password}
                          handleChange={handleChange}
                          tabIndex="2"
                          className={
                            errors.password && touched.password ? "empty" : ""
                          }
                        />
                        {errors.password && touched.password ? (
                          <div className="form-error">{errors.password}</div>
                        ) : null}
                      </div>
                      <div className="field anime">
                        <Input
                          password
                          placeholder={PageConst.cunfNewPwd}
                          onBlur={handleBlur}
                          autoComplete="new-password"
                          name="conPwd"
                          value={values.conPwd}
                          handleChange={handleChange}
                          tabIndex="3"
                          className={
                            errors.conPwd && touched.conPwd ? "empty" : ""
                          }
                        />
                        {errors.conPwd && touched.conPwd ? (
                          <div className="form-error">{errors.conPwd}</div>
                        ) : null}
                      </div>
                      <div className="btnDiv">
                        <Button htmlType="submit" disabled={disable}>
                          {ButtonConst.save}
                        </Button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </ChangePWDStyle>
        </LayOut>
      </Spin>
    );
  }
}
const mapStateToProps = (state) => ({
  loading: state.user.loading,
  userData: state.user.userData,
});
const mapDispatchToProps = (dispatch) => ({
  updatePassword: (payload) => dispatch(updatePassword(payload)),
  getUserById: (payload) => dispatch(getUserById(payload)),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ChangePWD)
);
