import React, { Component } from "react";
import * as Yup from "yup";
import { Space, Spin } from "antd";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import { withRouter } from "react-router-dom";
// import { CloseCircleFilled } from "@ant-design/icons";

import { PageConst } from "./constant";
import { ButtonConst } from "App/AppConstant";
import { sendmail } from "redux/smtp/actions";
import { getUserList } from "redux/user/actions";
import { getConRefList } from "redux/connRef/actions";
import { getMasterList } from "redux/master/actions";
import { addUpsiInfo, getUpsiInfoById } from "redux/upsiInfo/actions";
import { getTemplateInfoList } from "redux/templates/actions";
// import {
//   getUserById,
// } from "redux/user/actions";
import {
  Label,
  Input,
  Button,
  Row,
  Col,
  Select,
  LayOut,
  FileUpload,
  DateTimePicker,
  MultiSelect,
} from "components/Form";
import { getAuthUserID, getAuthName, getAuthRole } from "modules/helper";
var userId = getAuthUserID();
var userName = getAuthName();
var role = getAuthRole();
const ValidationSchema = Yup.object().shape({
  type: Yup.string().trim().nullable().required(" "),
  purpose: Yup.string().trim().nullable().required(" "),
  mode: Yup.string().trim().nullable().required(" "),
  sdate: Yup.date().nullable().required(" "),
  // created_date: Yup.string().trim().nullable().required(" "),
  // receiver: Yup.string().trim().nullable().required(" "),
});

class UPSIInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      serror: false,
      disable: false,
      serror1: false,
      docByte: "",
      docnm: "",
      rids: [],
      ulist: [],
      dpData: [],
      cpData: [],
      disData: [],
      conlist: [],
      disData1: [],
      closerMail: [],
      init: {
        sdate: "", // created_date: "",// dpId: 0, dpName: "",
        type: "",
        receiver: [],
        receiver2: [],
        receiverId: [],
        receiver2Id: [],
        purpose: "",
        mode: "",
        description: "",
      },
    };
  }
  async componentDidMount() {
    try {
      const { match } = this.props;
      userId = userId ? userId : getAuthUserID();
      userName = userName ? userName : getAuthName();
      role = role ? role : getAuthRole();
      await this.props.getConRefList(userId);
      await this.props.getMasterList();
      await this.props.getUserList();
      if (match?.path?.toLowerCase().includes("edit-upsi")) {
        await this.props.getUpsiInfoById(window.atob(match?.params?.id));
        this.setState({ edit: true });
      }
      await this.props.getTemplateInfoList();
    } catch (error) {
      console.log(error);
    }
  }
  componentDidUpdate(prevProps) {
    try {
      const { upsiInfoData, userList, isSaved, connRefList, templateList } =
        this.props;
      if (isSaved !== prevProps.isSaved)
        if (isSaved) this.props.history.push("/upsi-information");
      if (connRefList !== prevProps.connRefList) {
        let list = [userId];
        connRefList?.filter(
          (c) => c.dpId === userId && c.is_deleted === 0 && list.push(c.uId)
        );
        this.setState({ conlist: list });
      }
      if (userList !== prevProps.userList) {
        let me = userList?.find((f) => f.id === userId); // const { conlist } = this.state;
        if (me) {
          let urlist = userList?.filter(
            (a) => a.is_deleted === 0 && a.uid !== 68
          ); // && !conlist.includes(a.id)
          let data = [],
            rids = [],
            dpData = [],
            cpData = [],
            nm = "";
          urlist.forEach((a) => {
            a.value = a.surname ? a.name + " " + a.surname : a.name;
            let pos =
              a.uid === 66
                ? " (CO"
                : a.uid === 67
                ? " (DP"
                : a.uid === 68
                ? " (PCS"
                : " (CP";
            if (data.includes(a.value + pos + ")")) {
              if (data.includes(a.value + pos + ") 2")) {
                if (data.includes(a.value + pos + ") 3"))
                  nm = a.value + pos + ") 4";
                else nm = a.value + pos + ") 3";
              } else nm = a.value + pos + ") 2";
            } else nm = a.value + pos + ")";
            rids.push(a.id);
            data.push(nm);
            a.uid < 68 && me.pan !== a.pan && dpData.push(nm);
            a.uid > 68 && me.pan !== a.pan && cpData.push(nm);
          });
          this.setState({ ulist: data, rids, dpData, cpData });
        }
      }
      if (upsiInfoData !== prevProps.upsiInfoData) {
        let data = {
          id: upsiInfoData?.id,
          sdate: upsiInfoData?.sdate ? new Date(upsiInfoData.sdate) : "",
          type: upsiInfoData?.type,
          receiver: upsiInfoData?.receiver?.split(","),
          receiverId: upsiInfoData?.receiverId,
          purpose: upsiInfoData?.purpose,
          mode: upsiInfoData?.mode,
          description: upsiInfoData?.description, // created_date: upsiInfoData?.created_date? new Date(upsiInfoData.created_date): "",
        };
        this.setState({
          init: data,
          disData: data.receiver,
          docnm: upsiInfoData.documentName,
        }); // docByte: upsiInfoData.document,
      }
      if (templateList !== prevProps.templateList) {
        let match = templateList?.find((a) => a.name === "UPSI Share");
        if (match) this.setState({ closerMail: match });
      }
    } catch (error) {
      console.log(error);
    }
  }
  onChange = () => this.setState({ checked: !this.state.checked });
  selectUI = (val, name, setFieldValue, error) => {
    try {
      const { masterList } = this.props;
      let data = [];
      if (name === "type" || name === "purpose" || name === "mode") {
        let nm = name === "type" ? "upsi " + name : name;
        let ml = masterList?.filter(
          (a) => a.is_deleted === 0 && a.typeName.toLowerCase().includes(nm)
        );
        ml?.forEach((v) => data.push({ id: v.id, value: v.mname }));
      }
      return (
        <Select
          withID
          name={name}
          data={data}
          value={val}
          defaultValue={val}
          className={error && val === "" ? "empty" : ""}
          onChange={async (value) => setFieldValue(name, value)}
        />
      ); // if (name === "dpName") setFieldValue("dpId", data.id);
    } catch (error) {
      console.log(error);
    }
  };
  seall = (name, setFieldValue, zero) => {
    try {
      const { ulist, rids, dpData, cpData } = this.state;
      setFieldValue(name, []);
      let list = name === "receiver" ? dpData : cpData;
      name === "receiver" && this.setState({ disData: [], serror: false });
      name !== "receiver" && this.setState({ disData1: [], serror: false });
      if (!zero) {
        setTimeout(() => {
          let rcId = [];
          setFieldValue(name, list);
          list.forEach((a) => {
            let idx = ulist.findIndex((b) => b === a);
            if (idx > -1) rcId.push(rids[idx]);
          });
          setFieldValue(name + "Id", rcId);
          name === "receiver" && this.setState({ disData: dpData });
          name !== "receiver" && this.setState({ disData1: cpData });
        }, 10);
      }
    } catch (error) {
      console.log(error);
    }
  };
  selectChange = (e) => this.setState({ disData: e, serror: false });
  selectChange1 = (e) => this.setState({ disData1: e, serror: false });
  mlSelectUI = (name, val, setFieldValue) => {
    try {
      const { ulist, serror, rids, dpData, cpData } = this.state;
      let dData = val[name]?.length === 0 ? [] : val[name];
      let list = name === "receiver" ? dpData : cpData;
      return (
        <MultiSelect
          name={name}
          defaultValue={dData}
          data={list}
          className={serror ? "empty" : ""}
          onChange={(e) => {
            setFieldValue(name, e);
            name === "receiver" && this.selectChange(e);
            name !== "receiver" && this.selectChange1(e);
            let c = [];
            e.forEach((a) => {
              let idx = ulist.findIndex((b) => b === a);
              if (idx > -1) c.push(rids[idx]);
            });
            setFieldValue(name + "Id", c);
          }}
        />
      );
    } catch (error) {
      console.log(error);
    }
  };
  seByte = (bcode, name) => this.setState({ docByte: bcode, docnm: name });
  removeFile = (setFieldValue) => setFieldValue("documentName", "");
  handleSubmit = async (values, { setSubmitting }) => {
    try {
      this.setState({ disable: true });
      setTimeout(() => this.setState({ disable: false }), 5000);
      const { docByte, docnm, disData, disData1, rids, ulist } = this.state;
      let diData = disData.concat(disData1);
      if (diData.length > 0) {
        if (docnm !== "") values.documentName = docnm; // values.document = docByte;
        values.receiver = diData.toString();
        delete values.receiver2;
        values.receiverId = values.receiverId
          .concat(values.receiver2Id)
          .toString();
        delete values.receiver2Id;
        let postData = { val: values };
        if (docnm !== "") postData.data = docByte;
        if (values.id) {
          // const { upsiInfoData } = this.props;
          // new Date(values.sdate)?.toUTCString() === new Date(upsiInfoData.sdate).toUTCString() && delete values.sdate; // new Date(values.created_date)?.toUTCString() === new Date(upsiInfoData.created_date).toUTCString() && delete values.created_date;
          // upsiInfoData.type === values.type && delete values.type;
          // upsiInfoData.receiver === values.receiver && delete values.receiver;
          // upsiInfoData.receiverId === values.receiverId && delete values.receiverId;
          // upsiInfoData.purpose === values.purpose && delete values.purpose;
          // upsiInfoData.mode === values.mode && delete values.mode;
          // upsiInfoData.description === values.description && delete values.description;
          // upsiInfoData.documentName === values.documentName && delete values.documentName;
          // postData.id = values.id;
          // delete values.id;
          // await this.props.updateUpsiInfo(postData);
        } else {
          let udx = rids.findIndex((x) => x === userId);
          if (udx > -1) values.dpName = ulist[udx];
          values.dpId = userId;
          values.uid = userId;
          await this.props.addUpsiInfo(postData);
          this.sendMail(postData);
        }
      } else this.setState({ serror: true });
      setSubmitting(false);
    } catch (error) {
      console.log(error, "handle error");
    }
  };
  sendMail = async (a) => {
    try {
      const { userList, isSaved } = this.props;
      const { closerMail } = this.state;
      let data = [];
      a.val.receiverId.split(",")?.forEach((a) => {
        let user = userList?.find((b) => b.id === parseInt(a));
        if (user) data.push(user.email);
      });
      if (closerMail?.template && data.length > 0 && isSaved) {
        let temp = closerMail.template;
        data.length === 1
          ? (temp = temp?.replace("and &lt;person1&gt;", ""))
          : (temp = temp?.replace("you and &lt;person1&gt;", a.val.receiver));
        // temp = temp?.replace("&lt;person1&gt;", a.val.receiver);
        temp = temp?.replace("&lt;UPSI date + Timestamp&gt;", a.val.sdate);
        temp = temp?.replace("&lt;Type of UPSI&gt;", a.val.type);
        temp = temp?.replace("&lt;UPSI Purpose&gt;", a.val.purpose);
        if (a.val.description.trim() === "")
          temp = temp?.replace("Below is the UPSI description", "");
        temp = temp?.replace("&lt;UPSI description&gt;", a.val.description);
        let curentUser = userList?.find((b) => b.id === userId);
        temp = temp?.replace(
          /&lt;Sender Name&gt;/gi,
          `${curentUser.name} ${
            curentUser.fatherName ? curentUser.fatherName : ""
          }  ${curentUser.surname}`
        );
        data.push(curentUser.email);
        let fileData = {
          filename: a.val.documentName,
          fileData: a.data,
        };
        let mailData = {
          // from: curentUser.email,
          // from: "anvitpatel2828@gmail.com",
          // to: ["anvit.p@naapbooks.in"],
          to: data,
          subject: closerMail.name,
          html: temp,
        };
        if (a.val.documentName) mailData.file = fileData;
        let postData = {
          uid: userId,
          mail: mailData,
          text: {
            type: closerMail.name,
            send: data,
          },
        };
        await this.props.sendmail(postData);
      }
    } catch (error) {
      console.log(error);
    }
  };
  render() {
    const { init, disable, docByte, docnm, serror } = this.state;
    const { loading, loading1 } = this.props;
    return (
      <Spin spinning={loading || loading1} size="large">
        <LayOut>
          <div className="main anime">
            <div className="head">
              <h4 className="title">{PageConst.ui}</h4>
              <Button onClick={() => this.props.history.goBack()}>
                {ButtonConst.back}
              </Button>
            </div>
            <div className="display">
              <Formik
                initialValues={init}
                validationSchema={ValidationSchema}
                onSubmit={this.handleSubmit}
                enableReinitialize
              >
                {({
                  values,
                  errors,
                  touched,
                  setFieldValue,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <Form onSubmit={handleSubmit} noValidate>
                    <Row gutter={40}>
                      {/* <Col xs={24} sm={24} md={24} lg={12} xl={8}><Label title={PageConst.ud}className={errors.created_date && touched.created_date? "empty": ""}/>
                        <DateTimePicker value={values.created_date}handleChange={handleChange("created_date")}className={errors.created_date && touched.created_date? "empty": ""}/></Col> */}
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.usd}
                          className={
                            errors.sdate && touched.sdate ? "empty" : ""
                          }
                        />
                        <DateTimePicker
                          value={values.sdate}
                          handleChange={handleChange("sdate")}
                          className={
                            errors.sdate && touched.sdate ? "empty" : ""
                          }
                        />
                        {errors.sdate && touched.sdate && (
                          <div className="form-error">{errors.sdate}</div>
                        )}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.type}
                          className={errors.type && touched.type ? "empty" : ""}
                        />
                        {values.type === "" &&
                          this.selectUI(
                            values.type,
                            "type",
                            setFieldValue,
                            errors.type && touched.type
                          )}
                        {values.type !== "" &&
                          this.selectUI(values.type, "type", setFieldValue)}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.purpose}
                          className={
                            errors.purpose && touched.purpose ? "empty" : ""
                          }
                        />
                        {values.purpose === "" &&
                          this.selectUI(
                            values.purpose,
                            "purpose",
                            setFieldValue,
                            errors.purpose && touched.purpose
                          )}
                        {values.purpose !== "" &&
                          this.selectUI(
                            values.purpose,
                            "purpose",
                            setFieldValue
                          )}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.mode}
                          className={errors.mode && touched.mode ? "empty" : ""}
                        />
                        {values.mode === "" &&
                          this.selectUI(
                            values.mode,
                            "mode",
                            setFieldValue,
                            errors.mode && touched.mode
                          )}
                        {values.mode !== "" &&
                          this.selectUI(values.mode, "mode", setFieldValue)}
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.receiver + PageConst.dp}
                          className={serror ? "empty" : ""}
                        />
                        {values.receiver?.length === 0 &&
                          this.mlSelectUI("receiver", values, setFieldValue)}
                        {values.receiver?.length > 0 &&
                          this.mlSelectUI("receiver", values, setFieldValue)}
                        <Space>
                          <Button
                            onClick={() =>
                              this.seall("receiver", setFieldValue)
                            }
                          >
                            {ButtonConst.sall}
                          </Button>
                          <Button
                            onClick={() =>
                              this.seall("receiver", setFieldValue, true)
                            }
                          >
                            {ButtonConst.dsall}
                          </Button>
                        </Space>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.receiver + PageConst.cp}
                          className={serror ? "empty" : ""}
                        />
                        {values.receiver2?.length === 0 &&
                          this.mlSelectUI("receiver2", values, setFieldValue)}
                        {values.receiver2?.length > 0 &&
                          this.mlSelectUI("receiver2", values, setFieldValue)}
                        <Space>
                          <Button
                            onClick={() =>
                              this.seall("receiver2", setFieldValue)
                            }
                          >
                            {ButtonConst.sall}
                          </Button>
                          <Button
                            onClick={() =>
                              this.seall("receiver2", setFieldValue, true)
                            }
                          >
                            {ButtonConst.dsall}
                          </Button>
                        </Space>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <Label
                          title={PageConst.desc + "/" + PageConst.comment}
                        />
                        <Input
                          row={4}
                          name="description"
                          value={values.description}
                          onBlur={handleBlur}
                          handleChange={handleChange}
                        />
                      </Col>
                      <Col>
                        <Label title={PageConst.doc} />
                        {/* {values.documentName ? (<span className="optionui"><span className="txtWrap">{values.documentName}</span>
                            <CloseCircleFilled onClick={() => this.removeFile(setFieldValue)}/></span>) : (<input type="file" id="myFile" name="filename" />)} */}
                        <FileUpload
                          nm={PageConst.up + PageConst.doc}
                          accept=".jpg, .jpeg, .png, .pdf, .docx, .xlsx, .xlsm, .xlsb,"
                          image
                          file={docByte}
                          name={docnm}
                          sendByte={this.seByte}
                        />
                      </Col>
                    </Row>
                    <div className="btnDiv anime">
                      <Button disabled={disable} htmlType={"submit"}>
                        {ButtonConst.save}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </LayOut>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.master.loading,
  isSaved: state.upsiInfo.isSaved,
  masterList: state.master.masterList,
  loading1: state.upsiInfo.loading,
  userList: state.user.userList,
  connRefList: state.connref.connRefList,
  upsiInfoData: state.upsiInfo.upsiInfoData,
  templateList: state.template.templateList,
});
const mapDispatchToProps = (dispatch) => ({
  sendmail: (payload) => dispatch(sendmail(payload)),
  addUpsiInfo: (payload) => dispatch(addUpsiInfo(payload)),
  getUserList: (payload) => dispatch(getUserList(payload)),
  getMasterList: (payload) => dispatch(getMasterList(payload)),
  getConRefList: (payload) => dispatch(getConRefList(payload)),
  getUpsiInfoById: (payload) => dispatch(getUpsiInfoById(payload)),
  getTemplateInfoList: (payload) => dispatch(getTemplateInfoList(payload)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UPSIInfo)
);
