import { axiosAuthGet, axiosAuthPost } from "modules/Axios";
import { holdingConst, AuditConst } from "modules/config";
import * as actions from "./constant";

export const getHoldingList = () => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_HOLDING_LIST_INITIATED });
    let response = await axiosAuthGet(holdingConst.GET_HOLDING_LIST);
    if (response?.success)
      await dispatch({ type: actions.GET_HOLDING_LIST_SUCCESS, payload: response });
    else dispatch({ type: actions.GET_HOLDING_LIST_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.GET_HOLDING_LIST_ERROR, error: "Network Error" });
  }
};

export const getHoldingById = (id) => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_HOLDING_BY_ID_INITIATED });
    let response = await axiosAuthGet(holdingConst.GET_HOLDING_BY_ID + id);
    if (response?.success)
      await dispatch({ type: actions.GET_HOLDING_BY_ID_SUCCESS, payload: response });
    else dispatch({ type: actions.GET_HOLDING_BY_ID_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.GET_HOLDING_BY_ID_ERROR, error: "Network Error" });
  }
};
export const getHoldingByuId = (id) => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_HOLDING_BY_UID_INITIATED });
    let response = await axiosAuthGet(holdingConst.GET_HOLDING_BY_UID + id);
    if (response?.success)
      await dispatch({ type: actions.GET_HOLDING_BY_UID_SUCCESS, payload: response });
    else dispatch({ type: actions.GET_HOLDING_BY_UID_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.GET_HOLDING_BY_UID_ERROR, error: "Network Error" });
  }
};

export const addHolding = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.ADD_HOLDING_INITIATED });
    let response = await axiosAuthPost(holdingConst.ADD_HOLDING, payload);
    if (response?.success) {
      // await axiosAuthPost(AuditConst.ADD_AUDIT, { uid: payload.cid, text: "Holding added" });
      await dispatch({ type: actions.ADD_HOLDING_SUCCESS, payload: response });
    } else dispatch({ type: actions.ADD_HOLDING_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.ADD_HOLDING_ERROR, error: "Network Error" });
  }
};

export const updateHolding = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.UPDATE_HOLDING_INITIATED });
    let response = await axiosAuthPost(holdingConst.UPDATE_HOLDING + payload.id, payload);
    if (response?.success) {
      await axiosAuthPost(AuditConst.ADD_AUDIT, { uid: payload.cid, toid: payload.id, text: "Holding update" });
      await dispatch({ type: actions.UPDATE_HOLDING_SUCCESS, payload: response });
    } else dispatch({ type: actions.UPDATE_HOLDING_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.UPDATE_HOLDING_ERROR, error: "Network Error" });
  }
};

export const deleteHolding = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.DELETE_HOLDING_INITIATED });
    let response = await axiosAuthGet(holdingConst.DELETE_HOLDING + payload.id);
    if (response?.success) {
      await axiosAuthPost(AuditConst.ADD_AUDIT, { uid: payload.cid, toid: payload.id, text: "Holding deleted" });
      await dispatch({ type: actions.DELETE_HOLDING_SUCCESS, payload: response });
    } else dispatch({ type: actions.DELETE_HOLDING_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.DELETE_HOLDING_ERROR, error: "Network Error" });
  }
};

export const deleteByDateHolding = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.DELETEBYDATE_HOLDING_INITIATED });
    let response = await axiosAuthGet(holdingConst.DELETEBYDATE_HOLDING + payload.date);
    if (response?.success) {
      await axiosAuthPost(AuditConst.ADD_AUDIT, { uid: payload.cid, otherText: payload.date, text: "BenPos deleted" });
      await dispatch({ type: actions.DELETEBYDATE_HOLDING_SUCCESS, payload: response });
    } else dispatch({ type: actions.DELETEBYDATE_HOLDING_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.DELETEBYDATE_HOLDING_ERROR, error: "Network Error" });
  }
};
