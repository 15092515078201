import React, { Component } from "react";
import { Spin } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { ButtonConst, appConst } from "App/AppConstant";
import { Button, Table, LayOut, Input } from "components/Form";
import { getMasterList, deleteMaster } from "redux/master/actions";
import { getAuthRole, getAuthUserID } from "modules/helper";
var role = getAuthRole();
var userId = getAuthUserID();

class Master extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      url: "",
      search: "",
      mlist: [],
      collapsed: false,
    };
  }
  async componentDidMount() {
    try {
      const { match } = this.props;
      userId = userId ? userId : getAuthUserID();
      role = role ? role : getAuthRole();
      let path = match.path.replace(/\//g, "").replace(/-/g, " ");
      let title = path?.replace(
        /(^\w|\s\w)(\S*)/g,
        (_, m1, m2) => m1?.toUpperCase() + m2?.toLowerCase()
      );
      title = title !== "Sharing Mode" ? title : "UPSI Sharing Mode";
      title = title.replace("Upsi", "UPSI");
      this.setState({ url: path, title });
      await this.props.getMasterList();
    } catch (error) {
      console.log(error);
    }
  }
  componentDidUpdate(prevProps) {
    try {
      const { masterList } = this.props;
      if (prevProps.masterList !== masterList) {
        const { title } = this.state;
        let ml = masterList?.filter(
          (a) =>
            a.is_deleted === 0 &&
            title.toLowerCase().includes(a.typeName.toLowerCase())
        );
        this.setState({ mlist: ml });
      }
    } catch (error) {
      console.log(error);
    }
  }
  removeData = async (id) => {
    try {
      const { title } = this.state;
      await this.props.deleteMaster({ id: id, uid: userId, typeName: title });
      await this.props.getMasterList();
    } catch (error) {
      console.log(error);
    }
  };
  editData = (id) => this.redirect("/edit-", "/" + id);
  redirect = (link, txt) => {
    const { url } = this.state;
    this.props.history.push(link + url.replace(/ /g, "-") + txt);
  };
  changetxt = (e) => this.setState({ search: e.target.value });
  render() {
    const { loading, match } = this.props;
    const { mlist, title, search } = this.state;
    return (
      <Spin size="large" spinning={loading}>
        <LayOut>
          <div className="main anime">
            <div className="head">
              <h4 className="title">{title}</h4>
              <div className="btnDiv">
                <Input
                  placeholder={appConst.src}
                  handleChange={this.changetxt}
                />
                {role !== 68 && !match.path.includes("/user-role") && (
                  <Button onClick={() => this.redirect("/add-", "")}>
                    {ButtonConst.add}
                  </Button>
                )}
              </div>
            </div>
            <div className="display">
              <Table
                data={mlist}
                type="master"
                tab={title}
                search={search}
                deleteCol={this.removeData}
                editCol={this.editData}
              />
            </div>
          </div>
        </LayOut>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.master.loading,
  masterList: state.master.masterList,
});
const mapDispatchToProps = (dispatch) => ({
  getMasterList: (payload) => dispatch(getMasterList(payload)),
  deleteMaster: (payload) => dispatch(deleteMaster(payload)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Master));
