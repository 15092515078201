import React, { Component } from "react";
import { message, Spin } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { ClosureConst } from "./constant";
import { sendmail } from "redux/smtp/actions";
import { getUserList } from "redux/user/actions";
import { ButtonConst, appConst } from "App/AppConstant";
import { Button, Table, LayOut, Input } from "components/Form";
import { getTemplateInfoList } from "redux/templates/actions";
import { getAuthRole, getAuthUserID } from "modules/helper";
import { getConRefList } from "redux/connRef/actions";
import {
  getClosureList,
  deleteClosure,
  updateClosure,
} from "redux/closure/actions";
var role = getAuthRole();
var userId = getAuthUserID();
class Closure extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cid: 0,
      cList: [],
      ulist: [],
      search: "",
      usrlist: [],
      userlist: [],
      closerMail: [],
      openMail:[],
      disable: false,
      openClose:false,
    };
  }
  async componentDidMount() {
    try {
      userId = userId ? userId : getAuthUserID();
      role = role ? role : getAuthRole();
      await this.props.getConRefList();
      await this.props.getUserList();
      await this.props.getClosureList();
      await this.props.getTemplateInfoList();
    } catch (error) {
      console.log(error);
    }
  }
  async componentDidUpdate(prevProps) {
    try {
      const { closureList, templateList, userList, isSend, connRefList } =
        this.props;
      const {openClose} =this.state;
      if (isSend !== prevProps.isSend&&this.state.cid !==0) {
        if (isSend) {
          let  sent = {
            uid: userId,
            id: this.state.cid,
            isSend: 1,
          }
          if (openClose)sent.openClose=openClose;
          await this.props.updateClosure(sent);
          await this.props.getClosureList();
          this.setState({openClose:false})
        }
      }
      if (prevProps.closureList !== closureList) {
        const { usrlist, userlist } = this.state;
        let ml = closureList?.filter((a) => a.is_deleted === 0);
        let ml2 = [];
        if (role === 67) {
          ml = ml?.filter((a) => a.uid === userId || a.upid === userId);
          usrlist?.forEach((s) => {
            let cl = closureList?.filter(
              (a) => a.is_deleted === 0 && a.sid?.includes(s?.toString())
            );
            cl?.forEach((f) => {
              if (
                ml2?.findIndex((x) => x.id === f.id) === -1 &&
                ml?.findIndex((d) => d.id === f.id) === -1
              )
                ml2.push(f);
            });
          });
        }
        let allcl = ml?.concat(ml2);
        allcl?.forEach((f) => {
          let usr = userlist?.find((x) => x.id === f.upid || x.id === f.uid);
          f.unmae = usr?.value;
        });
        let sortData = allcl?.sort((a, b) => b.id - a.id);
        this.setState({ cList: sortData });
      }
      if (templateList !== prevProps.templateList) {
        let match = templateList.find(
          (a) => a.name === "Closure of Trading Window for All"
        );
        let  match2 = templateList.find(
          (a) => a.name === "Trading Window Open"
        );
        if (match) this.setState({ closerMail: match ,openMail:match2});
      }
      if (userList !== prevProps.userList) {
        let urlist = userList?.filter((a) => a.is_deleted === 0);
        let data = [];
        urlist?.forEach((a) => {
          a.value = a.surname ? a.name + " " + a.surname : a.name;
          let pos =
            a.uid === 66
              ? " (CO"
              : a.uid === 67
              ? " (DP"
              : a.uid === 68
              ? " (PCS"
              : " (CP";
          if (data.find((b) => b.value === a.value + pos + ")")) {
            if (data.find((b) => b.value === a.value + pos + ") 2")) {
              if (data.find((b) => b.value === a.value + pos + ") 3"))
                data.push({ id: a.id, value: a.value + pos + ") 4" });
              else data.push({ id: a.id, value: a.value + pos + ") 3" });
            } else data.push({ id: a.id, value: a.value + pos + ") 2" });
          } else data.push({ id: a.id, value: a.value + pos + ")" });
        });
        this.setState({ ulist: userList, userlist: data });
      }
      if (connRefList !== prevProps.connRefList) {
        let list = [userId];
        connRefList?.filter(
          (a) => a.dpId === userId && a.is_deleted === 0 && list.push(a.uId)
        );
        this.setState({ usrlist: list });
      }
    } catch (error) {
      console.log(error);
    }
  }
  removeData = async (id) => {
    try {
      await this.props.deleteClosure({ id: id, uid: userId });
      await this.props.getClosureList();
    } catch (error) {
      console.log(error);
    }
  };
  dateChange = (date) => {
    let day = new Date(date).getDate();
    day = day?.toString()?.length === 1 ? "0" + day : day;
    let month = new Date(date).getMonth() + 1;
    month = month?.toString()?.length === 1 ? "0" + month : month;
    let year = new Date(date).getFullYear();
    return date ? day + "-" + month + "-" + year : "";
  };
  findUser = (a) => {
    try {
      const { ulist } = this.state;
      let rec = a?.sid?.split(",");
      let data = [];
      rec?.forEach((a) => {
        let user = ulist?.find((b) => b.id === parseInt(a));
        if (user) data.push(user.email);
      });
      return data;
    } catch (error) {
      console.log(error);
    }
  };
  sendMail = async (a,mailType) => {
    try {
      const { closerMail, disable , openMail} = this.state;
      this.setState({ disable: true });
      setTimeout(() => this.setState({ disable: false }), 5000);
      if (!disable) {
        let toData = this.findUser(a);
        if (closerMail?.template && toData.length > 0 && mailType !=="openMail") {
          let temp = closerMail.template;
          temp = temp?.replace("DD/MM/YEAR", this.dateChange(a.from));
          temp = temp?.replace("DD/MM/YEAR", this.dateChange(a.to));
          let mailData = {
            to: toData, //"kshitij.v@naapbooks.in",
            subject: closerMail.name,
            html: temp,
          };
          let postData = {
            uid: userId,
            mail: mailData,
            text: {
              type: closerMail.name,
              send: toData,
              from: this.dateChange(a.from),
              to: this.dateChange(a.to),
            },
          };
          this.setState({ cid: a.id });
          await this.props.sendmail(postData);
        }else if(openMail?.template && toData.length > 0 && mailType ==="openMail"){
          let temp = openMail.template;
          temp = temp?.replace("DD/MM/YEAR", this.dateChange(a.from));
          temp = temp?.replace("DD/MM/YEAR", this.dateChange(a.to));
          let mailData = {
            to: toData, //"kshitij.v@naapbooks.in",
            subject: openMail.name,
            html: temp,
          };
          let postData = {
            uid: userId,
            mail: mailData,
            text: {
              type: openMail.name,
              send: toData,
              from: this.dateChange(a.from),
              to: this.dateChange(a.to),
            },
          };
          this.setState({ cid: a.id ,openClose:true });
          await this.props.sendmail(postData);
        } else if(true) message.error(ClosureConst.ce);
      }
    } catch (error) {
      console.log(error);
    }
  };
  redirect = (link) => this.props.history.push(link);
  changetxt = (e) => this.setState({ search: e.target.value });
  render() {
    const { loading, loading2 } = this.props;
    const { cList, search } = this.state;
    return (
      <Spin size="large" spinning={loading || loading2}>
        <LayOut>
          <div className="main anime">
            <div className="head">
              <h4 className="title">{ClosureConst.closure}</h4>
              <div className="btnDiv">
                <Input
                  placeholder={appConst.src}
                  handleChange={this.changetxt}
                />
                {role === 66 && (
                  <Button onClick={() => this.redirect("/add-window-closure")}>
                    {ButtonConst.add}
                  </Button>
                )}
              </div>
            </div>
            <div className="display">
              <Table
                data={cList}
                type="closure"
                tab="Closure"
                search={search}
                deleteCol={this.removeData}
                sendMail={this.sendMail}
                editCol={(id) => this.redirect("/edit-window-closure/" + id)}
                viewCol={(id) =>
                  this.redirect("/window-closure/" + window.btoa(id))
                }
              />
            </div>
          </div>
        </LayOut>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.closure.loading,
  loading2: state.smtp.loading,
  isSend: state.smtp.isSend,
  userList: state.user.userList,
  closureList: state.closure.closureList,
  templateList: state.template.templateList,
  connRefList: state.connref.connRefList,
});
const mapDispatchToProps = (dispatch) => ({
  sendmail: (payload) => dispatch(sendmail(payload)),
  getUserList: (payload) => dispatch(getUserList(payload)),
  deleteClosure: (payload) => dispatch(deleteClosure(payload)),
  updateClosure: (payload) => dispatch(updateClosure(payload)),
  getClosureList: (payload) => dispatch(getClosureList(payload)),
  getTemplateInfoList: (payload) => dispatch(getTemplateInfoList(payload)),
  getConRefList: (payload) => dispatch(getConRefList(payload)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Closure)
);
