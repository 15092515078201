import React, { Component } from "react";
import axios from "axios";
import * as XLSX from "xlsx";
import { message, Upload, Button } from "antd";
import { VerticalAlignTopOutlined, CloseCircleFilled } from "@ant-design/icons";

// import { configVar } from "modules/config";

class FileUpload extends Component {
  constructor() {
    super();
    this.state = { imgByte: null, imgnm: null, show: false, configVar: {} };
  }
  componentDidMount() {
    const { file, name } = this.props;
    this.setconfig();
    this.setState({ imgByte: file, imgnm: name });
  }
  componentDidUpdate(prevProps) {
    const { file, name } = this.props;
    if (name !== prevProps.name) {
      this.setState({ imgByte: file, imgnm: name });
    }
  }
  setconfig = async () => {
    try {
      let { data: response } = await axios.get(
        "http://" + window.location.host + "/manifest.json"
      );
      let send = {
        BASE_URL: "http://" + response.apiUrl + "/api/",
        MAIN_URI: "http://" + response.apiUrl + "/",
      };
      this.setState({ configVar: send });
    } catch (error) {
      console.log(error);
    }
  };
  beforeUpload = async (file) => {
    try {
      const { image, excel } = this.props;
      let name = file.name;
      if (image) {
        if (image && file.size > 5000000)
          message.error("Select smaller size file(less then 5mb)");
        else {
          let formdata = new FormData();
          formdata.append("file", file);
          this.props.sendByte(formdata, file.name);
        }
      } else if (excel) {
        this.setByte([], name);
        // this.props.sendByte([], name);
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        fileReader.onload = (e) => {
          const bufferArray = e.target.result;
          const wb = XLSX.read(bufferArray, { type: "buffer" });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data = XLSX.utils.sheet_to_json(ws);
          this.props.sendData(data);
        };
      }
    } catch (error) {
      console.log(error);
    }
  };
  removeFile = () => {
    this.setState({ imgByte: null, imgnm: null });
    this.props.sendByte("", "");
  };
  removeLink = () => this.setState({ show: false });
  setByte = (byteCode, name) =>
    this.setState({ imgByte: byteCode, imgnm: name });
  fileUI = () => {
    const { img, nm, accept } = this.props;
    const props = { beforeUpload: this.beforeUpload };
    const { imgnm, show, configVar } = this.state;
    let name = imgnm;
    let imgpath = "";
    if (img) {
      let a = img?.split("/");
      imgpath = a?.length > 0 && a[a?.length - 1];
    }
    if (imgnm) {
      imgpath = "";
    }
    return (
      <>
        {imgnm ? (
          <span className="optionui">
            <span className="txtWrap">{name}</span>
            <CloseCircleFilled onClick={() => this.removeFile()} />
          </span>
        ) : (
          <>
            {imgpath !== "" && show && (
              <span className="optionui">
                <a
                  href={configVar.MAIN_URI + img}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="txtWrap">{imgpath}</span>
                </a>
                <CloseCircleFilled onClick={() => this.removeLink()} />
              </span>
            )}
            <Upload showUploadList={false} accept={accept} {...props}>
              <Button color="secondary" className="uploadbtn">
                <VerticalAlignTopOutlined /> {nm}
              </Button>
            </Upload>
          </>
        )}
      </>
    );
  };
  render() {
    return <>{this.fileUI()}</>;
  }
}
export default FileUpload;
