import React, { Component } from "react";
import { Spin } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { getUserByDp, deleteUser } from "redux/user/actions";
import { ButtonConst, appConst } from "App/AppConstant";
import { Button, Table, LayOut, Input } from "components/Form";
import { PageConst } from "container/AddUser/constant";
import { getAuthUserID, getAuthRole } from "modules/helper";
import { getConRefList } from "redux/connRef/actions";

var userId = getAuthUserID();
var role = getAuthRole();
class Person extends Component {
  constructor(props) {
    super(props);
    this.state = { ulist: [], colist: [], search: "" };
  }
  async componentDidMount() {
    try {
      userId = userId ? userId : getAuthUserID();
      role = role ? role : getAuthRole();
      if (localStorage.url === "/profile") localStorage.url = "/";
      await this.props.getUserByDp(0);
      await this.props.getConRefList();
    } catch (error) {
      console.log(error);
    }
  }
  componentDidUpdate(prevProps) {
    try {
      const { userData, connRefList } = this.props;
      if (userData !== prevProps.userData) {
        let ulist = userData?.filter(
          (a) => a.id !== userId && a.is_deleted === 0
        ); // if (role === 67) ulist = ulist?.filter((a) => a.cid === userId);
        this.setState({ ulist });
      }
      if (connRefList !== prevProps.connRefList) {
        const { ulist } = this.state;
        let list = [],
          ar = [];
        if (role === 67) {
          let urlist = connRefList?.filter(
            (a) => a.dpId === userId && a.is_deleted === 0
          );
          urlist?.forEach((c) => {
            let usr = ulist?.find((v) => v.id === c.uId);
            usr && list?.push(usr);
          });
          ar = list;
        } else ar = ulist;
        ar?.forEach((d) => {
          let clist = [];
          connRefList?.filter(
            (v) =>
              v.is_deleted === 0 &&
              v.uId === d.id &&
              v.dpName &&
              clist.push(v.dpName)
          );
          d.dpName = clist.toString();
        });
        this.setState({ colist: ar });
      }
    } catch (error) {
      console.log(error);
    }
  }
  removeData = async (id) => {
    try {
      const { userData } = this.props;
      let item = userData?.find((x) => x.id === id);
      let pan = item?.pan; //+ parseInt(Math.random() * 100);
      // if (userData?.find((x) => x.pan === pan)) {
      //   pan = item?.pan + parseInt(Math.random() * 100);
      //   if (userData?.find((x) => x.pan === pan))
      //     pan = item?.pan + parseInt(Math.random() * 100);
      // }
      let postData = {
        id: id,
        pan: "-" + pan,
        uid: userId,
        username: "-" + item.userName + parseInt(Math.random() * 100),
      };
      await this.props.deleteUser(postData);
      await this.props.getUserByDp(0);
      await this.props.getConRefList();
    } catch (error) {
      console.log(error);
    }
  };
  redirect = (link) => this.props.history.push(link);
  changetxt = (e) => this.setState({ search: e.target.value });
  render() {
    const { colist, search } = this.state;
    const { loading, loading2 } = this.props;
    return (
      <Spin size="large" spinning={loading || loading2}>
        <LayOut>
          <div className="main anime">
            <div className="head">
              <h4 className="title">{PageConst.cp}</h4>
              <div className="btnDiv">
                <Input
                  placeholder={appConst.src}
                  handleChange={this.changetxt}
                />
                {role !== 68 && (
                  <Button
                    onClick={() => this.redirect("/add-connected-person")}
                  >
                    {ButtonConst.add}
                  </Button>
                )}
              </div>
            </div>
            <div className="display">
              <Table
                data={colist}
                search={search}
                type="connected"
                tab={"Connected person"}
                deleteCol={this.removeData}
                editCol={(id) => this.redirect("/edit-connected-person/" + id)}
              />
            </div>
          </div>
        </LayOut>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.user.loading,
  loading2: state.connref.loading,
  userData: state.user.userData,
  connRefList: state.connref.connRefList,
});
const mapDispatchToProps = (dispatch) => ({
  getUserByDp: (payload) => dispatch(getUserByDp(payload)),
  deleteUser: (payload) => dispatch(deleteUser(payload)),
  getConRefList: (payload) => dispatch(getConRefList(payload)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Person));
