import React, { Component } from "react";
import { Spin } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { getUserByDp, deleteUser } from "redux/user/actions";
import { ButtonConst, appConst } from "App/AppConstant";
import { Button, Table, LayOut, Input } from "components/Form";
import { getAuthUserID, getAuthRole } from "modules/helper";
var userId = getAuthUserID();
var role = getAuthRole();

class User extends Component {
  constructor(props) {
    super(props);
    this.state = { ulist: [], search: "", url: "", title: "" };
  }
  async componentDidMount() {
    try {
      const { match } = this.props;
      userId = userId ? userId : getAuthUserID();
      role = role ? role : getAuthRole();
      if (localStorage.url === "/profile") localStorage.url = "/";
      if (role === 67) this.props.history.push("/"); //connected-person
      let path = match.path.replace(/\//g, "");
      let title = path
        ?.replace(/-/g, " ")
        .replace(
          /(^\w|\s\w)(\S*)/g,
          (_, m1, m2) => m1?.toUpperCase() + m2?.toLowerCase()
        );
      title = title === "Pcs" ? "PCS List" : title + " List";
      this.setState({ url: path.toLowerCase(), title });
      await this.props.getUserByDp(1);
    } catch (error) {
      console.log(error);
    }
  }
  componentDidUpdate(prevProps) {
    try {
      const { userData } = this.props;
      if (userData !== prevProps.userData) {
        const { url } = this.state;
        let uid =
          url?.toLocaleLowerCase() === "compliance-officer"
            ? 66
            : url?.toLocaleLowerCase() === "designated-person"
            ? 67
            : 68;
        let ulist = userData?.filter(
          (a) => a.is_deleted === 0 && a.uid === uid && a.isDP === 1
        );
        if (role !== 68) ulist = ulist?.filter((a) => a.id !== userId);
        this.setState({ ulist });
      }
    } catch (error) {
      console.log(error);
    }
  }
  removeData = async (id) => {
    try {
      const { userData } = this.props;
      let item = userData?.find((x) => x.id === id);
      let pan = item?.pan; // + parseInt(Math.random() * 100);
      // if (userData?.find((x) => x.pan === pan)) {
      //   pan = item?.pan + parseInt(Math.random() * 100);
      //   if (userData?.find((x) => x.pan === pan))
      //     pan = item?.pan + parseInt(Math.random() * 100);
      // }
      let postData = {
        id: id,
        uid: userId,
        username: "-" + item.userName + parseInt(Math.random() * 100),
        pan: "-" + pan,
      };
      await this.props.deleteUser(postData);
      await this.props.getUserByDp(1);
    } catch (error) {
      console.log(error);
    }
  };
  redirect = (link) => this.props.history.push(link);
  changetxt = (e) => this.setState({ search: e.target.value });
  render() {
    const { ulist, search, title, url } = this.state;
    const { loading } = this.props;
    return (
      <Spin size="large" spinning={loading}>
        <LayOut>
          <div className="main anime">
            <div className="head">
              <h4 className="title">{title}</h4>
              <div className="btnDiv">
                <Input
                  placeholder={appConst.src}
                  handleChange={this.changetxt}
                />
                {role !== 68 && (
                  <Button onClick={() => this.redirect("/add-" + url)}>
                    {ButtonConst.add}
                  </Button>
                )}
              </div>
            </div>
            <div className="display">
              <Table
                data={ulist}
                search={search}
                type="user"
                tab={url}
                editCol={(id) => this.redirect("/edit-" + url + "/" + id)}
                deleteCol={this.removeData}
              />
            </div>
          </div>
        </LayOut>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.user.loading,
  userData: state.user.userData,
});
const mapDispatchToProps = (dispatch) => ({
  getUserByDp: (payload) => dispatch(getUserByDp(payload)),
  deleteUser: (payload) => dispatch(deleteUser(payload)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(User));
