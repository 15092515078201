const PageConst = {
  title: "Post Deal Disclosure",
  pct: "Pre Clearance-Taken",
  above: "Above 10 Lacs",
  pre: "Preclearance Id*",
  folio: "Folio No.",
  dts: "Date of Transaction start*",
  dte: "Date of Transaction end*",
  name: "Name*",
  ts: "Type of Security*",
  nss: "No. of Securities sold / Acquired*",
  total: "Total Security Held as on date",
  te: "Trading Exchange",
  yes: "Yes",
  no: "No",
  mDate: "Date of Transaction end is less than Date of Transaction start",
  ce: "Can't send email",
  cn:"Contract Note*",
  un: "Upload Note",
  pfu: "Please upload Contract Note",
  td:"Trade Date*"
};
export { PageConst };
