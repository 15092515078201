import React, { Component } from "react";
import * as Yup from "yup";
import { Spin } from "antd";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { ButtonConst } from "App/AppConstant";
import { LoginStyle } from "container/Login/style";
import { LoginConst } from "container/Login/constant";
import {
  addSubscription,
  getSubscriptionList,
} from "redux/subscription/actions";
import { Label, Input, Button, DatePicker, Row, Col } from "components/Form";

const ValidationSchema = Yup.object().shape({
  name: Yup.string().trim().nullable().required(" "),
  sdate: Yup.date().nullable().required(" "),
  edate: Yup.date()
    .nullable()
    .required(" ")
    .when(
      "sdate",
      (sdate, schema) => sdate && schema.min(sdate, LoginConst.mDate)
    ),
});

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = { disable: false };
  }
  async componentDidMount() {
    await this.props.getSubscriptionList();
  }
  async componentDidUpdate(prevProps) {
    const { subscriptionList, history } = this.props;
    if (subscriptionList !== prevProps.subscriptionList) {
      if (subscriptionList?.length > 0) history.push("/login");
    }
  }
  handleSubmit = async (values, { setSubmitting }) => {
    try {
      this.setState({ disable: true });
      setTimeout(() => this.setState({ disable: false }), 5000);
      let postdata = {
        name: values.name.trim(),
        sdate: values.sdate.trim(),
        edate: values.edate.trim(),
      };
      await this.props.addSubscription(postdata);
      setSubmitting(false);
    } catch (error) {
      console.log(error, "handle error");
    }
  };
  render() {
    const { disable } = this.state;
    const { loading } = this.props;
    return (
      <Spin spinning={loading} size="large">
        <LoginStyle>
          {/* <Image src={coffee} alt="coffee" preview={false} className="coffee" /> */}
          <div className="box">
            {/* <Image src={logo} alt="logo" preview={false} /> */}
            <h1 className="head">{LoginConst.sub}</h1>
            <Formik
              initialValues={{ name: "", sdate: "", edate: "" }}
              validationSchema={ValidationSchema}
              onSubmit={this.handleSubmit}
              enableReinitialize
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <Form onSubmit={handleSubmit} noValidate>
                  <div className="inputdiv login">
                    <Label
                      title={LoginConst.name}
                      className={errors.name && touched.name ? "empty" : ""}
                    />
                    <Input
                      name="name"
                      value={values.name}
                      handleChange={handleChange}
                      onBlur={handleBlur}
                      maxLength={255}
                      tabIndex="1"
                      className={errors.name && touched.name ? "empty" : ""}
                    />
                    {errors.name && touched.name ? (
                      <div className="form-error">{errors.name}</div>
                    ) : null}
                  </div>
                  <Row gutter={20}>
                    <Col>
                      <div className="inputdiv">
                        <Label
                          title={LoginConst.sdate}
                          className={
                            errors.sdate && touched.sdate ? "empty" : ""
                          }
                        />
                        <DatePicker
                          value={values.sdate}
                          handleChange={handleChange("sdate")}
                          tabIndex="2"
                          className={
                            errors.sdate && touched.sdate ? "empty" : ""
                          }
                        />
                      </div>
                    </Col>
                    <Col>
                      <div className="inputdiv">
                        <Label
                          title={LoginConst.edate}
                          className={
                            errors.edate && touched.edate ? "empty" : ""
                          }
                        />
                        <DatePicker
                          value={values.edate}
                          handleChange={handleChange("edate")}
                          tabIndex="3"
                          className={
                            errors.edate && touched.edate ? "empty" : ""
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                  {errors.edate && touched.edate ? (
                    <div className="form-error">{errors.edate}</div>
                  ) : null}
                  <div className="btntDiv">
                    <Button htmlType="submit" disabled={disable}>
                      {ButtonConst.save}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div className="copyRight">
            <center>
              <div className="bdiv">
                Copyright © 2023
                <a href="https://www.naapbooks.com/" target="blank">
                  Naapbooks Limited
                </a>
              </div>
            </center>
          </div>
        </LoginStyle>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.subscription.loading,
  subscriptionList: state.subscription.subscriptionList,
});
const mapDispatchToProps = (dispatch) => ({
  addSubscription: (payload) => dispatch(addSubscription(payload)),
  getSubscriptionList: (payload) => dispatch(getSubscriptionList(payload)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
