import React, { Component } from "react";

import { Menu, Header } from "components/Form";

class LayOut extends Component {
  render() {
    const { children, link } = this.props;
    return (
      <div className="flex">
        <Menu />
        <div className="allDiv">
          <Header link={link} />
          {children}
        </div>
        <div className="copyRight">
          <center>
            <div className="bdiv">
              Copyright © 2023
              <a href="https://www.naapbooks.com/" target="blank">
                Naapbooks Limited
              </a>
            </div>
          </center>
        </div>
      </div>
    );
  }
}
export default LayOut;
