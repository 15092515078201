import React, { Component } from "react";
import { Spin } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { getBenPoList, deleteBenPo } from "redux/benpo/actions";
import { deleteByDate } from "redux/benpolist/actions";
import { appConst, ButtonConst } from "App/AppConstant";
import { Button, Table, LayOut, Input } from "components/Form";
import { getAuthRole, getAuthUserID } from "modules/helper";
import { deleteByDateHolding } from "redux/holding/actions";

var role = getAuthRole();
var userId = getAuthUserID();
class BenPOS extends Component {
  constructor(props) {
    super(props);
    this.state = { blist: [], disData: [], search: "" };
  }
  async componentDidMount() {
    try {
      role = role ? role : getAuthRole();
      userId = userId ? userId : getAuthUserID();
      if (localStorage.url === "/profile") localStorage.url = "/";
      this.setState({ disData: this.state.children });
      await this.props.getBenPoList();
    } catch (error) {
      console.log(error);
    }
  }
  componentDidUpdate(prevProps) {
    try {
      const { benpoList } = this.props;
      if (benpoList !== prevProps.benpoList) {
        let list = benpoList?.filter((a) => a.is_deleted === 0);
        list.forEach((a) => {
          let day = new Date(a.date).getDate();
          day = day?.toString()?.length === 1 ? "0" + day : day;
          let month = new Date(a.date).getMonth() + 1;
          month = month?.toString()?.length === 1 ? "0" + month : month;
          let year = new Date(a.date).getFullYear();
          a.date1 = a.date ? year + "-" + month + "-" + day : "";
        });
        this.setState({ blist: list });
      }
    } catch (error) {
      console.log(error);
    }
  }
  removeData = async (id) => {
    try {
      const { blist } = this.state;
      let list = blist?.find((a) => a.id === id);
      if (role === 66) await this.props.deleteBenPo({ id: id, uid: userId });
      await this.props.deleteByDate(list?.date1?.split("T")[0]);
      await this.props.deleteByDateHolding({date: list?.date1?.split("T")[0],cid: userId});
      await this.props.getBenPoList();
    } catch (error) {
      console.log(error);
    }
  };
  changetxt = (e) => this.setState({ search: e.target.value });
  handleChange = (e) => this.setState({ disData: e });
  redirect = (id) => this.props.history.push("/edit-BenPOS/" + id);
  render() {
    const { blist, search } = this.state;
    const { loading } = this.props;
    return (
      <Spin size="large" spinning={loading}>
        <LayOut>
          <div className="main anime">
            <div className="head">
              <h4 className="title">{appConst.ben}</h4>
              <div className="btnDiv">
                <Input
                  placeholder={appConst.src}
                  handleChange={this.changetxt}
                />
                {role !== 68 && (
                  <Button
                    onClick={() => this.props.history.push("/add-BenPOS")}
                  >
                    {ButtonConst.add}
                  </Button>
                )}
              </div>
            </div>
            <div className="display">
              <Table
                data={blist}
                type="benpo"
                tab="benpo"
                search={search}
                deleteCol={this.removeData}
                downloadCol={this.excleUI}
                viewCol={(id) => this.props.history.push("/benPOSList/" + id)}
              />
            </div>
          </div>
        </LayOut>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.benpo.loading,
  benpoList: state.benpo.benpoList,
});
const mapDispatchToProps = (dispatch) => ({
  getBenPoList: (payload) => dispatch(getBenPoList(payload)),
  deleteBenPo: (payload) => dispatch(deleteBenPo(payload)),
  deleteByDate: (payload) => dispatch(deleteByDate(payload)),
  deleteByDateHolding: (payload) => dispatch(deleteByDateHolding(payload)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BenPOS));
