import { message } from "antd";
import { push } from "connected-react-router";

import { axiosAuthGet, axiosAuthPost } from "modules/Axios";
import { closureConst, AuditConst } from "modules/config";
import * as actions from "./constant";

export const getClosureList = () => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_CLOSURE_LIST_INITIATED });
    let response = await axiosAuthGet(closureConst.GET_CLOSURE_LIST);
    if (response?.success)
      await dispatch({
        type: actions.GET_CLOSURE_LIST_SUCCESS,
        payload: response,
      });
    else
      dispatch({
        type: actions.GET_CLOSURE_LIST_ERROR,
        error: response?.message,
      });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.GET_CLOSURE_LIST_ERROR, error: "Network Error" });
  }
};

export const getClosureById = (id) => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_CLOSURE_BY_ID_INITIATED });
    let response = await axiosAuthGet(closureConst.GET_CLOSURE_BY_ID + id);
    if (response?.success)
      await dispatch({
        type: actions.GET_CLOSURE_BY_ID_SUCCESS,
        payload: response,
      });
    else
      dispatch({
        type: actions.GET_CLOSURE_BY_ID_ERROR,
        error: response?.message,
      });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.GET_CLOSURE_BY_ID_ERROR, error: "Network Error" });
  }
};

export const getMenyClosureById = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_MENYCLOSURE_BY_ID_INITIATED });
    let response = await axiosAuthPost(
      closureConst.GET_MENYCLOSURE_BY_ID,
      payload
    );
    if (response?.success)
      await dispatch({
        type: actions.GET_MENYCLOSURE_BY_ID_SUCCESS,
        payload: response,
      });
    else
      dispatch({
        type: actions.GET_MENYCLOSURE_BY_ID_ERROR,
        error: response?.message,
      });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({
      type: actions.GET_MENYCLOSURE_BY_ID_ERROR,
      error: "Network Error",
    });
  }
};

export const addClosure = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.ADD_CLOSURE_INITIATED });
    let response = await axiosAuthPost(closureConst.ADD_CLOSURE, payload);
    if (response?.success) {
      await axiosAuthPost(AuditConst.ADD_AUDIT, {
        uid: payload.uid,
        text: "Trading Window Closure record added",
      });
      await dispatch({ type: actions.ADD_CLOSURE_SUCCESS, payload: response });
      dispatch(push("/window-closure"));
    } else
      dispatch({ type: actions.ADD_CLOSURE_ERROR, error: response?.message });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.ADD_CLOSURE_ERROR, error: "Network Error" });
  }
};

export const updateClosure = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.UPDATE_CLOSURE_INITIATED });
    let send = payload.openClose
      ? { uid: payload.userId, id: payload.id, isSend: payload.isSend }
      : payload;
    let response = await axiosAuthPost(
      closureConst.UPDATE_CLOSURE + payload.id,
      send
    );
    if (response?.success) {
      // await axiosAuthPost(AuditConst.ADD_AUDIT,{uid:payload.uid,toid:payload.id,text:"Trading Window Closure record updated"});
      await axiosAuthPost(AuditConst.ADD_AUDIT, {
        uid: payload.uid,
        toid: payload.id,
        text: payload.openClose
          ? "Trading Window open record updated"
          : "Trading Window Closure record updated",
      });
      await dispatch({
        type: actions.UPDATE_CLOSURE_SUCCESS,
        payload: response,
      });
      dispatch(push("/window-closure"));
    } else
      dispatch({
        type: actions.UPDATE_CLOSURE_ERROR,
        error: response?.message,
      });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.UPDATE_CLOSURE_ERROR, error: "Network Error" });
  }
};

export const deleteClosure = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.DELETE_CLOSURE_INITIATED });
    let response = await axiosAuthGet(closureConst.DELETE_CLOSURE + payload.id);
    if (response?.success) {
      await axiosAuthPost(AuditConst.ADD_AUDIT, {
        uid: payload.uid,
        toid: payload.id,
        text: "Trading Window Closure record delete",
      });
      await dispatch({
        type: actions.DELETE_CLOSURE_SUCCESS,
        payload: response,
      });
      message.success(response?.message);
    } else
      dispatch({
        type: actions.DELETE_CLOSURE_ERROR,
        error: response?.message,
      });
  } catch (error) {
    console.log(error, "action catch");
    dispatch({ type: actions.DELETE_CLOSURE_ERROR, error: "Network Error" });
  }
};
